"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApiMessageHelper = void 0;
const risesharp_common_1 = require("risesharp-common");
class ApiMessageHelper {
  getDefaultError() {
    return risesharp_common_1.Constants.GENERIC_ERROR;
  }
  getError(error) {
    let isMobileApp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let errorMessage = '';
    if (error.type === risesharp_common_1.Constants.IDENTIFIER_JSON) {
      let i = 0;
      const keys = Object.keys(error.details);
      for (const key of keys) {
        i = i + 1;
        errorMessage += this.getMessage(error.details[key]);
        if (i < keys.length) {
          errorMessage += isMobileApp ? '\n' : '<br>';
        }
      }
    } else {
      errorMessage = this.getMessage(error.details);
    }
    return errorMessage;
  }
  getMessage(input) {
    let isMobileApp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (input !== null && input !== undefined && input !== '') {
      return input.replace(/<<LBR>>/g, isMobileApp ? '\n' : '<br>');
    }
    return '';
  }
}
const ApiMessageHelperInstance = new ApiMessageHelper();
exports.ApiMessageHelper = ApiMessageHelperInstance;