"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApproveLinkedJobs = void 0;
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
const apiMessageHelper_1 = require("./../utils/apiMessageHelper");
class ApproveLinkedJobs extends base_1.Base {
  constructor() {
    super(...arguments);
    this.url = '/approve-linked-jobs';
  }
  getMainList(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ANDERROR), {
        approveLinkedJobsList: risesharp_common_1.Schema.getPaginationList()
      });
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        input.action_type = 'get_main_list';
        const responseJson = yield this.handleGetOpsInput(token, input, this.url, 'approve-linked-jobs-main');
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.approveLinkedJobsList = responseJson.data.approveLinkedJobsList;
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - getMainList', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  getResumeList(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ANDERROR), {
        approveLinkedJobsList: {
          resumeList: []
        }
      });
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        input.action_type = 'get_resume_list';
        const responseJson = yield this.handleGetOpsInput(token, input, this.url, 'approve-linked-jobs-resume');
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.approveLinkedJobsList.resumeList = responseJson.data.approveLinkedJobsList.resumeList;
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - getResumeList', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  sendMessage(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        input.action_type = 'send_message';
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - sendMessage', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  uploadResume(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        input.action_type = 'upload_resume';
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - uploadResume', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  removeResume(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        input.action_type = 'remove_resume';
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - removeResume', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  approvalNotes(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - approvalNotes', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  approveJob(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePutOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - approveJob', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  rejectJob(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handleDeleteOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - rejectJob', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  notifyTpo(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('ApproveLinkedJobsService - notifyTpo', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
}
const ApproveLinkedJobsInstance = new ApproveLinkedJobs();
exports.ApproveLinkedJobs = ApproveLinkedJobsInstance;