"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RandomString = void 0;
const randomStringGenerator = require('just.randomstring');
class RandomString {
  getString(length) {
    return randomStringGenerator(length, 'numbers_uppercases');
  }
}
const RandomStringInstance = new RandomString();
exports.RandomString = RandomStringInstance;