"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class SupportIssues extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    } // empty - nothing to do
    );
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES]: false
        },
        modalActionType: {
          [risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES]: ''
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES) {
      return {
        student_query: '',
        student_user_id: '',
        student_first_name: '',
        student_last_name: '',
        student_email: '',
        student_mobile: '',
        student_jobs: '',
        student_newsletters: '',
        student_products: ''
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), {
        supportIssues: risesharp_common_1.Schema.getSupportIssues()
      });
    }
    return {};
  }
}
exports.default = SupportIssues;