"use strict";

var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Base = void 0;
// @ts-ignore
const get = require("lodash.get");
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
class Base {
  constructor(state) {
    var _this = this;
    this.state = {};
    this.defaultValues = {};
    this.setFormState = mobx_1.action(function (name, value, identifier) {
      let subIdentifier = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
      const stateObj = subIdentifier ? _this.state.form[identifier][subIdentifier] : _this.state.form[identifier];
      stateObj[name] = value;
    });
    this.setValidationState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (!subIdentifier && get(_this.state, 'validation') !== undefined) {
        _this.state.validation[identifier] = newState;
      }
      if (subIdentifier && get(_this.state, 'validation') !== undefined && get(_this.state.validation, identifier) !== undefined) {
        _this.state.validation[identifier][subIdentifier] = newState;
      }
    });
    this.setApiState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      _this.handleApiInput(newState, identifier, subIdentifier);
    });
    this.setSpinnerState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (!subIdentifier && get(_this.state, 'spinner') !== undefined) {
        _this.state.spinner[identifier] = newState;
      }
      if (subIdentifier && get(_this.state, 'spinner') !== undefined && get(_this.state.spinner, identifier) !== undefined) {
        _this.state.spinner[identifier][subIdentifier] = newState;
      }
    });
    this.setDisplayFormState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (!subIdentifier && get(_this.state, 'displayForm') !== undefined) {
        _this.state.displayForm[identifier] = newState;
      }
      if (subIdentifier && get(_this.state, 'displayForm') !== undefined && get(_this.state.displayForm, identifier) !== undefined) {
        _this.state.displayForm[identifier][subIdentifier] = newState;
      }
    });
    this.setDisplaySectionState = mobx_1.action(function (name, value, identifier) {
      let subIdentifier = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
      if (!subIdentifier && get(_this.state, 'displaySections') !== undefined) {
        for (const key in _this.state.displaySections[identifier]) {
          if (_this.state.displaySections[identifier].hasOwnProperty(key)) {
            if (name === key) {
              _this.state.displaySections[identifier][name] = value;
            }
          }
        }
      }
      if (subIdentifier && get(_this.state, 'displaySections') !== undefined && get(_this.state.displaySections, identifier) !== undefined) {
        for (const key in _this.state.displaySections[identifier][subIdentifier]) {
          if (_this.state.displaySections[identifier][subIdentifier].hasOwnProperty(key)) {
            if (name === key) {
              _this.state.displaySections[identifier][subIdentifier][name] = value;
            }
          }
        }
      }
    });
    this.setPaginationState = mobx_1.action(function (name, value, identifier) {
      let subIdentifier = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
      const stateObj = subIdentifier ? _this.state.pagination[identifier][subIdentifier] : _this.state.pagination[identifier];
      stateObj[name] = value;
    });
    this.setAvailabilityState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (!subIdentifier && get(_this.state, 'availability') !== undefined) {
        _this.state.availability[identifier] = newState;
      }
      if (subIdentifier && get(_this.state, 'availability') !== undefined && get(_this.state.availability, identifier) !== undefined) {
        _this.state.availability[identifier][subIdentifier] = newState;
      }
    });
    this.setToggleMenuState = mobx_1.action(newState => {
      this.state.isToggleMenuOpen = newState;
    });
    this.setDropdownMenuState = mobx_1.action(newState => {
      this.state.isDropdownMenuOpen = newState;
    });
    this.setUserLoggedInState = mobx_1.action(newState => {
      this.state.isUserLoggedIn = newState;
    });
    this.setUserDetailsState = mobx_1.action(newState => {
      this.state.userDetails = newState;
    });
    this.setCollegeDetailsState = mobx_1.action(newState => {
      this.state.collegeDetails = newState;
    });
    this.setEmployerDetailsState = mobx_1.action(newState => {
      this.state.employerDetails = newState;
    });
    this.setInternetCheckState = mobx_1.action(newState => {
      this.state.isInternetOnline = newState;
    });
    this.setInternetCheckIntervalState = mobx_1.action(newState => {
      this.state.internetChkIntervalId = newState;
    });
    this.setMaintenanceMessageState = mobx_1.action(newState => {
      this.state.maintenanceMessageDetails = newState;
    });
    this.setMaintMsgIntervalState = mobx_1.action(newState => {
      this.state.maintMsgIntervalId = newState;
    });
    this.setSliderState = mobx_1.action(newState => {
      this.state.slider = newState;
    });
    this.setVideoState = mobx_1.action(newState => {
      this.state.video = newState;
    });
    this.setStudentTaskIntervalState = mobx_1.action(newState => {
      this.state.studentTaskIntervalId = newState;
    });
    this.setStudentEmplSkillsIntervalState = mobx_1.action(newState => {
      this.state.studentEmplSkillsIntervalId = newState;
    });
    this.setCheckLogoutIntervalState = mobx_1.action(newState => {
      this.state.checkLogoutIntervalId = newState;
    });
    this.modifyApiState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      const keys = Object.keys(newState);
      if (keys.length > 0) {
        keys.forEach(key => {
          _this.changeApiValue(key, newState[key], identifier, subIdentifier);
        });
      }
    });
    this.modifyValidationState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (!subIdentifier && get(_this.state, 'validation') !== undefined) {
        _this.state.validation[identifier] = newState;
      }
      if (subIdentifier && get(_this.state, 'validation') !== undefined && get(_this.state.validation, identifier) !== undefined) {
        _this.state.validation[identifier][subIdentifier] = newState;
      }
    });
    /* ##### api state manipulation ##### */
    this.handleApiInput = function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      // generic - errorMessage
      const errorMessage = get(newState, 'errorMessage');
      if (errorMessage !== undefined) {
        _this.changeApiValue('errorMessage', errorMessage, identifier, subIdentifier);
      }
      // generic - successMessage
      const successMessage = get(newState, 'successMessage');
      if (successMessage !== undefined) {
        _this.changeApiValue('successMessage', successMessage, identifier, subIdentifier);
      }
      // accountProfile
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_ACCOUNTPROFILE) {
        let finalSubIdentifier = subIdentifier;
        if (subIdentifier === 'account_profile_list') {
          finalSubIdentifier = '';
          const collegeManageStaff = get(newState, 'collegeManageStaff');
          if (collegeManageStaff !== undefined) {
            const subIdentifierState = get(collegeManageStaff, 'main_list');
            if (subIdentifierState !== undefined) {
              _this.state.collegeStaffList[identifier] = subIdentifierState.list;
            }
          }
        }
        const accountProfile = get(newState, 'accountProfile');
        if (accountProfile !== undefined) {
          _this.changeApiValue('accountProfile', accountProfile, identifier, finalSubIdentifier);
          const email = get(newState, 'accountProfile.email');
          if (email !== '') {
            _this.changeCompleteFormValue(accountProfile, identifier, finalSubIdentifier);
          }
        }
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
      }
      // collegeDashboard
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEDASHBOARD) {
        const collegeDashboard = get(newState, 'collegeDashboard');
        if (collegeDashboard !== undefined) {
          _this.changeApiValue('collegeDashboard', collegeDashboard, identifier, subIdentifier);
        }
      }
      // forgotPassword
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_FORGOTPASSWORD) {
        const forgotPasswordDetails = get(newState, 'forgotPasswordDetails');
        if (forgotPasswordDetails !== undefined) {
          _this.changeApiValue('forgotPasswordDetails', forgotPasswordDetails, identifier, subIdentifier);
        }
      }
      // signin
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNIN) {
        const userDetails = get(newState, 'userDetails');
        if (userDetails !== undefined) {
          _this.changeApiValue('userDetails', userDetails, identifier, subIdentifier);
        }
      }
      // signup
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNUP) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        if (collegeCourses === undefined) {
          const signupDetails = get(newState, 'signupDetails');
          if (signupDetails !== undefined) {
            _this.changeApiValue('signupDetails', signupDetails, identifier, subIdentifier);
          }
        }
      }
      // signupConfirmationDetails
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_SIGNUPCONFIRMATION) {
        const signupConfirmationDetails = get(newState, 'signupConfirmationDetails');
        if (signupConfirmationDetails !== undefined) {
          _this.changeApiValue('signupConfirmationDetails', signupConfirmationDetails, identifier, subIdentifier);
        }
      }
      // collegeStudents
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGESTUDENTS) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        // collegeStudentsList
        if (collegeCourses === undefined) {
          const collegeStudentsList = get(newState, 'collegeStudentsList');
          if (collegeStudentsList !== undefined) {
            _this.changeApiValue('collegeStudentsList', collegeStudentsList, identifier, subIdentifier);
            // noDetails at the identifier level
            _this.changeNoDetailsValue('collegeStudentsList', identifier);
          }
          // filters at the identifier level
          _this.handleFiltersAndListDisplay(identifier);
        }
      }
      // staffColleges
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFFCOLLEGES) {
        // s3SignedUrl
        const s3SignedUrl = get(newState, 's3SignedUrl');
        if (s3SignedUrl !== undefined) {
          _this.changeApiValue('s3SignedUrl', s3SignedUrl, identifier, subIdentifier);
        }
        // collegeDetails
        const collegeDetails = get(newState, 'collegeDetails');
        if (collegeDetails !== undefined) {
          const id = collegeDetails.id;
          if (id) {
            _this.changeCompleteFormValue(collegeDetails, identifier, subIdentifier);
          }
        }
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        // if not any of the above calls - staffCollegeslist
        if (s3SignedUrl === undefined && collegeDetails === undefined && collegeCourses === undefined) {
          const staffCollegesList = get(newState, 'staffCollegesList');
          if (staffCollegesList !== undefined) {
            _this.changeApiValue('staffCollegesList', staffCollegesList, identifier, subIdentifier);
            // noDetails at the identifier level
            _this.changeNoDetailsValue('staffCollegesList', identifier);
          }
          // filters at the identifier level
          _this.handleFiltersAndListDisplay(identifier);
        }
      }
      // staffEmployers
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS) {
        // employerDetails
        const employerDetails = get(newState, 'employerDetails');
        if (employerDetails !== undefined) {
          const id = employerDetails.id;
          if (id) {
            _this.changeCompleteFormValue(employerDetails, identifier, subIdentifier);
          }
        }
        // if not any of the above calls - staffEmployersList
        if (employerDetails === undefined) {
          const staffEmployersList = get(newState, 'staffEmployersList');
          if (staffEmployersList !== undefined) {
            _this.changeApiValue('staffEmployersList', staffEmployersList, identifier, subIdentifier);
            // noDetails at the identifier level
            _this.changeNoDetailsValue('staffEmployersList', identifier);
          }
          // filters at the identifier level
          _this.handleFiltersAndListDisplay(identifier);
        }
      }
      // fetchAdministrators
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_FETCHADMINISTRATORS) {
        const fetchAdministratorsList = get(newState, 'fetchAdministratorsList');
        if (fetchAdministratorsList !== undefined) {
          _this.changeApiValue('fetchAdministratorsList', fetchAdministratorsList, identifier, subIdentifier);
        }
      }
      // employerDetails
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERDETAILS) {
        const employerDetails = get(newState, 'employerDetails');
        if (employerDetails !== undefined) {
          _this.changeApiValue('employerDetails', employerDetails, identifier, subIdentifier);
        }
      }
      // employerLinkedCollegesList
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERLINKCOLLEGES) {
        const employerLinkCollegesList = get(newState, 'employerLinkCollegesList');
        if (employerLinkCollegesList !== undefined) {
          _this.changeApiValue('employerLinkCollegesList', employerLinkCollegesList, identifier, subIdentifier);
        }
      }
      // employerCollegesList
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERCOLLEGESLIST) {
        const employerCollegesList = get(newState, 'employerCollegesList');
        if (employerCollegesList !== undefined) {
          _this.changeApiValue('employerCollegesList', employerCollegesList, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('employerCollegesList', identifier);
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // studentAssessmentsList
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTASSESSMENTSLIST) {
        const studentAssessmentsList = get(newState, 'studentAssessmentsList');
        if (studentAssessmentsList !== undefined) {
          _this.changeApiValue('studentAssessmentsList', studentAssessmentsList, identifier, subIdentifier);
        }
      }
      // personality assessment
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_PERSONALITYASSESSMENT) {
        const personalityAssessmentDetails = get(newState, 'personalityAssessmentDetails');
        if (personalityAssessmentDetails !== undefined) {
          _this.changeApiValue('personalityAssessmentDetails', personalityAssessmentDetails, identifier, subIdentifier);
          const id = personalityAssessmentDetails.id;
          if (id) {
            _this.changeCompleteFormValue(personalityAssessmentDetails, identifier, subIdentifier);
          }
        }
      }
      // interests assessment
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_INTERESTSASSESSMENT) {
        const interestsAssessmentDetails = get(newState, 'interestsAssessmentDetails');
        if (interestsAssessmentDetails !== undefined) {
          _this.changeApiValue('interestsAssessmentDetails', interestsAssessmentDetails, identifier, subIdentifier);
          const id = interestsAssessmentDetails.id;
          if (id) {
            _this.changeCompleteFormValue(interestsAssessmentDetails, identifier, subIdentifier);
          }
        }
      }
      // values assessment
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT) {
        const valuesAssessmentDetails = get(newState, 'valuesAssessmentDetails');
        if (valuesAssessmentDetails !== undefined) {
          _this.changeApiValue('valuesAssessmentDetails', valuesAssessmentDetails, identifier, subIdentifier);
          const id = valuesAssessmentDetails.id;
          if (id) {
            _this.changeCompleteFormValue(valuesAssessmentDetails, identifier, subIdentifier);
          }
        }
      }
      // competency assessment
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT) {
        const competencyAssessmentDetails = get(newState, 'competencyAssessmentDetails');
        if (competencyAssessmentDetails !== undefined) {
          _this.changeApiValue('competencyAssessmentDetails', competencyAssessmentDetails, identifier, subIdentifier);
          const id = competencyAssessmentDetails.id;
          if (id) {
            _this.state.form[identifier].assessmentId = id;
          }
          const details = competencyAssessmentDetails.details;
          if (details && details.length > 0) {
            for (const x of details) {
              _this.state.form[identifier][x.section_id] = {
                section_id: x.section_id,
                rank: x.rank,
                plan: x.plan
              };
            }
          }
        }
      }
      // employerJobs
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERJOBS) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        if (collegeCourses === undefined) {
          const employerJobDetails = get(newState, 'employerJobDetails');
          if (employerJobDetails !== undefined) {
            const id = employerJobDetails.id;
            if (id) {
              _this.changeCompleteFormValue(employerJobDetails, identifier, subIdentifier);
            }
          }
        }
      }
      // employerJobsList
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERJOBSLIST) {
        const employerJobsList = get(newState, 'employerJobsList');
        if (employerJobsList !== undefined) {
          _this.changeApiValue('employerJobsList', employerJobsList, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('employerJobsList', identifier);
          // misc
          _this.state.api[identifier].fp_status = employerJobsList.fp_status || false;
          _this.state.api[identifier].fp_jobs_limit = employerJobsList.fp_jobs_limit || 0;
          _this.state.api[identifier].fp_active_jobs = employerJobsList.fp_active_jobs || 0;
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // approveLinkedJobs
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS) {
        const approveLinkedJobsList = get(newState, 'approveLinkedJobsList');
        if (approveLinkedJobsList !== undefined) {
          _this.changeApiValue('approveLinkedJobsList', approveLinkedJobsList, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('approveLinkedJobsList', identifier);
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // jobDetails
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_JOBDETAILS) {
        const jobDetails = get(newState, 'jobDetails');
        if (jobDetails !== undefined) {
          _this.changeJobDetailsValue(jobDetails, identifier);
        }
      }
      // studentJobsList
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST) {
        const studentJobsList = get(newState, 'studentJobsList');
        if (studentJobsList !== undefined) {
          _this.changeApiValue('studentJobsList', studentJobsList, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('studentJobsList', identifier);
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // employerDashboard
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERDASHBOARD) {
        const employerDashboard = get(newState, 'employerDashboard');
        if (employerDashboard !== undefined) {
          _this.changeApiValue('employerDashboard', employerDashboard, identifier, subIdentifier);
        }
      }
      // collegeApproval
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEAPPROVAL) {
        const collegeApprovalList = get(newState, 'collegeApprovalList');
        if (collegeApprovalList !== undefined) {
          _this.changeApiValue('collegeApprovalList', collegeApprovalList, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('collegeApprovalList', identifier);
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // studentResumeBuilder
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER) {
        const studentResumeDetails = get(newState, 'studentResumeDetails');
        if (studentResumeDetails !== undefined) {
          const id = studentResumeDetails.id;
          if (id) {
            _this.changeCompleteFormValue(studentResumeDetails, identifier, subIdentifier);
          }
        }
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
      }
      // studentAppointments
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS) {
        const studentAppointmentsDetails = get(newState, 'studentAppointmentsDetails');
        if (studentAppointmentsDetails !== undefined) {
          _this.changeCompleteFormValue(studentAppointmentsDetails, identifier, subIdentifier);
        }
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
      }
      // studentJobSearchStatus
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSEARCHSTATUS) {
        const studentJobSearchStatus = get(newState, 'studentJobSearchStatus');
        if (studentJobSearchStatus !== undefined) {
          const id = studentJobSearchStatus.id;
          if (id) {
            _this.changeCompleteFormValue(studentJobSearchStatus, identifier, subIdentifier);
          }
        }
      }
      // collegeJobReadiness
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEJOBREADINESS) {
        const jobReadinessDetails = get(newState, 'jobReadinessDetails');
        if (jobReadinessDetails !== undefined) {
          const name = jobReadinessDetails.name;
          if (name) {
            _this.changeCompleteFormValue(jobReadinessDetails, identifier, subIdentifier);
          }
        }
      }
      // viewResume
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_VIEWRESUME) {
        const viewResume = get(newState, 'viewResume');
        if (viewResume !== undefined) {
          _this.changeApiValue('viewResume', viewResume, identifier, subIdentifier);
        }
      }
      // studentEmployersList
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTEMPLLIST) {
        const studentEmployersList = get(newState, 'studentEmployersList');
        if (studentEmployersList !== undefined) {
          _this.changeApiValue('studentEmployersList', studentEmployersList, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('studentEmployersList', identifier);
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // collegeDirectJobs
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEDIRECTJOBS) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        if (collegeCourses === undefined) {
          const collegeDirectJobDetails = get(newState, 'collegeDirectJobDetails');
          if (collegeDirectJobDetails !== undefined) {
            const id = collegeDirectJobDetails.id;
            if (id) {
              _this.changeCompleteFormValue(collegeDirectJobDetails, identifier, subIdentifier);
            }
          }
        }
      }
      // studentDashboard
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTDASHBOARD) {
        const studentDashboard = get(newState, 'studentDashboard');
        if (studentDashboard !== undefined) {
          _this.changeApiValue('studentDashboard', studentDashboard, identifier, subIdentifier);
        }
      }
      // collegeStudentsSummary
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLSTUSUMMARY) {
        const collegeStudentsSummary = get(newState, 'collegeStudentsSummary');
        if (collegeStudentsSummary !== undefined) {
          _this.changeApiValue('collegeStudentsSummary', collegeStudentsSummary, identifier, subIdentifier);
        }
      }
      // supportIssues
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_SUPPORTISSUES) {
        const supportIssues = get(newState, 'supportIssues');
        if (supportIssues !== undefined) {
          _this.changeApiValue('supportIssues', supportIssues, identifier, subIdentifier);
        }
      }
      // staffNotifyStudents
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYSTUDENTS) {
        const staffNotifyStudents = get(newState, 'staffNotifyStudents');
        if (staffNotifyStudents !== undefined) {
          _this.changeApiValue('staffNotifyStudents', staffNotifyStudents, identifier, subIdentifier);
        }
      }
      // collegeEnrollmentDetails
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS) {
        const collegeEnrollmentDetails = get(newState, 'collegeEnrollmentDetails');
        if (collegeEnrollmentDetails !== undefined) {
          const id = collegeEnrollmentDetails.id;
          if (id) {
            const map = _this.state.form[identifier];
            map.set('id', id);
            map.set('enrollment_year', collegeEnrollmentDetails.enrollment_year);
            map.set('degree_program', collegeEnrollmentDetails.degree_program);
            map.set('degree_specialization', collegeEnrollmentDetails.degree_specialization);
            map.set('no_of_years', collegeEnrollmentDetails.no_of_years);
            map.set('details', collegeEnrollmentDetails.details);
          }
        }
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
      }
      // collegeEnrollmentList
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTLIST) {
        const collegeEnrollmentList = get(newState, 'collegeEnrollmentList');
        if (collegeEnrollmentList !== undefined) {
          _this.changeApiValue('collegeEnrollmentList', collegeEnrollmentList, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('collegeEnrollmentList', identifier);
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // staffNotifyTpos
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS) {
        const staffNotifyTpos = get(newState, 'staffNotifyTpos');
        if (staffNotifyTpos !== undefined) {
          _this.changeApiValue('staffNotifyTpos', staffNotifyTpos, identifier, subIdentifier);
        }
      }
      // collegeImportStudents
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEIMPORTSTUDENTS) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
      }
      // trackAccessUsers
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_TRACKACCESSUSERS) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        // collegesList
        let collegesList = undefined;
        if (collegeCourses === undefined) {
          collegesList = get(newState, 'collegesList');
          if (collegesList !== undefined) {
            _this.state.collegesList[identifier] = collegesList;
          }
        }
        // trackAccessUsersList
        if (collegeCourses === undefined && collegesList === undefined) {
          const trackAccessUsersList = get(newState, 'trackAccessUsersList');
          if (trackAccessUsersList !== undefined) {
            _this.changeApiValue('trackAccessUsersList', trackAccessUsersList, identifier, subIdentifier);
            // noDetails at the identifier level
            _this.changeNoDetailsValue('trackAccessUsersList', identifier);
          }
          // filters at the identifier level
          _this.handleFiltersAndListDisplay(identifier);
        }
      }
      // viewCandidates
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_VIEWCANDIDATES) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        if (collegeCourses === undefined) {
          const viewCandidates = get(newState, 'viewCandidates');
          if (viewCandidates !== undefined) {
            _this.changeApiValue('viewCandidates', viewCandidates, identifier, subIdentifier);
            // noDetails at the identifier level
            _this.changeNoDetailsValue('viewCandidates', identifier);
          }
          // filters at the identifier level
          _this.handleFiltersAndListDisplay(identifier);
        }
      }
      // collegeManageStaff
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLL_MANAGE_STAFF) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        if (collegeCourses === undefined) {
          if (subIdentifier === 'main_list') {
            const collegeManageStaff = get(newState, 'collegeManageStaff');
            if (collegeManageStaff !== undefined) {
              const subIdentifierState = get(collegeManageStaff, subIdentifier);
              if (subIdentifierState !== undefined) {
                // Note: subIdentifier becomes key for changeApiValue
                _this.changeApiValue(subIdentifier, subIdentifierState, identifier);
                // noDetails at the identifier level
                // Note: subIdentifier becomes key for changeNoDetailsValue
                _this.changeNoDetailsValue(subIdentifier, identifier);
              }
              // filters at the identifier level
              _this.handleFiltersAndListDisplay(identifier, subIdentifier);
            }
          }
        }
      }
      // mentoringNotes
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_MENTORING_NOTES) {
        const chkArr = ['student_info', 'tpo_notes', 'mentor_notes', 'student_tpo_notes', 'student_mentor_notes', 'all_notes', 'student_own_notes'];
        if (chkArr.indexOf(subIdentifier) !== -1) {
          const mentoringNotes = get(newState, 'mentoringNotes');
          if (mentoringNotes !== undefined) {
            const subIdentifierState = get(mentoringNotes, subIdentifier);
            if (subIdentifierState !== undefined) {
              // Note: subIdentifier becomes key for changeApiValue
              _this.changeApiValue(subIdentifier, subIdentifierState, identifier);
            }
          }
        }
      }
      // employerManageStaff
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPL_MANAGE_STAFF) {
        if (subIdentifier === 'main_list') {
          const employerManageStaff = get(newState, 'employerManageStaff');
          if (employerManageStaff !== undefined) {
            const subIdentifierState = get(employerManageStaff, subIdentifier);
            if (subIdentifierState !== undefined) {
              // Note: subIdentifier becomes key for changeApiValue
              _this.changeApiValue(subIdentifier, subIdentifierState, identifier);
              // noDetails at the identifier level
              // Note: subIdentifier becomes key for changeNoDetailsValue
              _this.changeNoDetailsValue(subIdentifier, identifier);
            }
            // filters at the identifier level
            _this.handleFiltersAndListDisplay(identifier, subIdentifier);
          }
        }
      }
      // jobPlacementReport
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_JOBPLMTREPORT) {
        // collegeCourses
        const collegeCourses = get(newState, 'collegeCourses');
        if (collegeCourses !== undefined) {
          _this.state.coursesList[identifier] = collegeCourses;
        }
        if (collegeCourses === undefined) {
          const jobPlacementReport = get(newState, 'jobPlacementReport');
          if (jobPlacementReport !== undefined) {
            _this.changeApiValue('jobPlacementReport', jobPlacementReport, identifier, subIdentifier);
            // noDetails at the identifier level
            _this.changeNoDetailsValue('jobPlacementReport', identifier);
          }
          // filters at the identifier level
          _this.handleFiltersAndListDisplay(identifier);
        }
      }
      // jobPlacementSummary
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_JOBPLMTSUMMARY) {
        const jobPlacementSummary = get(newState, 'jobPlacementSummary');
        if (jobPlacementSummary !== undefined) {
          _this.changeApiValue('jobPlacementSummary', jobPlacementSummary, identifier, subIdentifier);
        }
      }
      // eventsWorkshops
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EVENTS_WORKSHOPS) {
        if (subIdentifier === 'main_list') {
          const eventsWorkshops = get(newState, 'eventsWorkshops');
          if (eventsWorkshops !== undefined) {
            const subIdentifierState = get(eventsWorkshops, subIdentifier);
            if (subIdentifierState !== undefined) {
              // Note: subIdentifier becomes key for changeApiValue
              _this.changeApiValue(subIdentifier, subIdentifierState, identifier);
              // noDetails at the identifier level
              // Note: subIdentifier becomes key for changeNoDetailsValue
              _this.changeNoDetailsValue(subIdentifier, identifier);
            }
            // filters at the identifier level
            _this.handleFiltersAndListDisplay(identifier, subIdentifier);
          }
        } else if (subIdentifier === 'event_details') {
          const eventsWorkshops = get(newState, 'eventsWorkshops');
          if (eventsWorkshops !== undefined) {
            const subIdentifierState = get(eventsWorkshops, subIdentifier);
            if (subIdentifierState !== undefined) {
              const id = subIdentifierState.id;
              if (id) {
                _this.changeCompleteFormValue(subIdentifierState, identifier);
              }
            }
          }
        }
      }
      // employerCandidateSummary
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLCANDSUMMARY) {
        const employerCandidateSummary = get(newState, 'employerCandidateSummary');
        if (employerCandidateSummary !== undefined) {
          if (subIdentifier === 'summary' || subIdentifier === 'search' || subIdentifier.startsWith('summary_')) {
            let summaryMatched = false;
            let finalSubIdentifier = subIdentifier;
            if (subIdentifier.startsWith('summary_')) {
              summaryMatched = true;
              finalSubIdentifier = subIdentifier.slice(8); // slice 8 characters
            }
            let subIdentifierState;
            if (!summaryMatched) {
              subIdentifierState = get(employerCandidateSummary, finalSubIdentifier);
            } else {
              subIdentifierState = get(employerCandidateSummary, 'summary');
            }
            if (subIdentifierState !== undefined) {
              const prevObj = _this.state.api[identifier];
              if (finalSubIdentifier === 'summary') {
                const {
                    employerCandidateSummary
                  } = prevObj,
                  rest = __rest(prevObj, ["employerCandidateSummary"]);
                employerCandidateSummary['summary'] = subIdentifierState;
                const newObj = Object.assign({
                  employerCandidateSummary
                }, rest);
                _this.state.api[identifier] = newObj;
              } else if (finalSubIdentifier === 'search') {
                const {
                    employerCandidateSummary
                  } = prevObj,
                  rest = __rest(prevObj, ["employerCandidateSummary"]);
                employerCandidateSummary['search'] = subIdentifierState;
                const newObj = Object.assign({
                  employerCandidateSummary
                }, rest);
                _this.state.api[identifier] = newObj;
              } else if (finalSubIdentifier === 'degree_program') {
                if (subIdentifierState.hasOwnProperty('details') && subIdentifierState.details.hasOwnProperty('degree_program')) {
                  _this.state.degreePrograms[identifier] = subIdentifierState.details.degree_program;
                }
              } else if (finalSubIdentifier === 'degree_specialization') {
                if (subIdentifierState.hasOwnProperty('details') && subIdentifierState.details.hasOwnProperty('degree_specialization')) {
                  _this.state.degreeSpecializations[identifier] = subIdentifierState.details.degree_specialization;
                }
              }
            }
          } else {
            _this.changeApiValue('employerCandidateSummary', employerCandidateSummary, identifier, subIdentifier);
          }
        }
      }
      // staffManageUsers
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFF_MANAGE_USERS) {
        if (subIdentifier === 'main_list') {
          const staffManageUsers = get(newState, 'staffManageUsers');
          if (staffManageUsers !== undefined) {
            const subIdentifierState = get(staffManageUsers, subIdentifier);
            if (subIdentifierState !== undefined) {
              // Note: subIdentifier becomes key for changeApiValue
              _this.changeApiValue(subIdentifier, subIdentifierState, identifier);
              // noDetails at the identifier level
              // Note: subIdentifier becomes key for changeNoDetailsValue
              _this.changeNoDetailsValue(subIdentifier, identifier);
            }
            // filters at the identifier level
            _this.handleFiltersAndListDisplay(identifier, subIdentifier);
          }
        }
      }
      // employerUsageReport
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLUSAGEREPORT) {
        const employerUsageReport = get(newState, 'employerUsageReport');
        if (employerUsageReport !== undefined) {
          _this.changeApiValue('employerUsageReport', employerUsageReport, identifier, subIdentifier);
        }
      }
      // creditsUsageReport
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_CREDITSUSAGEREPORT) {
        const creditsUsageReport = get(newState, 'creditsUsageReport');
        if (creditsUsageReport !== undefined) {
          _this.changeApiValue('creditsUsageReport', creditsUsageReport, identifier, subIdentifier);
        }
      }
      // makePayment
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT) {
        const makePayment = get(newState, 'makePayment');
        if (makePayment !== undefined) {
          _this.changeApiValue('makePayment', makePayment, identifier, subIdentifier);
        }
      }
      // trackInvoicesReport
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT) {
        const trackInvoicesReport = get(newState, 'trackInvoicesReport');
        if (trackInvoicesReport !== undefined) {
          _this.changeApiValue('trackInvoicesReport', trackInvoicesReport, identifier, subIdentifier);
        }
      }
      // collegeDegreeDetails
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLDGRDETAILS) {
        if (subIdentifier === 'program_list' || subIdentifier === 'specialization_list') {
          const collegeDegreeDetails = get(newState, 'collegeDegreeDetails');
          if (collegeDegreeDetails !== undefined) {
            const subIdentifierState = get(collegeDegreeDetails, subIdentifier);
            if (subIdentifierState !== undefined) {
              // Note: subIdentifier becomes key for changeApiValue
              _this.changeApiValue(subIdentifier, subIdentifierState, identifier);
              // noDetails at the identifier level
              // Note: subIdentifier becomes key for changeNoDetailsValue
              _this.changeNoDetailsValue(subIdentifier, identifier);
            }
          }
          // filters at the identifier level
          _this.handleFiltersAndListDisplay(identifier, subIdentifier);
        }
      }
      // notificationPreferences
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_NOTNPREFERENCES) {
        let notificationPreferences = get(newState, 'notificationPreferences');
        if (notificationPreferences !== undefined) {
          _this.changeApiValue('notificationPreferences', notificationPreferences, identifier);
          const chkStr1 = get(newState, 'notificationPreferences.email.employer_activities');
          const chkStr2 = get(newState, 'notificationPreferences.email.college_activities');
          const chkStr3 = get(newState, 'notificationPreferences.email.student_jobs');
          if (chkStr1 !== '' || chkStr2 !== '' || chkStr3 !== '') {
            notificationPreferences = get(newState, 'notificationPreferences.email');
            _this.changeCompleteFormValue(notificationPreferences, identifier);
          }
        }
      }
      // applicantCredits
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_APPLICANTCREDITS) {
        const applicantCredits = get(newState, 'applicantCredits');
        if (applicantCredits !== undefined) {
          _this.changeApiValue('applicantCredits', applicantCredits, identifier, subIdentifier);
          // noDetails at the identifier level
          _this.changeNoDetailsValue('applicantCredits', identifier);
        }
        // filters at the identifier level
        _this.handleFiltersAndListDisplay(identifier);
      }
      // employerCollegeEvents
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS) {
        if (subIdentifier === 'college_list') {
          const employerCollegeEvents = get(newState, 'employerCollegeEvents');
          if (employerCollegeEvents !== undefined) {
            _this.state.api[identifier][subIdentifier] = employerCollegeEvents['college_list']['list'];
          }
        } else if (subIdentifier === 'main_list') {
          const employerCollegeEvents = get(newState, 'employerCollegeEvents');
          if (employerCollegeEvents !== undefined) {
            const subIdentifierState = get(employerCollegeEvents, subIdentifier);
            if (subIdentifierState !== undefined) {
              // Note: subIdentifier becomes key for changeApiValue
              _this.changeApiValue(subIdentifier, subIdentifierState, identifier);
              // noDetails at the identifier level
              // Note: subIdentifier becomes key for changeNoDetailsValue
              _this.changeNoDetailsValue(subIdentifier, identifier);
            }
            // filters at the identifier level
            _this.handleFiltersAndListDisplay(identifier, subIdentifier);
          }
        }
      }
      // add here
    };
    this.state = state;
  }
  setDefaultValues(defaultValues) {
    this.defaultValues = defaultValues;
  }
  changeApiValue(key, newState, identifier) {
    let subIdentifier = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    let commonMatch = false;
    const commonKeys = ['errorMessage', 'successMessage'];
    if (commonKeys.indexOf(key) !== -1) {
      commonMatch = true;
    }
    if (commonMatch) {
      // search subLevel first and then do it at the identifier level
      // common keys are most likely at the identifier level
      let subMatch = false;
      if (subIdentifier && get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined && get(this.state.api[identifier], subIdentifier) !== undefined) {
        subMatch = true;
        this.state.api[identifier][subIdentifier][key] = newState;
      }
      if (!subMatch && get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined) {
        this.state.api[identifier][key] = newState;
      }
    } else {
      if (!subIdentifier) {
        if (get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined) {
          this.state.api[identifier][key] = newState;
        }
      } else {
        if (get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined && get(this.state.api[identifier], subIdentifier) !== undefined) {
          this.state.api[identifier][subIdentifier][key] = newState;
        }
      }
    }
  }
  changeCompleteFormValue(newState, identifier) {
    let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    if (!subIdentifier && get(this.state, 'form') !== undefined) {
      this.state.form[identifier] = newState;
    }
    if (subIdentifier && get(this.state, 'form') !== undefined && get(this.state.form, identifier) !== undefined) {
      this.state.form[identifier][subIdentifier] = newState;
    }
  }
  changeNoDetailsValue(key, identifier) {
    let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    let stateObj = {};
    if (!subIdentifier) {
      if (get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined && get(this.state.api[identifier], key) !== undefined) {
        stateObj = this.state.api[identifier][key];
      }
    } else {
      if (get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined && get(this.state.api[identifier], subIdentifier) !== undefined && get(this.state.api[identifier][subIdentifier], key) !== undefined) {
        stateObj = this.state.api[identifier][subIdentifier][key];
      }
    }
    if (Object.keys(stateObj).length > 0) {
      const listLength = stateObj.list.length;
      const totalRecords = stateObj.total_records;
      const recordsPerPage = stateObj.records_per_page;
      this.state.api[identifier].noDetails = listLength > 0 ? false : true;
      this.adjustPagination(totalRecords, recordsPerPage, identifier);
    }
  }
  handleFiltersAndListDisplay(identifier) {
    let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    const obj = {
      filters: false
    };
    let stateObj = {};
    if (!subIdentifier) {
      if (get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined) {
        stateObj = this.state.api[identifier];
      }
    } else {
      if (get(this.state, 'api') !== undefined && get(this.state.api, identifier) !== undefined && get(this.state.api[identifier], subIdentifier) !== undefined) {
        stateObj = this.state.api[identifier][subIdentifier];
      }
    }
    let list = [];
    if (!stateObj.noDetails) {
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGESTUDENTS) {
        list = stateObj.collegeStudentsList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFFCOLLEGES) {
        list = stateObj.staffCollegesList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS) {
        list = stateObj.staffEmployersList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERCOLLEGESLIST) {
        list = stateObj.employerCollegesList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLOYERJOBSLIST) {
        list = stateObj.employerJobsList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS) {
        list = stateObj.approveLinkedJobsList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST) {
        list = stateObj.studentJobsList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEAPPROVAL) {
        list = stateObj.collegeApprovalList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTEMPLLIST) {
        list = stateObj.studentEmployersList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTLIST) {
        list = stateObj.collegeEnrollmentList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_TRACKACCESSUSERS) {
        list = stateObj.trackAccessUsersList.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_VIEWCANDIDATES) {
        list = stateObj.viewCandidates.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLL_MANAGE_STAFF) {
        list = stateObj.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPL_MANAGE_STAFF) {
        list = stateObj.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_JOBPLMTREPORT) {
        list = stateObj.jobPlacementReport.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_EVENTS_WORKSHOPS) {
        list = stateObj.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFF_MANAGE_USERS) {
        list = stateObj.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLDGRDETAILS) {
        list = stateObj.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_APPLICANTCREDITS) {
        list = stateObj.applicantCredits.list;
      } else if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS) {
        list = stateObj.list;
      }
      for (const x of list) {
        obj[x.id] = false;
      }
    }
    this.state.displaySections[identifier] = obj;
  }
  changeJobDetailsValue(newState, identifier) {
    if (get(this.state, 'api') !== undefined) {
      this.state.api[identifier].jobDetails = newState;
      if (newState && newState.job_title && newState.job_title !== '') {
        this.state.api[identifier].noDetails = false;
      }
    }
  }
  adjustPagination(count, recordsPerPage, identifier) {
    if (count > 0) {
      let page = this.state.pagination[identifier].p;
      if (!page) {
        page = 1;
      }
      // similar to api limit & offset logic
      const whNumber = Math.floor(count / recordsPerPage);
      const frNumber = count % recordsPerPage;
      const totalPages = whNumber + (frNumber > 0 ? 1 : 0);
      if (page > totalPages) {
        page = totalPages;
      }
      this.state.pagination[identifier].p = page;
    }
  }
}
exports.Base = Base;