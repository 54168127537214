"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class JobPlacementSummary extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_JOBPLMTSUMMARY]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_JOBPLMTSUMMARY)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
        },
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_JOBPLMTSUMMARY]: {
            graduation_year: ''
          }
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_JOBPLMTSUMMARY) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), {
        jobPlacementSummary: risesharp_common_1.Schema.getJobPlacementSummary()
      });
    }
    return {};
  }
}
exports.default = JobPlacementSummary;