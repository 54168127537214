import * as store from 'store';
import expirePlugin from 'store/plugins/expire';
// add store plugin
store.addPlugin(expirePlugin);

import { Constants } from 'risesharp-common';

class LocalStorage {
  setUserDetails(userDetails: {}) {
    store.set(Constants.USER_DETAILS_IDENTIFIER, userDetails);
  }

  getUserDetails(): {} {
    const userDetails = store.get(Constants.USER_DETAILS_IDENTIFIER);
    if (userDetails) {
      return userDetails;
    }
    return {};
  }

  removeUserDetails() {
    store.remove(Constants.USER_DETAILS_IDENTIFIER);
  }

  setCollegeDetails(collegeDetails: {}) {
    store.set(Constants.COLLEGE_DETAILS_IDENTIFIER, collegeDetails);
  }
  
  getCollegeDetails(): {} {
    const collegeDetails = store.get(Constants.COLLEGE_DETAILS_IDENTIFIER);
    if (collegeDetails) {
      return collegeDetails;
    }
    return {};
  }

  removeCollegeDetails() {
    store.remove(Constants.COLLEGE_DETAILS_IDENTIFIER);
  }

  setEmployerDetails(collegeDetails: {}) {
    store.set(Constants.EMPLOYER_DETAILS_IDENTIFIER, collegeDetails);
  }
  
  getEmployerDetails(): {} {
    const collegeDetails = store.get(Constants.EMPLOYER_DETAILS_IDENTIFIER);
    if (collegeDetails) {
      return collegeDetails;
    }
    return {};
  }

  removeEmployerDetails() {
    store.remove(Constants.EMPLOYER_DETAILS_IDENTIFIER);
  }

  setUpdateEmailTracker(timestamp: number) {
    store.set(Constants.UPDATE_EMAIL_TRACKER, timestamp);
  }
  
  getUpdateEmailTracker(): number {
    const updateEmailTracker = store.get(Constants.UPDATE_EMAIL_TRACKER);
    if (updateEmailTracker) {
      return updateEmailTracker;
    }
    return -1;
  }

  removeUpdateEmailTracker() {
    store.remove(Constants.UPDATE_EMAIL_TRACKER);
  }
  
  removeAll() {
    this.removeUserDetails();
    this.removeCollegeDetails();
    this.removeEmployerDetails();
    this.removeUpdateEmailTracker();
  }

  // events
  setLogoutEvent(skipUrl: string = '') {
    const expiration = new Date().getTime() + 2500;
    (store as any).set('logout-event', 'logout-event-' + Math.random(), expiration);
    (store as any).set('logout-event-skipUrl', skipUrl, expiration);
  }

  watchLogoutEvent(): [boolean, string] {
    let val1 = false;
    let val2 = '';

    const val3 = store.get('logout-event');
    if (val3 && val3.startsWith('logout-event-')) {
      val1 = true;
    }

    val2 = store.get('logout-event-skipUrl');

    return [val1, val2];
  }
}

const LocalStorageInstance = new LocalStorage();
export { LocalStorageInstance as LocalStorage };
