"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.General = void 0;
const ent = require('ent');
const constants_1 = require("./constants");
const validator_1 = require("./validator");
class General {
  constructor() {
    // personality assessment
    this.IDENTIFIER_PERSONALITY_ASSESSMENT = 'personality';
    this.IDENTIFIER_PERSONALITYASSMT_PLANS = 'detailed_plans';
    this.IDENTIFIER_PERSONALITYASSMT_GOALS = 'clear_goals';
    this.IDENTIFIER_PERSONALITYASSMT_LIKE = 'like_everyone';
    this.IDENTIFIER_PERSONALITYASSMT_TASKS = 'finish_tasks';
    this.IDENTIFIER_PERSONALITYASSMT_MONEY = 'money_motivation';
    this.IDENTIFIER_PERSONALITYASSMT_TEAM = 'team_environment';
    // interests assessment
    this.IDENTIFIER_INTERESTS_ASSESSMENT = 'interests';
    this.IDENTIFIER_INTERESTSASSMT_REALISTIC = 'realistic';
    this.IDENTIFIER_INTERESTSASSMT_INVESTIGATIVE = 'investigative';
    this.IDENTIFIER_INTERESTSASSMT_ARTISTIC = 'artistic';
    this.IDENTIFIER_INTERESTSASSMT_SOCIAL = 'social';
    this.IDENTIFIER_INTERESTSASSMT_ENTERPRISING = 'enterprising';
    this.IDENTIFIER_INTERESTSASSMT_CONVENTIONAL = 'conventional';
    // values assessment
    this.IDENTIFIER_VALUES_ASSESSMENT = 'values';
    this.IDENTIFIER_VALUESASSMT_FUN = 'fun';
    this.IDENTIFIER_VALUESASSMT_JOB = 'job';
    this.IDENTIFIER_VALUESASSMT_RECOGNITION = 'recognition';
    this.IDENTIFIER_VALUESASSMT_COMPENSATION = 'compensation';
    this.IDENTIFIER_VALUESASSMT_LOYALTY = 'loyalty';
    this.IDENTIFIER_VALUESASSMT_STRUCTURE = 'structure';
    this.IDENTIFIER_VALUESASSMT_INDEPENDENCE = 'independence';
    this.IDENTIFIER_VALUESASSMT_RESPECT = 'respect';
    this.IDENTIFIER_VALUESASSMT_RISK = 'risk';
    this.IDENTIFIER_VALUESASSMT_VARIETY = 'variety';
    this.IDENTIFIER_VALUESASSMT_LEARNING = 'learning';
    this.IDENTIFIER_VALUESASSMT_TRAINING = 'training';
    this.IDENTIFIER_VALUESASSMT_COLLEAGUE = 'colleague';
    this.IDENTIFIER_VALUESASSMT_DIFFERENCE = 'difference';
    this.IDENTIFIER_VALUESASSMT_CREATIVE = 'creative';
    // skills assessment
    this.IDENTIFIER_SKILLS_ASSESSMENT = 'skills';
    // competency assessment
    this.IDENTIFIER_COMPETENCY_ASSESSMENT = 'competency';
    this.IDENTIFIER_COMPETENCYASSMT_COMMUNICATION = 'communication';
    this.IDENTIFIER_COMPETENCYASSMT_TEAMWORK = 'teamwork';
    this.IDENTIFIER_COMPETENCYASSMT_LEADERSHIP = 'leadership';
    this.IDENTIFIER_COMPETENCYASSMT_CREATIVITY = 'creativity';
    this.IDENTIFIER_COMPETENCYASSMT_PROFESSIONALISM = 'professionalism';
    this.IDENTIFIER_COMPETENCYASSMT_DIVERSITY = 'diversity';
    this.IDENTIFIER_COMPETENCYASSMT_ADAPTABILITY = 'adaptability';
    this.IDENTIFIER_COMPETENCYASSMT_DIGITAL = 'digital_competence';
    this.IDENTIFIER_COMPETENCYASSMT_SCORE = 'readiness_score';
    this.IDENTIFIER_COMPETENCYASSMT_RANK1 = 'Need to Develop';
    this.IDENTIFIER_COMPETENCYASSMT_RANK2 = 'Developing';
    this.IDENTIFIER_COMPETENCYASSMT_RANK3 = 'Excelling';
    this.IDENTIFIER_COMPETENCYASSMT_RANK4 = 'Not Required';
  }
  checkValue(input) {
    return input !== undefined && input !== null && input !== '';
  }
  getDefaultUserTypes() {
    let skipStaff = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    const userTypeOptions = [constants_1.Constants.STAFF_USER_TYPE, constants_1.Constants.COLLEGES_USER_TYPE, constants_1.Constants.STUDENTS_USER_TYPE, constants_1.Constants.EMPLOYERS_USER_TYPE];
    if (!skipStaff) {
      return userTypeOptions;
    }
    return userTypeOptions.filter(e => e !== constants_1.Constants.STAFF_USER_TYPE);
  }
  getStates() {
    let countryCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'IN';
    if (countryCode === 'IN') {
      const obj = ['Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'];
      return obj;
    }
    return [];
  }
  getJobLocationCities() {
    let countryCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'IN';
    const obj = [];
    if (countryCode === 'IN') {
      const states = this.getStates(countryCode);
      for (const state of states) {
        switch (state) {
          case 'Andaman and Nicobar Islands':
            obj.push([state]);
            break;
          case 'Andhra Pradesh':
            obj.push(['Vishakapatnam', state]);
            obj.push(['Vijayawada', state]);
            obj.push(['Guntur', state]);
            obj.push(['Nellore', state]);
            obj.push(['Kurnool', state]);
            obj.push(['Kakinada', state]);
            obj.push(['Rajamahendravaram', state]);
            obj.push(['Kadapa', state]);
            obj.push(['Tirupati', state]);
            obj.push(['Eluru', state]);
            obj.push(['Ongole', state]);
            obj.push(['Anantapur', state]);
            obj.push(['Vizianagaram', state]);
            obj.push(['Tenali', state]);
            obj.push(['Prodattur', state]);
            obj.push(['Nandyal', state]);
            obj.push(['Adoni', state]);
            obj.push(['Madanapalle', state]);
            obj.push(['Machilipatnam', state]);
            obj.push(['Chittoor', state]);
            obj.push(['Hindupur', state]);
            obj.push(['Bhimavaram', state]);
            obj.push(['Srikakulam', state]);
            obj.push(['Anakapalle', state]);
            obj.push(['Tadepalligudem', state]);
            obj.push(['Dharmavaram', state]);
            obj.push(['Gudivada', state]);
            obj.push(['Narasaraopet', state]);
            obj.push(['Tadipatri', state]);
            obj.push(['Chilakaluripet', state]);
            obj.push(['Palakollu', state]);
            obj.push(['Amaravati', state]);
            obj.push(['Kavali', state]);
            break;
          case 'Arunachal Pradesh':
            obj.push(['Along', state]);
            obj.push(['Basar', state]);
            obj.push(['Bomdila', state]);
            obj.push(['Itanagar', state]);
            obj.push(['Khonsa', state]);
            obj.push(['Margherita', state]);
            obj.push(['Naharlagun', state]);
            obj.push(['Pasighat', state]);
            obj.push(['Tawang', state]);
            obj.push(['Tezu', state]);
            obj.push(['Ziro', state]);
            break;
          case 'Assam':
            obj.push(['Guwahati', state]);
            obj.push(['Silchar', state]);
            obj.push(['Dibrugarh', state]);
            obj.push(['Jorhat', state]);
            obj.push(['Nagaon', state]);
            obj.push(['Tinsukia', state]);
            obj.push(['Tezpur', state]);
            break;
          case 'Bihar':
            obj.push(['Patna', state]);
            obj.push(['Gaya', state]);
            obj.push(['Bhagalpur', state]);
            obj.push(['Muzaffarpur', state]);
            obj.push(['Purnia', state]);
            obj.push(['Darbhanga', state]);
            obj.push(['Bihar Sharif', state]);
            obj.push(['Arrah', state]);
            obj.push(['Begusarai', state]);
            obj.push(['Katihar', state]);
            obj.push(['Munger', state]);
            obj.push(['Chhapra', state]);
            obj.push(['Danapur', state]);
            obj.push(['Bettiah', state]);
            obj.push(['Saharsa', state]);
            obj.push(['Sasaram', state]);
            obj.push(['Hajipur', state]);
            obj.push(['Dehri', state]);
            obj.push(['Siwan', state]);
            obj.push(['Motihari', state]);
            obj.push(['Nawada', state]);
            obj.push(['Bagaha', state]);
            obj.push(['Buxar', state]);
            obj.push(['Kishanganj', state]);
            obj.push(['Sitamarhi', state]);
            obj.push(['Jamalpur', state]);
            obj.push(['Jehanabad', state]);
            obj.push(['Aurangabad', state]);
            break;
          case 'Chandigarh':
            obj.push([state]);
            break;
          case 'Chhattisgarh':
            obj.push(['Raipur', state]);
            obj.push(['Bhilai', state]);
            obj.push(['Korba', state]);
            obj.push(['Bilaspur', state]);
            obj.push(['Rajnandgaon', state]);
            obj.push(['Raigarh', state]);
            obj.push(['Jagdalpur', state]);
            obj.push(['Ambikapur', state]);
            obj.push(['Chirmiri', state]);
            obj.push(['Dhamtari', state]);
            obj.push(['Mahasamund', state]);
            break;
          case 'Dadra and Nagar Haveli':
            obj.push([state]);
            break;
          case 'Daman and Diu':
            obj.push([state]);
            break;
          case 'Delhi':
            obj.push([state]);
            break;
          case 'Goa':
            obj.push(['Bicholim', state]);
            obj.push(['Canacona', state]);
            obj.push(['Cuncolim', state]);
            obj.push(['Curchorem', state]);
            obj.push(['Mapusa', state]);
            obj.push(['Margao', state]);
            obj.push(['Mormugao', state]);
            obj.push(['Panaji', state]);
            obj.push(['Pernem', state]);
            obj.push(['Ponda', state]);
            obj.push(['Quepem', state]);
            obj.push(['Sanguem', state]);
            obj.push(['Sanquelim', state]);
            obj.push(['Valpoi', state]);
            break;
          case 'Gujarat':
            obj.push(['Ahmedabad', state]);
            obj.push(['Surat', state]);
            obj.push(['Vadodara', state]);
            obj.push(['Rajkot', state]);
            obj.push(['Bhavnagar', state]);
            obj.push(['Jamnagar', state]);
            obj.push(['Junagadh', state]);
            obj.push(['Anand', state]);
            obj.push(['Navsari', state]);
            obj.push(['Morbi', state]);
            obj.push(['Gandhidham', state]);
            obj.push(['Nadiad', state]);
            obj.push(['Surendranagar', state]);
            obj.push(['Bharuch', state]);
            obj.push(['Porbandar', state]);
            obj.push(['Gandhinagar', state]);
            break;
          case 'Haryana':
            obj.push(['Faridabad', state]);
            obj.push(['Gurugram', state]);
            obj.push(['Panipat', state]);
            obj.push(['Ambala', state]);
            obj.push(['Yamunanagar', state]);
            obj.push(['Rohtak', state]);
            obj.push(['Hisar', state]);
            obj.push(['Karnal', state]);
            obj.push(['Sonipat', state]);
            obj.push(['Panchkula', state]);
            obj.push(['Bhiwani', state]);
            obj.push(['Sirsa', state]);
            obj.push(['Bahadurgarh', state]);
            obj.push(['Jind', state]);
            obj.push(['Thanesar', state]);
            obj.push(['Kaithal', state]);
            obj.push(['Rewari', state]);
            obj.push(['Palwal', state]);
            obj.push(['Pundri', state]);
            obj.push(['Kosil', state]);
            break;
          case 'Himachal Pradesh':
            obj.push(['Shimla', state]);
            obj.push(['Solan', state]);
            obj.push(['Dharamsala', state]);
            obj.push(['Baddi', state]);
            obj.push(['Nahan', state]);
            obj.push(['Mandi', state]);
            obj.push(['Paonta Sahib', state]);
            obj.push(['Sundarnagar', state]);
            obj.push(['Chamba', state]);
            obj.push(['Kullu', state]);
            break;
          case 'Jammu and Kashmir':
            obj.push(['Srinagar', state]);
            obj.push(['Jammu', state]);
            obj.push(['Anantnag', state]);
            obj.push(['Udhampur', state]);
            obj.push(['Baramulla', state]);
            obj.push(['Sopore', state]);
            obj.push(['Kathua', state]);
            obj.push(['Bandipura', state]);
            obj.push(['Leh', state]);
            obj.push(['Rajouri', state]);
            obj.push(['Ganderbal', state]);
            obj.push(['Poonch', state]);
            obj.push(['Kulgam', state]);
            obj.push(['Bijbehara', state]);
            obj.push(['Kupwara', state]);
            obj.push(['Doda', state]);
            obj.push(['Akhnoor', state]);
            break;
          case 'Jharkhand':
            obj.push(['Jamshedpur', state]);
            obj.push(['Dhanbad', state]);
            obj.push(['Ranchi', state]);
            obj.push(['Bokaro Steel City', state]);
            obj.push(['Deoghar', state]);
            obj.push(['Chakradharpur', state]);
            obj.push(['Phusro', state]);
            obj.push(['Hazaribagh', state]);
            obj.push(['Giridih', state]);
            obj.push(['Ramgarh', state]);
            obj.push(['Medininagar', state]);
            obj.push(['Chirkunda', state]);
            break;
          case 'Karnataka':
            obj.push(['Bengaluru', state]);
            obj.push(['Hubli-Dharwad', state]);
            obj.push(['Mysuru', state]);
            obj.push(['Kalaburagi', state]);
            obj.push(['Mangaluru', state]);
            obj.push(['Belagavi', state]);
            obj.push(['Davangere', state]);
            obj.push(['Ballari', state]);
            obj.push(['Vijayapura', state]);
            obj.push(['Shivamogga', state]);
            obj.push(['Tumakuru', state]);
            obj.push(['Raichur', state]);
            obj.push(['Bidar', state]);
            obj.push(['Hosapete', state]);
            obj.push(['Gadag-Betageri', state]);
            obj.push(['Robertsonpete', state]);
            obj.push(['Hassan', state]);
            obj.push(['Bhadravati', state]);
            obj.push(['Chitradurga', state]);
            obj.push(['Udipi', state]);
            obj.push(['Kolara', state]);
            obj.push(['Mandya', state]);
            obj.push(['Chikkamagaluru', state]);
            obj.push(['Gangavati', state]);
            obj.push(['Bagalkote', state]);
            obj.push(['Ranebennuru', state]);
            break;
          case 'Kerala':
            obj.push(['Thiruvananthapuram', state]);
            obj.push(['Kochi', state]);
            obj.push(['Kozhikode', state]);
            obj.push(['Kollam', state]);
            obj.push(['Thrissur', state]);
            obj.push(['Alappuzha', state]);
            obj.push(['Palakkad', state]);
            obj.push(['Malappuram', state]);
            obj.push(['Manjeri', state]);
            obj.push(['Thalaserry', state]);
            obj.push(['Ponnani', state]);
            obj.push(['Vadakara', state]);
            obj.push(['Kanangad', state]);
            obj.push(['Taliparamba', state]);
            obj.push(['Payyanur', state]);
            obj.push(['Koyilandy', state]);
            obj.push(['Neyyattinkara', state]);
            obj.push(['Beypore', state]);
            obj.push(['Kayamkulam', state]);
            obj.push(['Kannur', state]);
            obj.push(['Tirur', state]);
            obj.push(['Kottayam', state]);
            obj.push(['Nileshwaram', state]);
            obj.push(['Kasaragod', state]);
            obj.push(['Kunnamkulam', state]);
            break;
          case 'Lakshadweep':
            obj.push([state]);
            break;
          case 'Madhya Pradesh':
            obj.push(['Indore', state]);
            obj.push(['Bhopal', state]);
            obj.push(['Jabalpur', state]);
            obj.push(['Gwalior', state]);
            obj.push(['Ujjain', state]);
            obj.push(['Sagar', state]);
            obj.push(['Dewas', state]);
            obj.push(['Satna', state]);
            obj.push(['Ratlam', state]);
            obj.push(['Rewa', state]);
            obj.push(['Murwara', state]);
            obj.push(['Singrauli', state]);
            obj.push(['Burhanpur', state]);
            obj.push(['Khandwa', state]);
            obj.push(['Morena', state]);
            obj.push(['Bhind', state]);
            obj.push(['Chhindwara', state]);
            obj.push(['Guna', state]);
            obj.push(['Shivpuri', state]);
            obj.push(['Vidisha', state]);
            obj.push(['Damoh', state]);
            obj.push(['Chhatarpur', state]);
            obj.push(['Mandsaur', state]);
            obj.push(['Khargone', state]);
            obj.push(['Nimach', state]);
            break;
          case 'Maharashtra':
            obj.push(['Mumbai', state]);
            obj.push(['Pune', state]);
            obj.push(['Nagpur', state]);
            obj.push(['Nashik', state]);
            obj.push(['Vasai-Virar', state]);
            obj.push(['Aurangabad', state]);
            obj.push(['Solapur', state]);
            obj.push(['Bhiwandi', state]);
            obj.push(['Amravati', state]);
            obj.push(['Malegaon', state]);
            obj.push(['Kohlapur', state]);
            obj.push(['Nanded', state]);
            obj.push(['Sangli', state]);
            obj.push(['Jalgaon', state]);
            obj.push(['Akola', state]);
            obj.push(['Latur', state]);
            obj.push(['Ahmadnagar', state]);
            obj.push(['Dhule', state]);
            obj.push(['Ichalkaranji', state]);
            obj.push(['Chandrapur', state]);
            obj.push(['Parbhani', state]);
            obj.push(['Jalna', state]);
            obj.push(['Bhusawal', state]);
            obj.push(['Navi Mumbai', state]);
            obj.push(['Panvel', state]);
            break;
          case 'Manipur':
            obj.push(['Churachandpur', state]);
            obj.push(['Imphal', state]);
            obj.push(['Kakching', state]);
            obj.push(['Mayang Imphal', state]);
            obj.push(['Moirang', state]);
            obj.push(['Phek', state]);
            obj.push(['Thoubal', state]);
            obj.push(['Wangjing', state]);
            obj.push(['Yairipok', state]);
            break;
          case 'Meghalaya':
            obj.push(['Cherrapunji', state]);
            obj.push(['Mairang', state]);
            obj.push(['Mankachar', state]);
            obj.push(['Nongpoh', state]);
            obj.push(['Nongstoin', state]);
            obj.push(['Shillong', state]);
            obj.push(['Tura', state]);
            break;
          case 'Mizoram':
            obj.push(['Aizawl', state]);
            obj.push(['Darlawn', state]);
            obj.push(['Khawhai', state]);
            obj.push(['Kolasib', state]);
            obj.push(['Lunglei', state]);
            obj.push(['Mamit', state]);
            obj.push(['North Vanlaiphai', state]);
            obj.push(['Saiha', state]);
            obj.push(['Sairang', state]);
            obj.push(['Saitlaw', state]);
            obj.push(['Serchhip', state]);
            obj.push(['Thenzawl', state]);
            break;
          case 'Nagaland':
            obj.push(['Dimapur', state]);
            obj.push(['Kohima', state]);
            obj.push(['Mokokchung', state]);
            obj.push(['Mon', state]);
            obj.push(['Tuensang', state]);
            obj.push(['Wokha', state]);
            obj.push(['Zunheboto', state]);
            break;
          case 'Odisha':
            obj.push(['Bhubaneswar', state]);
            obj.push(['Cuttack', state]);
            obj.push(['Rourkela', state]);
            obj.push(['Bramhapur', state]);
            obj.push(['Sambalpur', state]);
            obj.push(['Puri', state]);
            obj.push(['Balasore', state]);
            obj.push(['Bhadrak', state]);
            obj.push(['Baripada', state]);
            obj.push(['Balangir', state]);
            obj.push(['Jharsuguda', state]);
            obj.push(['Jeypore', state]);
            obj.push(['Bargarh', state]);
            obj.push(['Rayagada', state]);
            obj.push(['Bhawanipatna', state]);
            obj.push(['Paradip', state]);
            obj.push(['Phulbani', state]);
            obj.push(['Jeypore', state]);
            obj.push(['Angul', state]);
            obj.push(['Dhenkanal', state]);
            break;
          case 'Puducherry':
            obj.push(['Karaikal', state]);
            obj.push([state]);
            break;
          case 'Punjab':
            obj.push(['Ludhiana', state]);
            obj.push(['Amritsar', state]);
            obj.push(['Jalandhar', state]);
            obj.push(['Patiala', state]);
            obj.push(['Bathinda', state]);
            obj.push(['Hoshiarpur', state]);
            obj.push(['Mohali', state]);
            obj.push(['Batala', state]);
            obj.push(['Pathankot', state]);
            obj.push(['Moga', state]);
            obj.push(['Abohar', state]);
            obj.push(['Malerkotla', state]);
            obj.push(['Khanna', state]);
            obj.push(['Phagwara', state]);
            obj.push(['Muktasar', state]);
            obj.push(['Bamala', state]);
            obj.push(['Rajpura', state]);
            obj.push(['Firozpur', state]);
            obj.push(['Kapurthala', state]);
            obj.push(['Sunam', state]);
            break;
          case 'Rajasthan':
            obj.push(['Jaipur', state]);
            obj.push(['Jodhpur', state]);
            obj.push(['Kota', state]);
            obj.push(['Bikaner', state]);
            obj.push(['Ajmer', state]);
            obj.push(['Udaipur', state]);
            obj.push(['Bhilwara', state]);
            obj.push(['Alwar', state]);
            obj.push(['Bharatpur', state]);
            obj.push(['Sri Ganganagar', state]);
            obj.push(['Sikar', state]);
            obj.push(['Pali', state]);
            obj.push(['Hanumangarh', state]);
            break;
          case 'Sikkim':
            obj.push(['Gangtok', state]);
            obj.push(['Jorethang', state]);
            obj.push(['Namchi', state]);
            obj.push(['Rangpo', state]);
            obj.push(['Yuksom', state]);
            break;
          case 'Tamil Nadu':
            obj.push(['Chennai', state]);
            obj.push(['Coimbatore', state]);
            obj.push(['Madurai', state]);
            obj.push(['Tiruchirapalli', state]);
            obj.push(['Tiruppur', state]);
            obj.push(['Salem', state]);
            obj.push(['Erode', state]);
            obj.push(['Tirunelveli', state]);
            obj.push(['Vellore', state]);
            obj.push(['Thoothukkudi', state]);
            obj.push(['Dindigul', state]);
            obj.push(['Thanjavur', state]);
            obj.push(['Ranipet', state]);
            obj.push(['Sivakasi', state]);
            obj.push(['Karur', state]);
            obj.push(['Hosur', state]);
            obj.push(['Nagercoil', state]);
            obj.push(['Kancheepuram', state]);
            obj.push(['Kumarapalayam', state]);
            obj.push(['Karaikkudi', state]);
            obj.push(['Neyveli', state]);
            obj.push(['Cuddalore', state]);
            obj.push(['Kumbakonam', state]);
            obj.push(['Tiruvannamalai', state]);
            obj.push(['Pollachi', state]);
            obj.push(['Rajapalayam', state]);
            obj.push(['Gudiyatham', state]);
            obj.push(['Pudukkottai', state]);
            obj.push(['Vaniyampadi', state]);
            obj.push(['Ambur', state]);
            obj.push(['Nagapattinam', state]);
            break;
          case 'Telangana':
            obj.push(['Hyderabad', state]);
            obj.push(['Warangal', state]);
            obj.push(['Nizamabad', state]);
            obj.push(['Khammam', state]);
            obj.push(['Karimnagar', state]);
            obj.push(['Ramagundam', state]);
            obj.push(['Mahabubnagar', state]);
            obj.push(['Nalgonda', state]);
            obj.push(['Adilabad', state]);
            obj.push(['Suryapet', state]);
            obj.push(['Miryalaguda', state]);
            obj.push(['Jagtial', state]);
            break;
          case 'Tripura':
            obj.push(['Agartala', state]);
            obj.push(['Amarpur', state]);
            obj.push(['Belonia', state]);
            obj.push(['Dharmanagar', state]);
            obj.push(['Kailasahar', state]);
            obj.push(['Kamalpur', state]);
            obj.push(['Khowal', state]);
            obj.push(['Kumarghat', state]);
            obj.push(['Ranirbazar', state]);
            obj.push(['Sabroom', state]);
            obj.push(['Sonamura', state]);
            obj.push(['Tellamura', state]);
            obj.push(['Udaipur', state]);
            obj.push(['Bishalgarh', state]);
            obj.push(['Santirbazar', state]);
            obj.push(['Ambassa', state]);
            obj.push(['Jirania', state]);
            obj.push(['Mohanpur', state]);
            obj.push(['Melagarh', state]);
            obj.push(['Panisagar', state]);
            break;
          case 'Uttar Pradesh':
            obj.push(['Lucknow', state]);
            obj.push(['Kanpur', state]);
            obj.push(['Ghaziabad', state]);
            obj.push(['Agra', state]);
            obj.push(['Meerut', state]);
            obj.push(['Varanasi', state]);
            obj.push(['Prayagraj', state]);
            obj.push(['Bareilly', state]);
            obj.push(['Aligarh', state]);
            obj.push(['Moradabad', state]);
            obj.push(['Saharanpur', state]);
            obj.push(['Gorakhpur', state]);
            obj.push(['Faizabad', state]);
            obj.push(['Firozabad', state]);
            obj.push(['Jhansi', state]);
            obj.push(['Muzaffarnagar', state]);
            obj.push(['Mathura-Vrindavan', state]);
            obj.push(['Budaun', state]);
            obj.push(['Rampur', state]);
            obj.push(['Shahjahanpur', state]);
            obj.push(['Farukkhabad-Fategarh', state]);
            obj.push(['Ayodhya Cantt', state]);
            obj.push(['Maunath Bhanjan', state]);
            obj.push(['Hapur', state]);
            obj.push(['Noida', state]);
            obj.push(['Etawah', state]);
            obj.push(['Mirzapur-Vindhyachal', state]);
            obj.push(['Bulandshahr', state]);
            obj.push(['Sambhal', state]);
            obj.push(['Amroha', state]);
            obj.push(['Hardoi', state]);
            obj.push(['Fatehpur', state]);
            obj.push(['Raebareli', state]);
            obj.push(['Orai', state]);
            obj.push(['Sitapur', state]);
            obj.push(['Bahraich', state]);
            obj.push(['Modinagar', state]);
            obj.push(['Unnao', state]);
            obj.push(['Jaunpur', state]);
            obj.push(['Lakhimpur', state]);
            obj.push(['Hathras', state]);
            obj.push(['Banda', state]);
            obj.push(['Pilibhit', state]);
            obj.push(['Deen Dayal Upadhayay', state]);
            obj.push(['Barabanki', state]);
            obj.push(['Khurja', state]);
            obj.push(['Gonda', state]);
            obj.push(['Mainpuri', state]);
            obj.push(['Lalitpur', state]);
            obj.push(['Etah', state]);
            obj.push(['Deoria', state]);
            obj.push(['Ujhani', state]);
            obj.push(['Ghazipur', state]);
            obj.push(['Sultanpur', state]);
            obj.push(['Azamgarh', state]);
            obj.push(['Bijnore', state]);
            obj.push(['Sahaswan', state]);
            obj.push(['Basti', state]);
            obj.push(['Chandausi', state]);
            obj.push(['Akbarpur', state]);
            obj.push(['Ballia', state]);
            obj.push(['Tanda', state]);
            obj.push(['Greater Noida', state]);
            obj.push(['Shikohabad', state]);
            obj.push(['Shamli', state]);
            obj.push(['Awagarh', state]);
            obj.push(['Kasganj', state]);
            break;
          case 'Uttarakhand':
            obj.push(['Dehradun', state]);
            obj.push(['Haridwar', state]);
            obj.push(['Roorkee', state]);
            obj.push(['Haldwani-Kathgodam', state]);
            obj.push(['Rudrapur', state]);
            obj.push(['Kashipur', state]);
            obj.push(['Rishikesh', state]);
            break;
          case 'West Bengal':
            obj.push(['Kolkata', state]);
            obj.push(['Asansol', state]);
            obj.push(['Siliguri', state]);
            obj.push(['Durgapur', state]);
            obj.push(['Bardhaman', state]);
            obj.push(['English Bazar', state]);
            obj.push(['Baharampur', state]);
            obj.push(['Habra', state]);
            obj.push(['Kharagpur', state]);
            obj.push(['Shantipur', state]);
            obj.push(['Dankuni', state]);
            obj.push(['Dhulian', state]);
            obj.push(['Ranaghat', state]);
            obj.push(['Haldia', state]);
            obj.push(['Raiganj', state]);
            obj.push(['Krishnanagar', state]);
            obj.push(['Nabadwip', state]);
            obj.push(['Medinipur', state]);
            obj.push(['Jaipaiguri', state]);
            obj.push(['Balurghat', state]);
            obj.push(['Basirhat', state]);
            obj.push(['Bankura', state]);
            obj.push(['Chakdaha', state]);
            obj.push(['Darjeeling', state]);
            obj.push(['Alipurduar', state]);
            obj.push(['Purulia', state]);
            obj.push(['Jangipur', state]);
            obj.push(['Bangaon', state]);
            obj.push(['Cooch Behar', state]);
            break;
          default:
            break;
        }
      }
    }
    obj.push(['Remote']);
    obj.push(['Other']);
    return obj;
  }
  getHighestDegree() {
    const obj = ["Associate's Degree", "Bachelor's Degree", "Master's Degree", "Doctoral Degree"];
    return obj;
  }
  getTotalStudents() {
    const obj = ['Less than 1000', '1001 - 5000', 'More than 5000'];
    return obj;
  }
  getPaymentPlan() {
    const obj = ['Silver Plan', 'Gold Plan', 'Platinum Plan'];
    return obj;
  }
  getTotalEmployees() {
    const obj = ['Less than 1000', '1001 - 5000', 'More than 5000'];
    return obj;
  }
  getIndustryTypes() {
    const obj = ['Automotive', 'Construction', 'Engineering', 'Financial Services', 'Government', 'Manufacturing', 'Non-Profit', 'Retail', 'Technology', 'Business Process Outsourcing (BPO)', 'Education', 'Healthcare', 'Media', 'Professional Services', 'Other'];
    return obj;
  }
  getPersonalityAssessmentObj() {
    return {
      [this.IDENTIFIER_PERSONALITYASSMT_PLANS]: 'I enjoy making detailed plans',
      [this.IDENTIFIER_PERSONALITYASSMT_GOALS]: 'I have clear goals',
      [this.IDENTIFIER_PERSONALITYASSMT_LIKE]: 'I like everyone I meet',
      [this.IDENTIFIER_PERSONALITYASSMT_TASKS]: 'I prefer to finish tasks quickly than perfectly',
      [this.IDENTIFIER_PERSONALITYASSMT_MONEY]: 'Money motivates and energizes me',
      [this.IDENTIFIER_PERSONALITYASSMT_TEAM]: 'I enjoy working in a team environment'
    };
  }
  getPersonalityAssessmentOptions() {
    return ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'];
  }
  getInterestsAssessmentObj() {
    return {
      [this.IDENTIFIER_INTERESTSASSMT_REALISTIC]: {
        interest: 'Realistic',
        description: ['Practical', 'Enjoy practical and physical', 'Engage with tools, machines and gadgets']
      },
      [this.IDENTIFIER_INTERESTSASSMT_INVESTIGATIVE]: {
        interest: 'Investigative',
        description: ['Analytical', 'Enjoy gathering information and conducting analysis', 'Appreciates intellectual activities and tasks']
      },
      [this.IDENTIFIER_INTERESTSASSMT_ARTISTIC]: {
        interest: 'Artistic',
        description: ['Creative', 'Enjoy aesthetics and self expression', 'Favor unstructured environments']
      },
      [this.IDENTIFIER_INTERESTSASSMT_SOCIAL]: {
        interest: 'Social',
        description: ['Connected', 'Enjoy helping, training, and counseling', 'Thrive engaging with others']
      },
      [this.IDENTIFIER_INTERESTSASSMT_ENTERPRISING]: {
        interest: 'Enterprising',
        description: ['Influential', 'Enjoy managing and persuasion', 'Prefer to lead']
      },
      [this.IDENTIFIER_INTERESTSASSMT_CONVENTIONAL]: {
        interest: 'Conventional',
        description: ['Systematic', 'Enjoy details and accuracy', 'Comfortable with structure and within a chain of command']
      }
    };
  }
  getInterestsAssessmentOptions() {
    return ['One (1)', 'Two (2)', 'Three (3)', 'Four (4)', 'Five (5)', 'Six (6)'];
  }
  getValuesAssessmentObj() {
    return {
      [this.IDENTIFIER_VALUESASSMT_FUN]: 'Fun',
      [this.IDENTIFIER_VALUESASSMT_JOB]: 'Job',
      [this.IDENTIFIER_VALUESASSMT_RECOGNITION]: 'Recognition',
      [this.IDENTIFIER_VALUESASSMT_COMPENSATION]: 'Compensation',
      [this.IDENTIFIER_VALUESASSMT_LOYALTY]: 'Loyalty',
      [this.IDENTIFIER_VALUESASSMT_STRUCTURE]: 'Structure',
      [this.IDENTIFIER_VALUESASSMT_INDEPENDENCE]: 'Independence',
      [this.IDENTIFIER_VALUESASSMT_RESPECT]: 'Respect',
      [this.IDENTIFIER_VALUESASSMT_RISK]: 'Risk Taking',
      [this.IDENTIFIER_VALUESASSMT_VARIETY]: 'Variety',
      [this.IDENTIFIER_VALUESASSMT_LEARNING]: 'Learning',
      [this.IDENTIFIER_VALUESASSMT_TRAINING]: 'Training & Development',
      [this.IDENTIFIER_VALUESASSMT_COLLEAGUE]: 'Colleague Relationships',
      [this.IDENTIFIER_VALUESASSMT_DIFFERENCE]: 'Making Difference',
      [this.IDENTIFIER_VALUESASSMT_CREATIVE]: 'Creative Opportunities'
    };
  }
  getValuesAssessmentOptions() {
    return ['1: Not at all important', '2: Slightly Important', '3: Important', '4: Fairly Important', '5: Most Important'];
  }
  getJobTypes() {
    return ['Full-time', 'Part-time', 'Job-shadowing', 'Internship', 'Apprenticeship', 'Volunteer', 'Other'];
  }
  getJobFunctions() {
    return ['Administrative and Clerical', 'Human Resources', 'Management', 'Sales and Business Development', 'Accounting and Finance', 'Legal', 'Operations & Project Management', 'Retail', 'Technology and Digital Media', 'IT & ITES', 'Customer Service', 'Supply Chain and Logistics', 'Marketing', 'Production and Manufacturing', 'Science and Research', 'Training and Education', 'Other'].sort();
  }
  getContactUsRoles() {
    let skipCollegeAdmin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    const roles = ['College Student', 'College Administrator / Placement Officer', 'Employer/ Recruiting Officer', 'Other'];
    if (!skipCollegeAdmin) {
      return roles;
    }
    return roles.filter(e => e !== 'College Administrator / Placement Officer');
  }
  getFullMonths() {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  }
  getAppointmentReason() {
    return ['Job Readiness Assessment', 'Full-Time Job Search', 'Internship Search', 'Resume & Cover Letter Development', 'LinkedIn Profile Development', 'Career Exploration', 'Interview Preparation', 'Job Offer Evaluation', 'Salary Negotiation', 'Advanced Studies', 'Other'];
  }
  getJobSearchTypes() {
    return ['Seeking Employment', 'Unavailable for Employment', 'Planning to Continue Education', 'Secured Employment', 'Other'];
  }
  getJobSearchEmplTypes() {
    return ['Private Sector', 'Public Sector', 'Non-Profit Organization', 'Other'];
  }
  getJobSource() {
    return ['On-campus Recruiting', 'Networking', 'External Job Board', 'Recruiting Agency', 'Other'];
  }
  getResumeSkillCompetencies() {
    return ['Fundamental Awareness (Basic Knowledge)', 'Novice (Limited Experience)', 'Intermediate (Practical Application)', 'Advanced (Applied Theory)', 'Expert (Recognized Authority)'];
  }
  getRecommendationLevels() {
    return [['4', 'I very strongly recommend this candidate'], ['3', 'I strongly recommend this candidate'], ['2', 'I moderately recommend this candidate'], ['1', 'I do not recommend this candidate'], ['0', 'I do not know enough about this candidate']];
  }
  getGenderList() {
    return ['Male', 'Female', 'Other'];
  }
  getLastLoginFilters() {
    return [['-1', 'No signin'], ['30', 'More than 30 days'], ['60', 'More than 60 days'], ['90', 'More than 90 days'], ['180', 'More than 180 days'], ['365', 'More than 1 year']];
  }
  getJobReadinessScores() {
    return [['1', 'Needs Improvement'], ['2', 'Below Expectations'], ['3', 'Meets Expectations'], ['4', 'Exceeds Expectations'], ['5', 'Exceptional']];
  }
  getNationalities() {
    return ['Indian', 'Other'];
  }
  getReligions() {
    return ['Hindu', 'Muslim', 'Christian', 'Sikh', 'Buddhist', 'Jain', 'Other'];
  }
  getCasteCategories() {
    return ['OC', 'BC', 'OBC', 'MBC', 'SBC', 'SC', 'ST', 'OTHER'];
  }
  getEventTargetAudience() {
    return ['Students', 'Faculty', 'Staff', 'Alumni', 'Employers', 'General Public'];
  }
  getEventTypes() {
    return ['General Announcement', 'Lecture', 'Career Event', 'Conference', 'Community Service', 'Performance', 'Training', 'Sports', 'Other'];
  }
  getCollegeTermOptions() {
    return ['1 Term', '2 Terms', '3 Terms', '4 Terms', '5 Terms', '6 Terms', '7 Terms', '8 Terms', '9 Terms', '10 Terms'];
  }
  formatToDbJson(str) {
    if (str) {
      return ent.encode(str);
    }
    return str;
  }
  formatFromDbJson(str) {
    if (str) {
      return ent.decode(str);
    }
    return str;
  }
  getUserName(firstName, lastName) {
    let middleName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    let name = '';
    if (firstName) {
      name += firstName.trim();
    }
    if (middleName) {
      name += ' ' + middleName.trim();
    }
    if (lastName) {
      name += ' ' + lastName.trim();
    }
    return name;
  }
  getClaimCollegeStatus() {
    return [['N', 'Not Processed'], ['E', 'Contact Info Entered'], ['T', 'Introduction Email Sent'], ['V', 'Verification Email Sent'], ['I', 'Not Interested'], ['F', 'College Closed'], ['U', 'No Contact Found'], ['C', 'Customer']];
  }
  getCountryDetails() {
    return [['IN', 'India']];
  }
  getCountryFromCode(countryCode) {
    let countryName = '';
    if (countryCode) {
      const countryDetails = this.getCountryDetails();
      for (const x of countryDetails) {
        if (x[0] === countryCode) {
          countryName = x[1];
          break;
        }
      }
    }
    return countryName;
  }
  getApplUserStatus(opts) {
    /**
    * users table (all):
    * A - Active
    * I - Inactive [not applicable for students]
    * student_details table (students):
    * A - Active
    * student_college_details table (students):
    * A - Active (without alumni)
    * I - Inactive
    * L - Only Alumni
    * W - Wait (college admin approval process)
    * R - Rejected (by college admin)
    * N - Not Allowed (account closed by college admin)
    * S - Scrub data (account self-closed by user)
    */
    const active = opts['A'] || false;
    const inactive = opts['I'] || false;
    const alumni = opts['L'] || false;
    const wait = opts['W'] || false;
    const rejected = opts['R'] || false;
    const notAllowed = opts['N'] || false;
    const scrubData = opts['S'] || false;
    let userStatus = [];
    if (active) {
      userStatus = userStatus.concat(['A']);
    }
    if (alumni) {
      userStatus = userStatus.concat(['L']);
    }
    if (wait) {
      userStatus = userStatus.concat(['W']);
    }
    if (rejected) {
      userStatus = userStatus.concat(['R']);
    }
    if (notAllowed) {
      userStatus = userStatus.concat(['N']);
    }
    if (scrubData) {
      userStatus = userStatus.concat(['S']);
    }
    if (inactive) {
      userStatus = userStatus.concat(['I']);
    }
    if (userStatus.length === 0) {
      userStatus = userStatus.concat(['I']); // default 
    }
    const finalStatus = [];
    for (const x of userStatus) {
      if (finalStatus.indexOf(x) === -1) {
        finalStatus.push(x);
      }
    }
    return finalStatus;
  }
  getCTCBands() {
    return [['100000', 'Rs.1 - 1.5 Lakh per Year'], ['150000', 'Rs.1.5 - 2 Lakh per Year'], ['200000', 'Rs.2 - 2.5 Lakh per Year'], ['250000', 'Rs.2.5 - 3 Lakh per Year'], ['300000', 'Rs.3 - 4 Lakh per Year'], ['400000', 'Rs.4 - 5 Lakh per Year'], ['500000', 'Rs.5 - 6 Lakh per Year'], ['600000', 'Rs.6 - 7 Lakh per Year'], ['700000', 'Rs.7 - 8 Lakh per Year'], ['800000', 'Rs.8 - 9 Lakh per Year'], ['900000', 'Rs.9 - 10 Lakh per Year'], ['1000000', 'Above Rs.10 Lakh per Year']];
  }
  matchCTCBand(band) {
    if (band) {
      const ctcBands = this.getCTCBands();
      for (const x of ctcBands) {
        if (x[0] === band) {
          return x[1];
        }
      }
    }
    return '';
  }
  getCTCFrequencies() {
    return [['1', 'Per Year'], ['12', 'Per Month'], ['52', 'Per Week'], ['2080', 'Per Hour']];
  }
  matchCTCFrequency(frequency) {
    if (frequency) {
      const ctcFrequencies = this.getCTCFrequencies();
      for (const x of ctcFrequencies) {
        if (x[0] === frequency) {
          return x[1];
        }
      }
    }
    return '';
  }
  convertToInteger(str) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (str) {
      const removeCommas = opts.removeCommas || false;
      let newStr = str;
      if (removeCommas) {
        newStr = str.replace(/,/g, '');
      }
      const errorDetails = validator_1.Validator.validateInteger(newStr, {}, 'int_value', 'invalid integer value');
      if (errorDetails === undefined) {
        const tmpNo = Number.parseInt(newStr, 10);
        if (!Number.isNaN(tmpNo)) {
          return [true, tmpNo];
        }
      }
    }
    return [false, Number.MIN_SAFE_INTEGER];
  }
  // https://jalote.wordpress.com/2019/07/18/a-3-tier-higher-education-system-for-india/
  getCollegeTiers() {
    const obj = ['Unspecified', 'Tier 1', 'Tier 2', 'Tier 3'];
    return obj;
  }
  getNlpScoreRange() {
    return [['3', 'High Match', [51, 100]], ['2', 'Match', [11, 50]], ['1', 'Low Match', [0, 10]]];
  }
}
const GeneralInstance = new General();
exports.General = GeneralInstance;