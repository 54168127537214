import * as React from 'react';
import { Spinner as SpinnerLib } from 'react-activity';
import 'react-activity/dist/library.css';

import { General } from './../parentClasses/general';

class Spinner extends General {
  props: {
    display: boolean;
    buttonClass?: string;
    iconType?: string;
  };

  static defaultProps = {
    buttonClass: 'btn-primary',
    iconType: 'submit-indicator'
  };

  constructor(props: React.Props<any>) {
    super(props);
  }

  render() {
    if (this.props.display) {
      if (this.props.iconType === 'component-mount') {
        return (
          <SpinnerLib size={60} color="rgba(42, 191, 70, 1)" />
        );
      }
      return (
        <button className={'mr-1 btn ' + this.props.buttonClass} disabled={true}>
          <i className="fa fa-refresh fa-spin fa-2x fa-fw"/>
        </button>
      );
    }
    return null;
  }
}

export default Spinner;
