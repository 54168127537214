import * as React from 'react';

import { General } from './../parentClasses/general';

class PageLoading extends General {
  props: {
    spinner: JSX.Element;
  };

  constructor(props: React.Props<any>) {
    super(props);
  }

  render() {
    return (
      <div id="pageLoader" className="pageLoadingContainer">
        <div id="pageLoaderSpinner" className="pageLoading">
          {this.props.spinner}
        </div>
      </div>
    );
  }
}

export default PageLoading;
