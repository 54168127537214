"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class StudentResumeBuilder extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    /* #####overriden method###### */
    this.setValidationState = mobx_1.action(function (newState, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      _this.state.validation[identifier] = newState;
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.state.validation[identifier] = [];
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.modifyFormState = mobx_1.action(function (name, value, index, identifier) {
      let subIdentifier = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER) {
        switch (name) {
          case 'slug':
            _this.state.form[identifier].slug = value;
            break;
          case 'video_url':
            _this.state.form[identifier].profile.video_url = value;
            break;
          case 'summary':
            _this.state.form[identifier].profile.summary = value;
            break;
          case 'extracurricular_activities':
            _this.state.form[identifier].profile.extracurricular_activities = value;
            break;
          case 'achievements_and_awards':
            _this.state.form[identifier].profile.achievements_and_awards = value;
            break;
          case 'personal_first_name':
            _this.state.form[identifier].profile.personal.first_name = value;
            break;
          case 'personal_middle_name':
            _this.state.form[identifier].profile.personal.middle_name = value;
            break;
          case 'personal_last_name':
            _this.state.form[identifier].profile.personal.last_name = value;
            break;
          case 'personal_email':
            _this.state.form[identifier].profile.personal.email = value;
            break;
          case 'personal_mobile':
            _this.state.form[identifier].profile.personal.mobile = value;
            break;
          case 'personal_skype_id':
            _this.state.form[identifier].profile.personal.skype_id = value;
            break;
          case 'personal_photo_url':
            _this.state.form[identifier].profile.personal.photo_url = value;
            break;
          case 'personal_gender':
            _this.state.form[identifier].profile.personal.gender = value;
            break;
          case 'personal_linkedin_url':
            _this.state.form[identifier].profile.personal.linkedin_url = value;
            break;
          case 'other_date_of_birth':
            _this.state.form[identifier].profile.other.date_of_birth = value;
            break;
          case 'other_age':
            _this.state.form[identifier].profile.other.age = value;
            break;
          case 'other_address_line_1':
            _this.state.form[identifier].profile.other.address_line_1 = value;
            break;
          case 'other_address_line_2':
            _this.state.form[identifier].profile.other.address_line_2 = value;
            break;
          case 'other_city':
            _this.state.form[identifier].profile.other.city = value;
            break;
          case 'other_state':
            _this.state.form[identifier].profile.other.state = value;
            break;
          case 'other_zipcode':
            _this.state.form[identifier].profile.other.zipcode = value;
            break;
          case 'other_po_box_no':
            _this.state.form[identifier].profile.other.po_box_no = value;
            break;
          case 'other_country':
            _this.state.form[identifier].profile.other.country = value;
            break;
          case 'other_nationality':
            _this.state.form[identifier].profile.other.nationality = value;
            break;
          case 'other_religion':
            _this.state.form[identifier].profile.other.religion = value;
            break;
          case 'other_caste_category':
            _this.state.form[identifier].profile.other.caste_category = value;
            break;
          case 'other_father_name':
            _this.state.form[identifier].profile.other.father_name = value;
            break;
          case 'other_father_email':
            _this.state.form[identifier].profile.other.father_email = value;
            break;
          case 'other_father_mobile':
            _this.state.form[identifier].profile.other.father_mobile = value;
            break;
          case 'other_father_occupation':
            _this.state.form[identifier].profile.other.father_occupation = value;
            break;
          case 'other_mother_name':
            _this.state.form[identifier].profile.other.mother_name = value;
            break;
          case 'other_mother_email':
            _this.state.form[identifier].profile.other.mother_email = value;
            break;
          case 'other_mother_mobile':
            _this.state.form[identifier].profile.other.mother_mobile = value;
            break;
          case 'other_mother_occupation':
            _this.state.form[identifier].profile.other.mother_occupation = value;
            break;
          case 'other_pan_no':
            _this.state.form[identifier].profile.other.pan_no = value;
            break;
          case 'other_aadhaar_no':
            _this.state.form[identifier].profile.other.aadhaar_no = value;
            break;
          case 'other_passport_no':
            _this.state.form[identifier].profile.other.passport_no = value;
            break;
          case 'other_residence_phone':
            _this.state.form[identifier].profile.other.residence_phone = value;
            break;
          case "education_college_id_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].college_id = value;
            }
            break;
          case "education_institution_name_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].institution_name = value;
            }
            break;
          case "education_degree_program_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].degree_program = value;
            }
            break;
          case "education_degree_specialization_1_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].degree_specialization_1 = value;
            }
            break;
          case "education_degree_specialization_2_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].degree_specialization_2 = value;
            }
            break;
          case "education_degree_specialization_3_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].degree_specialization_3 = value;
            }
            break;
          case "education_admission_year_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].admission_year = value;
            }
            break;
          case "education_graduation_year_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].graduation_year = value;
            }
            break;
          case "education_cumulative_gpa_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].cumulative_gpa = value;
            }
            break;
          case "education_cumulative_percentage_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].cumulative_percentage = value;
            }
            break;
          case "education_cumulative_marks_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].cumulative_marks = value;
            }
            break;
          case "education_enrollment_no_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education[index].enrollment_no = value;
            }
            break;
          case "entrance_exams_exam_identifier_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.entrance_exams.length) {
              _this.state.form[identifier].profile.entrance_exams[index].exam_identifier = value;
            }
            break;
          case "entrance_exams_maximum_marks_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.entrance_exams.length) {
              _this.state.form[identifier].profile.entrance_exams[index].maximum_marks = value;
            }
            break;
          case "entrance_exams_marks_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.entrance_exams.length) {
              _this.state.form[identifier].profile.entrance_exams[index].marks = value;
            }
            break;
          case "entrance_exams_rank_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.entrance_exams.length) {
              _this.state.form[identifier].profile.entrance_exams[index].rank = value;
            }
            break;
          case "experience_title_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience[index].title = value;
            }
            break;
          case "experience_company_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience[index].company = value;
            }
            break;
          case "experience_location_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience[index].location = value;
            }
            break;
          case "experience_from_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience[index].from = value;
            }
            break;
          case "experience_to_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience[index].to = value;
            }
            break;
          case "experience_description_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience[index].description = value;
            }
            break;
          case "experience_additional_info_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience[index].additional_info = value;
            }
            break;
          case "recommendations_name_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.recommendations.length) {
              _this.state.form[identifier].profile.recommendations[index].name = value;
            }
            break;
          case "recommendations_email_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.recommendations.length) {
              _this.state.form[identifier].profile.recommendations[index].email = value;
            }
            break;
          case "recommendations_phone_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.recommendations.length) {
              _this.state.form[identifier].profile.recommendations[index].phone = value;
            }
            break;
          case "recommendations_role_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.recommendations.length) {
              _this.state.form[identifier].profile.recommendations[index].role = value;
            }
            break;
          case "recommendations_recommendation_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.recommendations.length) {
              _this.state.form[identifier].profile.recommendations[index].recommendation = value;
            }
            break;
          case "recommendations_url_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.recommendations.length) {
              _this.state.form[identifier].profile.recommendations[index].url = value;
            }
            break;
          case "skills_title_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.skills.length) {
              _this.state.form[identifier].profile.skills[index].title = value;
            }
            break;
          case "skills_description_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.skills.length) {
              _this.state.form[identifier].profile.skills[index].description = value;
            }
            break;
          case "skills_competency_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.skills.length) {
              _this.state.form[identifier].profile.skills[index].competency = value;
            }
            break;
          case "interests_title_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.interests.length) {
              _this.state.form[identifier].profile.interests[index].title = value;
            }
            break;
          case "interests_description_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.interests.length) {
              _this.state.form[identifier].profile.interests[index].description = value;
            }
            break;
          case "portfolio_title_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.portfolio.length) {
              _this.state.form[identifier].profile.portfolio[index].title = value;
            }
            break;
          case "portfolio_description_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.portfolio.length) {
              _this.state.form[identifier].profile.portfolio[index].description = value;
            }
            break;
          case "portfolio_url_".concat(index):
            if (index >= 0 && index < _this.state.form[identifier].profile.portfolio.length) {
              _this.state.form[identifier].profile.portfolio[index].url = value;
            }
            break;
        }
      }
    });
    this.addFormRow = mobx_1.action(function (name, identifier) {
      let subIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER) {
        const schema = risesharp_common_1.Schema.getResumeDetails();
        switch (name) {
          case 'education':
            _this.state.form[identifier].profile.education.push(schema.profile.education[0]);
            break;
          case 'entrance_exams':
            _this.state.form[identifier].profile.entrance_exams.push(schema.profile.entrance_exams[0]);
            break;
          case 'experience':
            _this.state.form[identifier].profile.experience.push(schema.profile.experience[0]);
            break;
          case 'recommendations':
            _this.state.form[identifier].profile.recommendations.push(schema.profile.recommendations[0]);
            _this.updateS3ProgressMapping('recommendations_' + (_this.state.form[identifier].profile.recommendations.length - 1));
            break;
          case 'skills':
            _this.state.form[identifier].profile.skills.push(schema.profile.skills[0]);
            break;
          case 'interests':
            _this.state.form[identifier].profile.interests.push(schema.profile.interests[0]);
            break;
          case 'portfolio':
            _this.state.form[identifier].profile.portfolio.push(schema.profile.portfolio[0]);
            _this.updateS3ProgressMapping('portfolio_' + (_this.state.form[identifier].profile.portfolio.length - 1));
            break;
          default:
            return;
        }
      }
    });
    this.removeFormRow = mobx_1.action(function (name, index, identifier) {
      let subIdentifier = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
      if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER) {
        switch (name) {
          case 'education':
            if (index >= 0 && index < _this.state.form[identifier].profile.education.length) {
              _this.state.form[identifier].profile.education.splice(index, 1);
            }
            break;
          case 'entrance_exams':
            if (index >= 0 && index < _this.state.form[identifier].profile.entrance_exams.length) {
              _this.state.form[identifier].profile.entrance_exams.splice(index, 1);
            }
            break;
          case 'experience':
            if (index >= 0 && index < _this.state.form[identifier].profile.experience.length) {
              _this.state.form[identifier].profile.experience.splice(index, 1);
            }
            break;
          case 'recommendations':
            if (index >= 0 && index < _this.state.form[identifier].profile.recommendations.length) {
              _this.state.form[identifier].profile.recommendations.splice(index, 1);
              _this.updateS3ProgressMapping('recommendations_' + index);
            }
            break;
          case 'skills':
            if (index >= 0 && index < _this.state.form[identifier].profile.skills.length) {
              _this.state.form[identifier].profile.skills.splice(index, 1);
            }
            break;
          case 'interests':
            if (index >= 0 && index < _this.state.form[identifier].profile.interests.length) {
              _this.state.form[identifier].profile.interests.splice(index, 1);
            }
            break;
          case 'portfolio':
            if (index >= 0 && index < _this.state.form[identifier].profile.portfolio.length) {
              _this.state.form[identifier].profile.portfolio.splice(index, 1);
              _this.updateS3ProgressMapping('portfolio_' + index);
            }
            break;
          default:
            return;
        }
      }
    });
    this.resetState();
  }
  setSaveSectionState(key, subKey, value, identifier) {
    const saveSections = this.state.saveSections[identifier] || {};
    if (saveSections[key] && saveSections[key][subKey]) {
      saveSections[key][subKey] = value;
    }
  }
  getSaveSectionState(key, subKey, value, identifier) {
    let result = null;
    const saveSections = this.state.saveSections[identifier] || {};
    if (saveSections[key] && saveSections[key][subKey]) {
      result = saveSections[key][subKey];
    }
    return result;
  }
  updateRequestRecommendationState(newState, identifier) {
    if (newState) {
      this.state.displaySections[identifier].recommendation = false;
    } else {
      this.state.displaySections[identifier].recommendation = true;
    }
  }
  updateS3ProgressMapping(progressType) {
    const s3Progress = this.state.s3Progress;
    s3Progress[risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER][progressType] = '';
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        s3Progress
      })
    });
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER)
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: []
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: this.getSpinnerDefault()
        },
        displaySections: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: this.getDisplaySectionsDefault()
        },
        saveSections: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: this.getSaveSectionsDefault()
        },
        displayForm: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: true
        },
        s3Progress: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: {
            video: '',
            recommendations_0: '',
            portfolio_0: ''
          }
        },
        coursesList: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: []
        },
        collegesList: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: new mobx_1.ObservableMap()
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER) {
      return risesharp_common_1.Schema.getResumeDetails();
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTRESUMEBUILDER) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        studentResumeBuilder: risesharp_common_1.Schema.getResumeDetails()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
  getDisplaySectionsDefault() {
    return {
      slug: false,
      video: false,
      summary: false,
      personal: true,
      other: false,
      education: false,
      entrance_exams: false,
      experience: false,
      recommendations: false,
      skills: false,
      interests: false,
      portfolio: false,
      info: false
    };
  }
  getSaveSectionsDefault() {
    return {
      slug: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      video: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      summary: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      personal: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      other: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      education: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      entrance_exams: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      experience: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      recommendations: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      skills: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      interests: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      portfolio: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      },
      info: {
        submit: false,
        errorMessage: '',
        successMessage: ''
      }
    };
  }
}
exports.default = StudentResumeBuilder;