"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollegeDegrees = void 0;
/*
* Nov 26, 2018
* Intially had a static program and spl.list - getDegreePrograms, getDegreeProgramSpecializations
* Now using db driven data - getDegreeProgramsList, getDegreeSpecializationsList
*/
class CollegeDegrees {
  getCollegeTypes() {
    const obj = ['Arts & Sciences', 'Business School', 'Engineering', 'Junior College', 'High School', 'Training Institute', 'Dental', 'Law', 'Medical', 'Nursing', 'Pharmacy', 'Polytechnic', 'Industrial Training Institute (ITI)', 'Other'];
    return obj.sort();
  }
  getCollegeAdmissionYears() {
    const years = [];
    const startYear = 1980;
    const endYear = new Date().getFullYear();
    for (let x = startYear; x <= endYear; x = x + 1) {
      years.push('' + x);
    }
    return years;
  }
  getCollegeGraduationYears() {
    const years = [];
    const startYear = 1980;
    const endYear = 2030;
    for (let x = startYear; x <= endYear; x = x + 1) {
      years.push('' + x);
    }
    return years;
  }
  getDegreeProgramsList(allDegrees) {
    let customRules = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (allDegrees.length > 0) {
      const programs = [];
      let chkDegreeProgram = '';
      if (Object.keys(customRules).length > 0 && customRules.hasOwnProperty('degree_program')) {
        const tmpStr = customRules.degree_program || '';
        if (tmpStr !== 'ALL') {
          chkDegreeProgram = tmpStr;
        }
      }
      const tmpPrograms = [];
      for (const x of allDegrees) {
        let skipProgram = false;
        if (chkDegreeProgram && chkDegreeProgram !== x.program) {
          skipProgram = true;
        }
        if (!skipProgram) {
          if (tmpPrograms.indexOf(x.program) === -1) {
            tmpPrograms.push(x.program);
            programs.push({
              [x.program]: {
                duration: x.duration,
                code: x.code
              }
            });
          }
        }
      }
      return programs;
    }
    return [{
      Other: {
        duration: 0,
        code: 'OTHER'
      }
    }];
  }
  getDegreeSpecializationsList(allDegrees, program) {
    let results = [];
    if (allDegrees.length > 0) {
      for (const x of allDegrees) {
        if (typeof program === 'string') {
          if (x.program === program) {
            results.push(x.specialization);
          }
        } else {
          for (const y of program) {
            if (x.program === y) {
              results.push(x.specialization);
            }
          }
        }
      }
    } else {
      results = ['Other'];
    }
    return results.sort();
  }
  getDegreeSplWithCode(allDegrees, program) {
    let results = [];
    if (allDegrees.length > 0) {
      for (const x of allDegrees) {
        if (typeof program === 'string') {
          if (x.program === program) {
            const str = "".concat(x.specialization, " (").concat(x.code, ")");
            results.push(str);
          }
        } else {
          for (const y of program) {
            if (x.program === y) {
              const str = "".concat(x.specialization, " (").concat(x.code, ")");
              results.push(str);
            }
          }
        }
      }
    } else {
      results = ['Other (OTHER)'];
    }
    return results.sort();
  }
  getDgrSplWithCodeSuffix(allDegrees, program) {
    let results = [];
    if (allDegrees.length > 0) {
      for (const x of allDegrees) {
        if (typeof program === 'string') {
          if (x.program === program) {
            const str1 = "".concat(x.specialization, " (").concat(x.code, ")");
            const str2 = x.specialization;
            results.push([str1, str2]);
          }
        } else {
          for (const y of program) {
            if (x.program === y) {
              const str1 = "".concat(x.specialization, " (").concat(x.code, ")");
              const str2 = x.specialization;
              results.push([str1, str2]);
            }
          }
        }
      }
    } else {
      results = [['Other (OTHER)', 'Other']];
    }
    return results.sort();
  }
  getResumeDegreeOptions(allDegrees) {
    let matchDegrees = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const sortFn = (left, right) => {
      const leftProgram = Object.keys(left)[0].toUpperCase();
      const rightProgram = Object.keys(right)[0].toUpperCase();
      if (leftProgram < rightProgram) {
        return -1;
      }
      if (leftProgram > rightProgram) {
        return 1;
      }
      return 0;
    };
    const degrees = this.getDegreeProgramsList(allDegrees);
    let finalDegrees = [];
    if (matchDegrees.length > 0) {
      const tmpArr = [];
      for (const x of matchDegrees) {
        if (x !== null) {
          const key = x.program;
          const val = {
            duration: x.duration,
            code: x.code
          };
          for (const y of degrees) {
            const chkKey = Object.keys(y)[0];
            const chkVal = y[chkKey];
            if (key === chkKey && JSON.stringify(val) === JSON.stringify(chkVal)) {
              if (tmpArr.indexOf(chkKey) === -1) {
                tmpArr.push(chkKey);
                finalDegrees.push({
                  [chkKey]: chkVal
                });
              }
            }
          }
        }
      }
    } else {
      finalDegrees = degrees;
    }
    return finalDegrees.sort(sortFn);
  }
  getResumeDegreeSpecializationsList(allDegrees, program) {
    if (program === 'SSC (Secondary School)' || program === 'Intermediate (Higher Secondary)') {
      return ['General'];
    }
    const degreeSpl = this.getDegreeSpecializationsList(allDegrees, program);
    return degreeSpl.sort();
  }
  getResumeStudyField(allDegrees, program) {
    if (program === 'SSC (Secondary School)') {
      return ['Maths, Science, English (Medium)', 'Maths, Science, Other (Medium)'];
    }
    if (program === 'Intermediate (Higher Secondary)') {
      return ['MPC', 'BPC', 'MBPC', 'CEC', 'HEC'];
    }
    return this.getDegreeSpecializationsList(allDegrees, program);
  }
  isProfileUpdateNeeded(profile) {
    let skipRollNo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    const degreeProgram = profile.degree_program || '';
    const degreeCode = profile.degree_code || '';
    const degreeDuration = Number.parseInt(profile.degree_duration || 0, 10);
    const degreeSpecialization = profile.degree_specialization || '';
    const admissionYear = profile.college_admission_year || '';
    const graduationYear = profile.college_graduation_year || '';
    const enrollmentNo = profile.college_enrollment_no || '';
    if (skipRollNo) {
      if (degreeProgram && degreeCode && degreeDuration >= 0 && degreeSpecialization && admissionYear && graduationYear) {
        return false;
      }
    } else {
      if (degreeProgram && degreeCode && degreeDuration >= 0 && degreeSpecialization && admissionYear && graduationYear && enrollmentNo) {
        return false;
      }
    }
    return true;
  }
  getEntranceExamsList() {
    return ['ACT', 'AFCAT', 'AIPGMEE', 'APIED', 'BCECE', 'BITSAT', 'CAT', 'CEE', 'CET', 'CG-PET', 'CG-PMT', 'CLAT', 'Comed-K', 'CTET', 'EAMCET', 'FPMT', 'GATE', 'GCET', 'GUJCET', 'IBSAT', 'IIT CEED', 'INAT', 'JEE (Advanced)', 'JEE (Main)', 'JEST', 'KCET', 'KEAM', 'MAT', 'MHT-CET', 'MOGA (PB)', 'MP-PET', 'MPSC', 'NAT', 'NCHMCT', 'NEET-UG', 'NEST', 'NIMCET', 'NMAT', 'Orissa JEE', 'PAT', 'RPET', 'RPMT', 'SAT', 'SEE-UPTU', 'SNAP', 'TNPCEE', 'UPSC', 'VITEEE', 'WBJEE', 'XAT', 'GRE', 'GMAT', 'TOEFL', 'IELTS', 'LSAT', 'MCAT'].sort();
  }
  getTermMonths() {
    return [['1', 'January'], ['2', 'February'], ['3', 'March'], ['4', 'April'], ['5', 'May'], ['6', 'June'], ['7', 'July'], ['8', 'August'], ['9', 'September'], ['10', 'October'], ['11', 'November'], ['12', 'December']];
  }
  getDegreeCode(allDegrees, degreeProgram, degreeSpecialization) {
    let code = '';
    if (allDegrees.length > 0) {
      for (const x of allDegrees) {
        if (x.program === degreeProgram && x.specialization === degreeSpecialization) {
          code = x.code;
        }
      }
    }
    return code;
  }
  getSplAblDisabilities() {
    return ['Hearing Impairment', 'Orthopedic Impairment', 'Physical Impairment', 'Speech Impairment', 'Visual Impairment', 'Other'];
  }
}
const CollegeDegreesInstance = new CollegeDegrees();
exports.CollegeDegrees = CollegeDegreesInstance;