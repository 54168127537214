import * as React from 'react';
import { Helmet } from 'react-helmet';

import { General as GeneralLib } from 'risesharp-common';

import { Helper } from './../utils/helper';
import { HelmetData } from './../utils/helmetData';

// require only after import
// tslint:disable-next-line
const bsn = require('bootstrap.native/dist/bootstrap-native-v4');

export abstract class General extends React.Component<any, any> {
  constructor(props: React.Props<any>) {
    super(props);
  }
  
  /*  #####modal window##### */
  openModalWindow(elemId: string, disallowEscape: boolean = false) {
    const mainDisplay = document.getElementById('main-display');
    if (mainDisplay) {
      if (mainDisplay.classList.contains('fadeInDown')) {
        mainDisplay.classList.remove('animated', 'fadeInDown');
      }
    }
    const modalElement = document.getElementById(elemId);
    if (modalElement) {
      let modalInstance;
      if (!disallowEscape) {
        modalInstance = new bsn.Modal(modalElement);
      } else {
        modalInstance = new bsn.Modal(modalElement, { backdrop: 'static', keyboard: false });
      }
      modalInstance.show();

      // IMPORTANT - sometimes backdrop does not disappear
      // tslint:disable:align
      modalElement.addEventListener('hide.bs.modal', ((event: any) => {
        this.handleHideModalEvent(event);
      }), { once: true });
    }
  }

  closeModalWindow(elemId: string, is401Error: boolean = false) {
    const modalElement = document.getElementById(elemId);
    if (modalElement) {
      const modalInstance = new bsn.Modal(modalElement);
      Helper.customHideModal(modalInstance, document);
      if (is401Error) {
        Helper.clearAllAndSignout(this.props, true);
      }
    }
  }

  handleHideModalEvent(event: any) {
    Helper.customHandleModalHideEvent(event, document);
  }
  /*  #####modal window##### */

  /*  #####date##### */
  displayDate(date: any, formatStr: string) {
    if (date) {
      return Helper.getMomentInstance(date).local().format(formatStr);
    }
    return '';
  }

  displayDateIST(date: any, formatStr: string) {
    if (date) {
      return Helper.getMomentInstance(date).utcOffset('+05:30').format(formatStr);
    }
    return '';
  }
  /*  #####date##### */

  /*  #####element display##### */
  displayTopLevelMessages(delayMode: boolean = false) {
    if (!delayMode) {
      Helper.scrollTop();
    } else {
      // tslint:disable:align
      window.setTimeout(() => {
        Helper.scrollTop();
      }, 100);
    }
  }

  focusElement(elementId: string) {
    Helper.scrollToElement(elementId, true);
  }

  trackContentHeight(initialLoad: boolean) {
    const footer: HTMLElement = document.getElementById('app-footer')!;
    if (footer) {
      if (initialLoad) {
        this.moveStickyFooterAdjuster(false);
      } else {
        this.moveStickyFooterAdjuster(true);
      }
    }
  }

  moveStickyFooterAdjuster(zeroHeight: boolean) {
    const stickyFooter: HTMLElement = document.getElementById('page-loader-stk-ftr')!;
    if (stickyFooter) {
      if (!zeroHeight) {
        let minHeight = '500px';
        const content: HTMLElement = document.getElementById('app-content')!;
        if (content) {
          const tmpHeight = content.clientHeight;
          if (tmpHeight > 500) {
            minHeight = tmpHeight + 'px';
          }
        }
        if (stickyFooter.style.minHeight !== minHeight) {
          stickyFooter.style.minHeight = minHeight;
        }
      } else {
        if (stickyFooter.style.minHeight !== '0px') {
          stickyFooter.style.minHeight = '0px';
        }
      }
    }
  }
  /*  #####element display##### */

  /*  #####privileges##### */
  showCollegeMenuItem(menuType: string) {
    let accessOK = false;

    const userPrivileges = Helper.getAuthorizedUserPrivileges(this.props);
    if (userPrivileges.length > 0) {
      const allowedPrivileges = [
        'PRV_COLL_ALL_PRIVILEGES',
        'PRV_COLL_ADMIN_PRIVILEGES',
        'PRV_COLL_PRINCIPAL'
      ] as any;
      if (menuType === 'students') {
        allowedPrivileges.push('PRV_COLL_STUDENT_MENTOR');
      }

      if (allowedPrivileges.length > 0) {
        for (const x of userPrivileges) {
          if (allowedPrivileges.indexOf(x) !== -1) {
            accessOK = true;
            break;
          }
        }
      }
    }

    return accessOK;
  }

  showEmployerMenuItem(menuType: string) {
    let accessOK = false;

    const userPrivileges = Helper.getAuthorizedUserPrivileges(this.props);
    if (userPrivileges.length > 0) {
      const allowedPrivileges = [
        'PRV_EMPL_ALL_PRIVILEGES',
        'PRV_EMPL_ADMIN_PRIVILEGES'
      ] as any;
      if (menuType === 'jobs') {
        allowedPrivileges.push('PRV_EMPL_RECRUITER');
      }

      if (allowedPrivileges.length > 0) {
        for (const x of userPrivileges) {
          if (allowedPrivileges.indexOf(x) !== -1) {
            accessOK = true;
            break;
          }
        }
      }
    }

    return accessOK;
  }

  allowOpsAccess(allowedPrivileges: string[]) {
    let privilegeOK = true;

    if (allowedPrivileges.length > 0) {
      let privilegeMatched = false;

      const userPrivileges = Helper.getAuthorizedUserPrivileges(this.props);
      if (userPrivileges.length > 0) {
        for (const x of userPrivileges) {
          if (allowedPrivileges.indexOf(x) !== -1) {
            privilegeMatched = true;
            break;
          }
        }
      }
      
      privilegeOK = privilegeMatched;
    }

    return privilegeOK;
  }

  hasCollegeSuperAdminPrivileges() {
    const allowedPrivileges = [
      'PRV_COLL_ALL_PRIVILEGES',
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasCollegeAdminPrivileges() {
    const allowedPrivileges = [
      'PRV_COLL_ALL_PRIVILEGES',
      'PRV_COLL_ADMIN_PRIVILEGES',
      'PRV_COLL_PRINCIPAL'
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasStudentMentorPrivileges() {
    const allowedPrivileges = [
      'PRV_COLL_STUDENT_MENTOR'
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasAddlStudentMentorPrivileges(opts: any) {
    const isSuperAdmin = opts.sa || false;
    const isPlacementStaff = opts.ps || false;
    const isDeptCoordinator = opts.dc || false;
    const isPrincipal = opts.pp || false;
    const skipMentor = opts.skip || false;

    let flag = isSuperAdmin ? this.hasCollegeSuperAdminPrivileges() : false;
    if (!flag) {
      flag = isPlacementStaff ? (this.hasCollegeAdminPrivileges() && !this.hasPrincipalPrivileges()) : false;
      if (!flag) {
        flag = isPrincipal ? (this.hasCollegeAdminPrivileges() && this.hasPrincipalPrivileges()) : false;
      }
      if (!flag) {
        flag = isDeptCoordinator ? this.hasDeptCoordinatorPrivileges() : false;
      }
      if (!flag && !skipMentor) {
        flag = this.hasStudentMentorPrivileges();
      }
    }
    
    return flag;
  }

  hasDeptCoordinatorPrivileges() {
    const allowedPrivileges = [
      'PRV_COLL_DEPT_COORDINATOR'
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasPrincipalPrivileges() {
    const allowedPrivileges = [
      'PRV_COLL_PRINCIPAL'
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasEmployerSuperAdminPrivileges() {
    const allowedPrivileges = [
      'PRV_EMPL_ALL_PRIVILEGES',
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasEmployerAdminPrivileges() {
    const allowedPrivileges = [
      'PRV_EMPL_ALL_PRIVILEGES',
      'PRV_EMPL_ADMIN_PRIVILEGES'
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasRecruiterPrivileges() {
    const allowedPrivileges = [
      'PRV_EMPL_RECRUITER',
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasStaffSuperAdminPrivileges() {
    const allowedPrivileges = [
      'PRV_STAFF_ALL_PRIVILEGES'
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasStaffAdminPrivileges() {
    const allowedPrivileges = [
      'PRV_STAFF_ALL_PRIVILEGES',
      'PRV_STAFF_ADMIN_PRIVILEGES'
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasStaffBDMCollegePrivileges() {
    const allowedPrivileges = [
      'PRV_STAFF_BDMCOLLEGE_PRIVILEGES',
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }

  hasStaffBDMEmployerPrivileges() {
    const allowedPrivileges = [
      'PRV_STAFF_BDMEMPLOYER_PRIVILEGES',
    ];
    return this.allowOpsAccess(allowedPrivileges);
  }
  /*  #####privileges##### */

  /*  #####others##### */
  showPage(e: React.MouseEvent<any>, url: string) {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(url);
  }
  
  showPageWithState(e: React.MouseEvent<any> | null, url: string, state: {}) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.props.history.push({
      pathname: url,
      state
    });
  }

  getHomeUrl() {
    const userType = Helper.getAuthorizedUserType(this.props);
    return Helper.getAuthenticatedUserHomeUrl(userType);
  }

  checkCurrentRoute(url: string) {
    const currentRoute = this.props.location.pathname;
    if (currentRoute === url) {
      return true;
    }
    return false;
  }

  getUserNameFromProps() {
    const userDetails = Helper.getAuthorizedUserDetails(this.props) as any;
    return this.getUserName(
      (userDetails.first_name || ''),
      (userDetails.last_name || '')
    );
  }

  getContainerStoreFromProps() {
    if (this.props.store && this.props.store.Container) {
      return this.props.store.Container;
    }
    return undefined;
  }

  getUserName(firstName: string, lastName: string, middleName: string = '') {
    return GeneralLib.getUserName(firstName, lastName, middleName);
  }

  getResendCode(identifier: string) {
    return Helper.getStateFromHistory(this.props, 'resend_otp_token');
  }

  checkFileUploadSize(fileSize: number, maxSize: number) {
    if (fileSize > 0 && fileSize <= maxSize) {
      return true;
    }
    return false;
  }

  displayDefaultTitleAndMeta() {
    return this.getHelmetData({
      identifier: ''
    });
  }

  getHelmetData(opts: any) {
    const noIndex = opts.noIndex || false;
    const pageNotFound = opts.pageNotFound || false;
    return (
      <Helmet>
        <title>{HelmetData.getTitle(opts)}</title>
        <meta name="description" content={HelmetData.getDescription(opts)}/>
        {(
          noIndex &&
          <meta name="robots" content="noindex"></meta>
        )}
        {(
          pageNotFound &&
          <meta name="prerender-status-code" content="404"/>
        )}
      </Helmet>
    );
  }

  getExternalScript(url: string, content: any) {
    return (
      <React.Fragment>
        <Helmet>
          <script src={url}></script>
        </Helmet>
        {content}
      </React.Fragment>
    );
  }
  /*  #####others##### */
}
