import * as moment from 'moment';

import { Schema, Constants, General } from 'risesharp-common';

import { LocalStorage } from './localStorage';

// require only after import
const queryString = require('query-string'); // should be part of react-router

class Helper {
  // #####IMPORTANT#####
  /*
  * localStorage related
  * First 3 methods should only be called by layout header's initState
  * onLoadCheckToken and onLoadGetUserType should only be called by layout container's methods
  * watchLogoutEvent should only be called by header
  * LocalStorage access should only be undertaken using this class
  */
  getInitStateAuthorizedUserDetails() {
    let userDetails = LocalStorage.getUserDetails();
    if (Object.keys(userDetails).length === 0) {
      userDetails = Schema.getAuthenticatedUserDetails();
    }
    return userDetails;
  }

  getInitStateCollegeDetails() {
    let collegeDetails = LocalStorage.getCollegeDetails();
    if (Object.keys(collegeDetails).length === 0) {
      collegeDetails = Schema.getCollegeDetails();
    }
    return collegeDetails;
  }

  getInitStateEmployerDetails() {
    let employerDetails = LocalStorage.getEmployerDetails();
    if (Object.keys(employerDetails).length === 0) {
      employerDetails = Schema.getEmployerDetails();
    }
    return employerDetails;
  }

  onLoadCheckToken() {
    const userDetails = LocalStorage.getUserDetails() as any;
    if (userDetails && userDetails.token) {
      return true;
    }
    return false;
  }

  onLoadGetUserType() {
    const userDetails = LocalStorage.getUserDetails() as any;
    if (userDetails && userDetails.hasOwnProperty('type')) {
      return userDetails.type;
    }
    return '';
  }

  watchLogoutEvent() {
    return LocalStorage.watchLogoutEvent();
  }
  // #####IMPORTANT#####

  private setUserDetails(userDetails: {}) {
    LocalStorage.setUserDetails(userDetails);
  }

  private setCollegeDetails(collegeDetails: {}) {
    LocalStorage.setCollegeDetails(collegeDetails);
  }

  private setEmployerDetails(employerDetails: {}) {
    LocalStorage.setEmployerDetails(employerDetails);
  }

  private removeAll() {
    LocalStorage.removeAll();
  }

  /* authentication related */
  signinUser(props: any, userDetails: {}, redirectTo: string = '') {
    if (props.store && props.store.Container && props.history) {
      props.store.Container.setUserLoggedInState(true);
      props.store.Container.setUserDetailsState(userDetails);
      this.setUserDetails(userDetails);

      // check college and employer profile
      let collegeDetails = {} as any;
      let employerDetails = {} as any;
      let collegematched = false;
      let employerMatched = false;
      const chkUserTypesOne = [Constants.COLLEGES_USER_TYPE, Constants.STUDENTS_USER_TYPE];
      const chkUserTypesTwo = [Constants.EMPLOYERS_USER_TYPE];

      // college profile
      if (userDetails && chkUserTypesOne.indexOf((userDetails as any).type) !== -1) {
        collegeDetails = (userDetails as any).college;
      }
      if (Object.keys(collegeDetails).length > 0 && collegeDetails.hasOwnProperty('url_slug') && collegeDetails.url_slug !== '') {
        collegematched = true;
      }
      if (collegematched) {
        this.setCollegeProfile(props, collegeDetails, false);
      } else {
        this.setCollegeProfile(props, {}, false);
      }

      // employer profile
      if (userDetails && chkUserTypesTwo.indexOf((userDetails as any).type) !== -1) {
        employerDetails = (userDetails as any).employer;
      }
      if (Object.keys(employerDetails).length > 0 && employerDetails.hasOwnProperty('url_slug') && employerDetails.url_slug !== '') {
        employerMatched = true;
      }
      if (employerMatched) {
        this.setEmployerProfile(props, employerDetails, false);
      } else {
        this.setEmployerProfile(props, {}, false);
      }

      // redirect
      if (!redirectTo) {
        props.history.push(this.getAuthenticatedUserHomeUrl((userDetails as any).type));
      } else {
        props.history.push(redirectTo);
      }
    }
  }

  setUpdatedUserDetails(props: any, newUserDetails: {}) {
    if (props.store && props.store.Container) {
      const prevUserDetails = props.store.Container.state.userDetails;
      const finalUserDetails = Object.assign({}, prevUserDetails, newUserDetails);
      props.store.Container.setUserDetailsState(finalUserDetails);
      this.setUserDetails(finalUserDetails);
    }
  }

  setUpdatedCollegeDetails(props: any, newCollegeDetails: {}) {
    if (props.store && props.store.Container) {
      const prevCollegeDetails = props.store.Container.state.collegeDetails;
      const finalCollegeDetails = Object.assign({}, prevCollegeDetails, newCollegeDetails);
      props.store.Container.setCollegeDetailsState(finalCollegeDetails);
      this.setCollegeDetails(finalCollegeDetails);
    }
  }

  setUpdatedEmployerDetails(props: any, newEmployerDetails: {}) {
    if (props.store && props.store.Container) {
      const prevEmployerDetails = props.store.Container.state.employerDetails;
      const finalEmployerDetails = Object.assign({}, prevEmployerDetails, newEmployerDetails);
      props.store.Container.setEmployerDetailsState(finalEmployerDetails);
      this.setEmployerDetails(finalEmployerDetails);
    }
  }

  setCollegeProfile(props: any, collegeDetails: {}, resetEmployer: boolean = true) {
    if (props.store && props.store.Container) {
      // remove employer details if any
      if (resetEmployer) {
        props.store.Container.setEmployerDetailsState({});
        this.setEmployerDetails({});
      }
      // clear and set college details
      props.store.Container.setCollegeDetailsState({});
      this.setCollegeDetails({});
      props.store.Container.setCollegeDetailsState(collegeDetails);
      this.setCollegeDetails(collegeDetails);
    }
  }

  setEmployerProfile(props: any, employerDetails: {}, resetCollege: boolean = true) {
    if (props.store && props.store.Container) {
      // remove college details if any
      if (resetCollege) {
        props.store.Container.setCollegeDetailsState({});
        this.setCollegeDetails({});
      }
      // clear and set employer details
      props.store.Container.setEmployerDetailsState({});
      this.setEmployerDetails({});
      props.store.Container.setEmployerDetailsState(employerDetails);
      this.setEmployerDetails(employerDetails);
    }
  }

  setStudentTasksDetails(props: any, opts: any, isDelete: boolean = false) {
    if (props.store && props.store.Container) {
      const prevUserDetails = props.store.Container.state.userDetails;
      if (prevUserDetails.hasOwnProperty('studentTasks')) {
        const taskId = opts.taskId || '';
        const taskUrl = opts.taskUrl || '';
        const completionDate = opts.completionDate || '';

        const videos = opts.videos || undefined;
        const vvCompletion = opts.vvCompletion || undefined;
        const vvDone = opts.vvDone || undefined;

        const { studentTasks, ...rest } = prevUserDetails;
        if (isDelete) {
          if (studentTasks.hasOwnProperty(taskId)) {
            delete studentTasks[taskId];
          }
        } else {
          studentTasks[taskId] = {
            task_url: taskUrl,
            completion_date: completionDate,
          };
          if (videos) {
            studentTasks[taskId]['videos'] = videos;
          }
          if (vvCompletion) {
            studentTasks[taskId]['vvCompletion'] = vvCompletion;
          }
          if (vvDone) {
            studentTasks[taskId]['vvDone'] = vvDone;
          }
        }
        const finalUserDetails = {
          studentTasks,
          ...rest
        };
        props.store.Container.setUserDetailsState(finalUserDetails);
        this.setUserDetails(finalUserDetails);
      }
    }
  }

  setStudentCollegeDetails(props: any, newCollege: any) {
    if (props.store && props.store.Container) {
      const prevUserDetails = props.store.Container.state.userDetails;
      if (prevUserDetails.hasOwnProperty('college')) {
        const { college, ...rest } = prevUserDetails;
        const finalUserDetails = {
          college: newCollege,
          ...rest
        };
        this.setUpdatedUserDetails(props, finalUserDetails);
      }
    }
  }

  clearAllAndSignout(props: any, is401Error: boolean = true, opts: {} = {}) {
    if (props.store && props.store.Container && props.store.Container.state && props.history) {
      const redirectTo = (opts as any).redirectTo || '';
      const replaceLocation = (opts as any).replaceLocation || false;
      const updatedPassword = (opts as any).updatedPassword || false;
      const jobRedirect: string = (opts as any).jobRedirect || false;
      const custLoginRedirect = (opts as any).custLoginRedirect || '';
      
      props.store.Container.resetStateOnSignout();
      this.removeAll();

      const signinState = {} as any;
      if (is401Error) {
        signinState.is401Error = true;
      }
      if (redirectTo) {
        signinState.redirect_after_login = redirectTo;
      }
      if (updatedPassword) {
        signinState.updated_password = true;
      }
      if (jobRedirect) {
        signinState.redirect_for_jobdetails = true;
      }

      if (custLoginRedirect) {
        props.history.push(custLoginRedirect);
      } else {
        if (!replaceLocation) {
          props.history.push({
            pathname: '/signin',
            state: signinState
          });
        } else {
          props.history.replace({
            pathname: '/signin',
            state: signinState
          });
        }
      }

      // for redirecting other tabs
      LocalStorage.setLogoutEvent();
    }
  }

  setUpdateEmailTracker(timestamp: number) {
    LocalStorage.setUpdateEmailTracker(timestamp);
  }

  getUpdateEmailTracker() {
    return LocalStorage.getUpdateEmailTracker();
  }

  removeUpdateEmailTracker() {
    LocalStorage.removeUpdateEmailTracker();
  }

  /* form related */
  handleOutlineAndMessage(validationObj: {}, key: string, id: string, existingClassNames: string = '') {
    let classNames = existingClassNames;
    Object.keys(validationObj).forEach((x: string) => {
      if (x === key) {
        if (id === 'outline') {
          if (validationObj[x].isError) {
            classNames = existingClassNames ? 'is-invalid ' + existingClassNames : 'is-invalid';
          }
        } else if (id === 'message') {
          if (validationObj[x].isError) {
            classNames = existingClassNames ? 'invalid-feedback ' + existingClassNames : 'invalid-feedback';
          } else {
            classNames = existingClassNames ? 'd-none ' + existingClassNames : 'd-none';
          }
        }
      }
    });
    // console.log(key, id, classNames);
    return classNames;
  }

  handleValidationState(validationObj: {}, errorMessages: {}) {
    const modState = {} as any;
    Object.keys(validationObj).forEach((key: string) => {
      if (errorMessages.hasOwnProperty(key)) {
        modState[key] = { isError: true, errorMessage: errorMessages[key] };
      } else {
        modState[key] = { isError: false, errorMessage: '' };
      }
    });
    return modState;
  }

  scrollTop() {
    // see https://stackoverflow.com/a/14384091
    const top = window.pageYOffset || document.documentElement.scrollTop;
    if (top !== 0) {
      window.scrollTo(0, 0);
    }
  }

  scrollToElement(id: string, scrollTop: boolean = false) {
    const elem = document.getElementById(id);
    if (elem) {
      window.scrollTo(0, this.calcElementOffset(elem));
    } else {
      if (scrollTop) {
        this.scrollTop();
      }
    }
  }
  // see https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
  calcElementOffset(elem: any) {
    const rect = elem.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const finalValue = rect.top + scrollTop;
    return finalValue;
  }

  /* dates */
  getMomentInstance(date: string = '') {
    if (date) {
      return moment.utc(date);
    }
    return moment.utc();
  }

  getDbDateFormat(): string {
    return 'YYYY-MM-DD HH:mm:ss';
  }

  getTimestampSecs() {
    return this.getMomentInstance().unix();
  }

  getDateFromTimestamp(tsVal: number) {
    return moment.unix(tsVal).utc();
  }

  /* others */
  getAuthorizedToken(props: any) {
    let token = '';
    if (props.store && props.store.Container) {
      token = props.store.Container.state.userDetails.token;
    }
    return token;
  }

  getAuthorizedUserDetails(props: any) {
    let userDetails = {};

    if (props.store && props.store.Container) {
      userDetails = props.store.Container.state.userDetails;
    }

    return userDetails;
  }

  getAuthorizedUserId(props: any) {
    const userDetails = this.getAuthorizedUserDetails(props) as any;
    if (userDetails && userDetails.hasOwnProperty('id')) {
      return userDetails.id;
    }
    return '';
  }

  getAuthorizedUserType(props: any) {
    const userDetails = this.getAuthorizedUserDetails(props) as any;
    if (userDetails && userDetails.hasOwnProperty('type')) {
      return userDetails.type;
    }
    return '';
  }

  checkActiveUserType(props: any, type: string) {
    const userDetails = this.getAuthorizedUserDetails(props);
    if (userDetails && (userDetails as any).type === type) {
      return true;
    }
    return false;
  }

  getAuthorizedUserPrivileges(props: any) {
    const userDetails = this.getAuthorizedUserDetails(props) as any;
    if (userDetails && userDetails.hasOwnProperty('userPrivileges')) {
      return userDetails.userPrivileges;
    }
    return [];
  }

  getAuthorizedUserCustomRules(props: any) {
    const userDetails = this.getAuthorizedUserDetails(props) as any;
    if (userDetails && userDetails.hasOwnProperty('customRules')) {
      return userDetails.customRules;
    }
    return {};
  }

  getAuthenticatedUserHomeUrl(userType: string) {
    let url = '';
    if (userType === Constants.STUDENTS_USER_TYPE) {
      url = '/students/dashboard';
    } else if (userType === Constants.COLLEGES_USER_TYPE) {
      url = '/colleges/dashboard';
    } else if (userType === Constants.EMPLOYERS_USER_TYPE) {
      url = '/employers/dashboard';
    } else if (userType === Constants.STAFF_USER_TYPE) {
      url = '/staff/dashboard';
    }
    return url;
  }

  getPageCount(totalRecords: number, recordsPerPage: number) {
    let count = 0;
    if (totalRecords !== 0 && recordsPerPage !== 0) {
      count = Math.ceil(totalRecords / recordsPerPage);
    }
    return count;
  }

  getCollegeObj(props: any) {
    let collegeObj = null;

    if (props.store && props.store.Container && props.store.Container.state) {
      const collegeDetails = props.store.Container.state.collegeDetails;
      if (collegeDetails && collegeDetails.url_slug && collegeDetails.url_slug !== '') {
        collegeObj =  collegeDetails;
      }
    }
    
    return collegeObj;
  }

  getCollegeName(props: any) {
    let collegeName = '';

    if (props.store && props.store.Container && props.store.Container.state) {
      const collegeDetails = props.store.Container.state.collegeDetails;
      if (collegeDetails && collegeDetails.url_slug && collegeDetails.url_slug !== '') {
        collegeName =  collegeDetails.college_name;
      }
    }
    
    return collegeName;
  }

  getEmployerObj(props: any) {
    let employerObj = null;

    if (props.store && props.store.Container && props.store.Container.state) {
      const employerDetails = props.store.Container.state.employerDetails;
      if (employerDetails && employerDetails.url_slug && employerDetails.url_slug !== '') {
        employerObj =  employerDetails;
      }
    }
    
    return employerObj;
  }

  getEmployerName(props: any) {
    let employerName = '';

    if (props.store && props.store.Container && props.store.Container.state) {
      const employerDetails = props.store.Container.state.employerDetails;
      if (employerDetails && employerDetails.url_slug && employerDetails.url_slug !== '') {
        employerName =  employerDetails.business_name;
      }
    }
    
    return employerName;
  }

  getAddress(addressDetails: any) {
    let address = '';
    if (addressDetails.address_line_1) {
      address += addressDetails.address_line_1;
    }
    if (addressDetails.address_line_2) {
      if (address) {
        address += '<br/>';
      }
      address += addressDetails.address_line_2;
    }
    if (addressDetails.city) {
      if (address) {
        address += '<br/>';
      }
      address += addressDetails.city;
    }
    if (addressDetails.state) {
      if (address) {
        address += '<br/>';
      }
      address += addressDetails.state;
    }
    if (addressDetails.zipcode) {
      if (address) {
        address += '<br/>';
      }
      address += addressDetails.zipcode;
    }
    return address;
  }

  getWebsite(url: string) {
    let website = '';
    if (url) {
      const flag1 = url.indexOf('http://') === 0;
      const flag2 = url.indexOf('https://') === 0;
      if (flag1 || flag2) {
        website = url;
      } else {
        // default to http
        website = 'http://' + url;
      }
    }
    return website;
  }

  getStateFromHistory(props: any, key: string) {
    let value = '';
    if (props && props.history && props.history.location && props.history.location.state) {
      const tmpValue = props.history.location.state[key];
      if (tmpValue) {
        value = tmpValue;
      }
    }
    return value;
  }

  getCobrandedValue(props: any, type: string, key: string) {
    let value = '';
    if (type === 'college') {
      const collegeObj = this.getCollegeObj(props);
      if (collegeObj !== null && collegeObj.hasOwnProperty(key)) {
        value = collegeObj[key];
      }
    } else if (type === 'employer') {
      const employerObj = this.getEmployerObj(props);
      if (employerObj !== null && employerObj.hasOwnProperty(key)) {
        value = employerObj[key];
      }
    }
    return value;
  }

  checkS3MediaForDisplay(url: string) {
    // S3 Url
    let mediaOK = (url && url.includes('X-Amz-Algorithm'));
    if (!mediaOK) {
      // CloudFront Url
      mediaOK = (url && url.includes('Policy') && url.includes('Key-Pair-Id'));
    }
    return mediaOK;
  }

  getS3FileName(url: string) {
    let newFilename = '';

    if (url) {
      let objUrl = '';

      // S3 Url
      let idx = url.indexOf('?X-Amz-Algorithm');
      if (idx === -1) {
        // CloudFront Url
        idx = url.indexOf('?Policy');
      }
      if (idx !== -1) {
        objUrl = url.substring(0, idx);
      } else {
        objUrl = url;
      }
      
      const index = objUrl.lastIndexOf('/');
      if (index > 0) {
        const tmpFilename = objUrl.slice(index + 1);
        newFilename = decodeURIComponent(tmpFilename);
      }
    }
    
    return newFilename;
  }
  
  checkFromDbJson(jsonStr: string) {
    if (jsonStr) {
      const str1 = jsonStr.replace(/&#39;/g, `'`);
      const str2 = str1.replace(/&#63;/g, `?`);
      return str2;
    }
    return '';
  }

  // having issues with bsn.Modal hide function (TO DO - recheck)
  customHideModal(modalInstance: any, document: HTMLDocument) {
    if (modalInstance) {
      modalInstance.hide();
    }
    const element1 = document.getElementsByClassName('modal-open') as any;
    if (element1 !== null && element1.length === 1) {
      element1[0].className = '';
    }
    const element2 = document.getElementsByClassName('modal-backdrop') as any;
    if (element2 !== null && element2.length === 1) {
      const chkElement = element2[0] as HTMLElement;
      if (chkElement.classList.contains('fade')) {
        // console.log('close modal and fade');
        chkElement.classList.remove('fade');
      }
    }
  }

  customHandleModalHideEvent(event: any, document: HTMLDocument) {
    if (event && event.target && event.type === 'hide.bs.modal') {
      const element2 = document.getElementsByClassName('modal-backdrop') as any;
      if (element2 !== null && element2.length === 1) {
        const chkElement = element2[0] as HTMLElement;
        if (chkElement.classList.contains('fade') && chkElement.classList.contains('show')) {
          // console.log('show and fade resolved');
          chkElement.classList.remove('fade');
        }
      }
    }
  }

  // react-select wrapper (multi)
  wrapMultiSelectOptions(options: any[], type: string) {
    if (options.length > 0) {
      const newOptions = [];
      switch (type) {
        case 'degreeProgram':
        case 'degreeSpecialization':
        case 'searchStatus':
        case 'states':
        case 'splAblDisabilities':
        case 'collegeTypes':
          for (const x of options) {
            newOptions.push({
              value: x,
              label: x
            });
          }
          break;
        case 'jobReadiness':
        case 'employabilitySkills':
          for (const elem of options) {
            newOptions.push({
              value: elem[0],
              label: elem[0] + ': ' + elem[1]
            });
          }
          break;
        case 'jobLocation':
          for (const elem of options) {
            let str = elem[0];
            if (elem[1]) {
              str += ", " + elem[1];
            }
            newOptions.push({
              value: str,
              label: str
            });
          }
          break; 
        case 'applyJobLocation':
          for (const elem of options) {
            // newOptions.push(elem);
            newOptions.push({ value: elem.value, label: elem.label });
          }
          break;                 
      }
      return newOptions;
    }
    return options;
  }

  checkMultiSelectValue(input: any[]) {
    const newInput = [];
    if (input && input.length > 0) {
      for (const x of input) {
        if (x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
          newInput.push(x);
        }
      }
    }
    return newInput;
  }

  checkMultiSelectDgrSpl(degreeProgram: string, input: any[]) {
    const newInput = [];
    if (input && input.length > 0) {
      for (const x of input) {
        if (x.hasOwnProperty('dp') && x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
          if (x.dp === degreeProgram) {
            newInput.push(x);
          }
        }
      }
    }
    return newInput;
  }

  displayMultiSelectLabel(input: any[]) {
    const newInput = [];
    if (input.length > 0) {
      for (const x of input) {
        if (x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
          newInput.push(x.label);
        }
      }
    }
    return newInput;
  }

  // react-select wrapper (single)
  wrapSingleSelectOptions(options: any[], type: string) {
    if (options.length > 0) {
      const newOptions = [];
      switch (type) {
        case 'graduationYear':
        case 'admissionYear':
          for (const x of options) {
            newOptions.push({
              value: x,
              label: x
            });
          }
          break;
        case 'jobLocation':
          for (const x of options) {
            if (x.length === 2) {
              newOptions.push({
                value: x[0] + ', ' + x[1],
                label: x[0] + ', ' + x[1]
              });
            } else if (x.length === 1) {
              newOptions.push({
                value: x[0],
                label: x[0]
              });
            }
          }
          break;
      }
      return newOptions;
    }
    return options;
  }

  checkSingleSelectValue(input: any[]) {
    const newInput = [];
    if (input.length > 0) {
      for (const x of input) {
        if (x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
          newInput.push(x);
        }
      }
    }
    return newInput;
  }

  displaySingleSelectLabel(input: any[]) {
    let newInput = '';
    if (input.length > 0) {
      for (const x of input) {
        if (x.hasOwnProperty('label') && x.hasOwnProperty('value')) {
          newInput = x.value;
        }
      }
    }
    return newInput;
  }

  checkStudentTasks(props: any, window: Window, type: string, videoUrl: string = '', videoFraction: number  = 0) {
    const userDetails = this.getAuthorizedUserDetails(props) as any;
    if (userDetails.hasOwnProperty('studentTasks')) {
      const studentTasks = userDetails.studentTasks;
      const keys = Object.keys(studentTasks);
      for (const key of keys) {
        const chkObj = studentTasks[key];
        if (chkObj.task_url === window.location.href) {
          const chkDate = chkObj.completion_date || '';
          if (!chkDate) {
            if (type === 'scroll' || type === 'video') {
              const opts = {
                taskId: key,
                taskUrl: chkObj.task_url
              };

              if (type === 'scroll') {
                const elem = document.getElementById('app-footer');
                if (elem) {
                  if (!chkObj.hasOwnProperty('videos')) {
                    const videos = window.document.getElementsByClassName('react-player-wrapper');
                    if (videos.length > 0) {
                      opts['videos'] = videos.length;
                      opts['vvCompletion'] = [];
                      opts['vvDone'] = false;
                      // console.log('scroll type initVideo opts', opts);
                      this.setStudentTasksDetails(props, opts);
                    }
                  }

                  // see https://stackoverflow.com/a/45859483
                  const innerHeight = window.innerHeight;
                  const scrollHeight = window.scrollY;
                  const offsetHeight = document.body.offsetHeight;
                  if (innerHeight + scrollHeight >= offsetHeight) {
                    if (!chkObj.hasOwnProperty('videos')) {
                      const completionDate = this.getMomentInstance().format(this.getDbDateFormat());
                      opts['completionDate'] = completionDate;
                      // console.log('scroll type nonVideo complete opts', opts);
                      this.setStudentTasksDetails(props, opts);
                    } else {
                      const videos = chkObj.videos;
                      const vvCompletion = chkObj.vvCompletion;
                      const vvDone = chkObj.vvDone || false;

                      if (vvDone) {
                        const completionDate = this.getMomentInstance().format(this.getDbDateFormat());
                        opts['completionDate'] = completionDate;
                      }

                      opts['videos'] = videos;
                      opts['vvCompletion'] = vvCompletion;
                      opts['vvDone'] = vvDone;
                      // console.log('scroll type video complete opts', opts);
                      this.setStudentTasksDetails(props, opts);
                    }
                  }
                }
              } else if (type === 'video') {
                if (chkObj.hasOwnProperty('videos')) {
                  const videos = chkObj.videos;
                  const vvCompletion = chkObj.vvCompletion;
                  let vvDone = chkObj.vvDone || false;

                  if (!vvDone && videoFraction === 1 && vvCompletion.indexOf(videoUrl) === -1) {
                    vvCompletion.push(videoUrl);
                  }

                  if (!vvDone && videoFraction === 1 && Number(videos) === vvCompletion.length) {
                    vvDone = true;
                    const completionDate = this.getMomentInstance().format(this.getDbDateFormat());
                    opts['completionDate'] = completionDate;
                  }

                  opts['videos'] = videos;
                  opts['vvCompletion'] = vvCompletion;
                  opts['vvDone'] = vvDone;

                  // console.log('video type complete opts', type, opts);
                  this.setStudentTasksDetails(props, opts);
                }
              }
            }
          }
        }
      }
    }
  }

  checkStudentEmplSkills(props: any, topicId: string, videoFraction: number  = 0) {
    if (props.store && props.store.Container && topicId && videoFraction === 1) {
      const prevUserDetails = props.store.Container.state.userDetails;
      
      if (!prevUserDetails.hasOwnProperty('studentEmplSkills')) {
        // for backward compatibility
        prevUserDetails.studentEmplSkills = {};
      }

      if (!prevUserDetails.studentEmplSkills.hasOwnProperty(topicId)) {
        const completionDate = this.getMomentInstance().toISOString();
        const { studentEmplSkills, ...rest } = prevUserDetails;
        const finalUserDetails = {
          studentEmplSkills,
          ...rest
        };
        studentEmplSkills[topicId] = { completion_date: completionDate };
        props.store.Container.setUserDetailsState(finalUserDetails);
        this.setUserDetails(finalUserDetails);
      }
    }
  }

  toggleStudentEmplSkills(props: any, topicId: string) {
    if (props.store && props.store.Container && topicId) {
      const prevUserDetails = props.store.Container.state.userDetails;
      
      if (!prevUserDetails.hasOwnProperty('studentEmplSkills')) {
        // for backward compatibility
        prevUserDetails.studentEmplSkills = {};
      }

      if (prevUserDetails.studentEmplSkills.hasOwnProperty(topicId)) {
        const { studentEmplSkills, ...rest } = prevUserDetails;
        studentEmplSkills[topicId].api_sync = true;
        const finalUserDetails = {
          studentEmplSkills,
          ...rest
        };
        props.store.Container.setUserDetailsState(finalUserDetails);
        this.setUserDetails(finalUserDetails);
      }
    }
  }

  handleUrlQueryString(url: string, newQueryParameters: object) {
    if (url) {
      const parsedObj = queryString.parseUrl(url, { arrayFormat: 'bracket' });
      
      const queryValues = { ...parsedObj['query'] } as any;
      const keys = Object.keys(newQueryParameters);
      if (keys.length > 0) {
        for (const key of keys) {
          queryValues[key] = newQueryParameters[key];
        }
      }

      if (Object.keys(queryValues).length > 0) {
        return parsedObj['url'] + '?' + queryString.stringify(queryValues, { arrayFormat: 'bracket' });
      }
      return parsedObj['url'];
    }
    return url;
  }

  getQueryParamValue(winLocHref: string, key: string) {
    let value = '';
    if (winLocHref && key) {
      const parsedObj = queryString.parseUrl(winLocHref, { arrayFormat: 'bracket' });
      if (Object.prototype.hasOwnProperty.call(parsedObj['query'], key)) {
        value = parsedObj['query'][key];
      }
    }
    return value;
  }
  getArrayAsString(input: any[]) {
    let str = "";
    if (input.length > 0) {
      for (const rec of input) {
        if (typeof rec === 'string') {
          str += rec + '<br>';
        }else if (typeof rec === 'object' && rec.label) {
          str += rec.label + '<br>';
        }
      }
    }
    return str;
  }

  // see https://flaviocopes.com/javascript-sleep/
  sleep (timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, timeInMillis));
  }

  isSRBSectionCompleted(profile: any, section: string, opts: any = {}) {
    let isSectionCompleted = false;

    try {
      const applyJobPhoto = opts.apply_job_photo || false;

      if (section === 'slug') {
        const slug = profile.slug || '';
        if (General.checkValue(slug)) {
          isSectionCompleted = true;
        }
      }

      if (section === 'video') {
        const videoUrl = profile.video_url || '';
        if (General.checkValue(videoUrl) && this.checkS3MediaForDisplay(videoUrl)) {
          isSectionCompleted = true;
        }
      }

      if (section === 'personal') {
        const personal = profile.personal || {};
        const firstName = personal.first_name || '';
        const email = personal.email || '';
        const mobile = personal.mobile || '';
        const photoUrl = personal.photo_url || '';
        if (!applyJobPhoto) {
          if (
            General.checkValue(firstName) && 
            (General.checkValue(email) || General.checkValue(mobile)) && 
            (General.checkValue(photoUrl) && this.checkS3MediaForDisplay(photoUrl))) {
            isSectionCompleted = true;
          }
        } else {
          if (
            (General.checkValue(photoUrl) && this.checkS3MediaForDisplay(photoUrl))) {
            isSectionCompleted = true;
          }
        }
      }

      if (section === 'summary') {
        const summary = profile.summary || '';
        if (General.checkValue(summary)) {
          isSectionCompleted = true;
        }
      }

      if (section === 'education') {
        const edu = profile.education || [];
        if (edu.length > 0) {
          for (const x of edu) {
            const insttName = x.institution_name || '';
            const dProg = x.degree_program || '';
            const dSpl = x.degree_specialization_1 || '';
            const aYr = x.admission_year || '';
            const gYr = x.graduation_year || '';
            if (
              General.checkValue(insttName) &&
              General.checkValue(dProg) &&
              General.checkValue(dSpl) &&
              General.checkValue(aYr) &&
              General.checkValue(gYr)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'entrance_exams') {
        const ee = profile.entrance_exams || [];
        if (ee.length > 0) {
          for (const x of ee) {
            const identifier = x.exam_identifier || '';
            const maxMarks = x.maximum_marks || '';
            const marks = x.marks || '';
            const rank = x.rank || '';
            if (
              General.checkValue(identifier) &&
              General.checkValue(maxMarks) &&
              General.checkValue(marks) &&
              General.checkValue(rank)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'experience') {
        const exp = profile.experience || [];
        if (exp.length > 0) {
          for (const x of exp) {
            const title = x.title || '';
            const company = x.company || '';
            const location = x.location || '';
            const from = x.from || '';
            const to = x.to || '';
            if (
              General.checkValue(title) &&
              General.checkValue(company) &&
              General.checkValue(location) &&
              General.checkValue(from) &&
              General.checkValue(to)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'recommendations') {
        const rec = profile.recommendations || [];
        if (rec.length > 0) {
          for (const x of rec) {
            const name = x.name || '';
            const email = x.email || '';
            const phone = x.phone || '';
            const role = x.role || '';
            const iRec = x.recommendation || '';
            if (
              General.checkValue(name) &&
              (General.checkValue(email) || General.checkValue(phone)) &&
              General.checkValue(role) &&
              General.checkValue(iRec)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'skills') {
        const skills = profile.skills || [];
        if (skills.length > 0) {
          for (const x of skills) {
            const title = x.title || '';
            const description = x.description || '';
            const competency = x.competency || '';
            if (
              General.checkValue(title) &&
              General.checkValue(description) &&
              General.checkValue(competency)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'projects') {
        const projects = profile.projects || [];
        if (projects.length > 0) {
          for (const x of projects) {
            const title = x.title || '';
            const description = x.description || '';
            const company = x.company || '';
            if (
              General.checkValue(title) &&
              General.checkValue(description) &&
              General.checkValue(company)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'interests') {
        const interests = profile.interests || [];
        if (interests.length > 0) {
          for (const x of interests) {
            const title = x.title || '';
            const description = x.description || '';
            if (
              General.checkValue(title) &&
              General.checkValue(description)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'portfolio') {
        const portfolio = profile.portfolio || [];
        if (portfolio.length > 0) {
          for (const x of portfolio) {
            const title = x.title || '';
            const description = x.description || '';
            if (
              General.checkValue(title) &&
              General.checkValue(description)
            ) {
              isSectionCompleted = true;
              break;
            }
          }
        }
      }

      if (section === 'info') {
        const eca = profile.extracurricular_activities || '';
        const aaw = profile.achievements_and_awards || '';
        if (General.checkValue(eca) && General.checkValue(aaw)) {
          isSectionCompleted = true;
        }
      }
    } catch (err) {
      console.error(err);
    }

    return isSectionCompleted;
  }
}

const HelperInstance = new Helper();
export { HelperInstance as Helper };
