"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MultiSelect = void 0;
const mobx = require("mobx");
class MultiSelect {
  getValue(input) {
    if (input) {
      return mobx.toJS(input);
    }
    return input;
  }
}
const MultiSelectInstance = new MultiSelect();
exports.MultiSelect = MultiSelectInstance;