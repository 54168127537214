"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountProfile = void 0;
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
const apiMessageHelper_1 = require("./../utils/apiMessageHelper");
class AccountProfile extends base_1.Base {
  constructor() {
    super(...arguments);
    this.url = '/account-profile';
  }
  getDetails(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ANDERROR), {
        accountProfile: risesharp_common_1.Schema.getAccountProfile()
      });
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handleGetOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.accountProfile = responseJson.data.accountProfile;
        }
      } catch (err) {
        console.error('AccountProfileService - getDetails', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  updateDetails(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('AccountProfileService - updateDetails', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
}
const AccountProfileInstance = new AccountProfile();
exports.AccountProfile = AccountProfileInstance;