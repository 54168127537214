/* googlebot crawler issue */
import 'babel-polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Routing from './routing';

// Roboto Google fonts
import 'typeface-roboto';

import './css/font-awesome.min.css';
import './css/bootstrap.min.css';
import './css/animate.min.css';
import './css/style.css';

// for PWA service worker
import { unregister } from './registerServiceWorker';

ReactDOM.render(
  <Routing/>,
  document.getElementById('root') as HTMLElement
);

// turn off PWA
unregister();
