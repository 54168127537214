"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
/*
* IMPORTANT - does not entirely use Base functions
*/
class CareerPathway extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.setCustomApiState = mobx_1.action(newState => {
      const api = this.state.api;
      const errorMessage = lodash_1.get(newState, 'errorMessage');
      if (errorMessage !== undefined) {
        api.errorMessage = errorMessage;
      }
      const successMessage = lodash_1.get(newState, 'successMessage');
      if (successMessage !== undefined) {
        api.successMessage = successMessage;
      }
      const careerPathwayData = lodash_1.get(newState, 'careerPathway');
      if (careerPathwayData !== undefined) {
        const client = this.state.client;
        const pathway = lodash_1.get(careerPathwayData, 'pathway');
        if (pathway !== undefined) {
          api.data.pathway = pathway;
          if (pathway.length > 0) {
            client.current_pathway = pathway[0].id;
          } else {
            client.current_pathway = '';
          }
        }
      }
    });
    this.resetCustomApiState = mobx_1.action(() => {
      const api = this.state.api;
      api.errorMessage = '';
      api.successMessage = '';
    });
    this.resetState();
  }
  setCustomGenericError() {
    const api = this.state.api;
    api.errorMessage = risesharp_common_1.Constants.GENERIC_ERROR;
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, this.getDefaultState())
    });
  }
  getDefaultState() {
    return {
      client: {
        pathway_id: '',
        current_pathway: '',
        displaySections: new mobx_1.ObservableMap()
      },
      api: {
        errorMessage: '',
        successMessage: '',
        data: {
          pathway: {}
        }
      },
      spinner: {
        [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
      }
    };
  }
}
exports.default = CareerPathway;