"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class ValuesAssessment extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyValidationState(_this.getValidationDefault(identifier), identifier, subIdentifier);
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT)
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT]: this.getSpinnerDefault()
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT) {
      return {
        id: '',
        fun: '',
        job_security: '',
        recognition: '',
        compensation: '',
        loyalty: '',
        structure: '',
        independence: '',
        respect: '',
        risk_taking: '',
        variety: '',
        learning: '',
        training_development: '',
        colleague_relationships: '',
        making_difference: '',
        creative_opportunities: ''
      };
    }
    return {};
  }
  getValidationDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT) {
      return {
        fun: {
          isError: false,
          errorMessage: ''
        },
        job_security: {
          isError: false,
          errorMessage: ''
        },
        recognition: {
          isError: false,
          errorMessage: ''
        },
        compensation: {
          isError: false,
          errorMessage: ''
        },
        loyalty: {
          isError: false,
          errorMessage: ''
        },
        structure: {
          isError: false,
          errorMessage: ''
        },
        independence: {
          isError: false,
          errorMessage: ''
        },
        respect: {
          isError: false,
          errorMessage: ''
        },
        risk_taking: {
          isError: false,
          errorMessage: ''
        },
        variety: {
          isError: false,
          errorMessage: ''
        },
        learning: {
          isError: false,
          errorMessage: ''
        },
        training_development: {
          isError: false,
          errorMessage: ''
        },
        colleague_relationships: {
          isError: false,
          errorMessage: ''
        },
        making_difference: {
          isError: false,
          errorMessage: ''
        },
        creative_opportunities: {
          isError: false,
          errorMessage: ''
        }
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_VALUESASSESSMENT) {
      return risesharp_common_1.Schema.getDefaultApiMessages();
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
}
exports.default = ValuesAssessment;