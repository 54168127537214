"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Validator = void 0;
const validate = require("validate.js");
const constants_1 = require("./constants");
class Validator {
  validatePresence(input, errorKey, errorValue) {
    let result;
    const error = validate.single(input, {
      presence: {
        allowEmpty: false
      }
    });
    if (error !== undefined) {
      result = {
        [errorKey]: errorValue
      };
    }
    return result;
  }
  validateEmail(input, errorKey, errorValue) {
    let result;
    const error = validate.single(input, {
      email: true
    });
    if (error !== undefined) {
      result = {
        [errorKey]: errorValue
      };
    }
    return result;
  }
  validateOptions(input, options, errorKey, errorValue) {
    let result;
    const error = validate.single(input, {
      inclusion: options
    });
    if (error !== undefined) {
      result = {
        [errorKey]: errorValue
      };
    }
    return result;
  }
  validateId(input, errorKey, errorValue) {
    let result;
    const error = validate.single(input, {
      format: constants_1.Constants.UUID_REGEX
    });
    if (error !== undefined) {
      result = {
        [errorKey]: errorValue
      };
    }
    return result;
  }
  validateMobile(input, errorKey, errorValue) {
    let result;
    let error = this.validatePresence(input, errorKey, errorValue);
    if (error === undefined) {
      const flag1 = input.startsWith('0') && input.length === 11;
      const flag2 = input.startsWith('91') && input.length === 12;
      const flag3 = input.startsWith('+91') && input.length === 13;
      const flag4 = input.startsWith('0091') && input.length === 14;
      if (flag1 || flag2 || flag3 || flag4) {
        let newInput = '';
        if (flag1) {
          newInput = input.slice(1);
        }
        if (flag2) {
          newInput = input.slice(2);
        }
        if (flag3) {
          newInput = input.slice(3);
        }
        if (flag4) {
          newInput = input.slice(4);
        }
        console.log('validateMobile input:newInput', input, newInput);
        error = validate.single(newInput, {
          format: constants_1.Constants.MOBILE_REGEX
        });
        if (error !== undefined) {
          result = {
            [errorKey]: errorValue
          };
        }
      } else {
        error = validate.single(input, {
          format: constants_1.Constants.MOBILE_REGEX
        });
        if (error !== undefined) {
          result = {
            [errorKey]: errorValue
          };
        }
      }
    } else {
      result = {
        [errorKey]: errorValue
      };
    }
    return result;
  }
  validateInteger(input, options, errorKey, errorValue) {
    let result;
    const opts = {
      strict: true,
      onlyInteger: true
    };
    if (options.equalTo) {
      opts.equalTo = options.equalTo;
    }
    if (options.lessThanOrEqualTo) {
      opts.lessThanOrEqualTo = options.lessThanOrEqualTo;
    }
    if (options.greaterThanOrEqualTo) {
      opts.greaterThanOrEqualTo = options.greaterThanOrEqualTo;
    }
    if (options.lessThan) {
      opts.lessThan = options.lessThan;
    }
    if (options.greaterThan) {
      opts.greaterThan = options.greaterThan;
    }
    const error = validate.single(input, {
      numericality: opts
    });
    if (error !== undefined) {
      result = {
        [errorKey]: errorValue
      };
    }
    return result;
  }
  validateRegex(input, regex, errorKey, errorValue) {
    let result;
    const error = validate.single(input, {
      format: regex
    });
    if (error !== undefined) {
      result = {
        [errorKey]: errorValue
      };
    }
    return result;
  }
}
const ValidatorInstance = new Validator();
exports.Validator = ValidatorInstance;