"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JSX = exports.ApiTS = exports.UiTS = exports.CommonTS = void 0;
const CommonLib = require("./ts/common");
exports.CommonTS = CommonLib;
const UiTSLib = require("./ts/ui");
exports.UiTS = UiTSLib;
const ApiLib = require("./ts/api");
exports.ApiTS = ApiLib;
// JSX namespace will be automatically handled by tsc
const JSXLib = require("./ts/jsx");
exports.JSX = JSXLib;
var constants_1 = require("./main/constants");
Object.defineProperty(exports, "Constants", {
  enumerable: true,
  get: function () {
    return constants_1.Constants;
  }
});
var validator_1 = require("./main/validator");
Object.defineProperty(exports, "Validator", {
  enumerable: true,
  get: function () {
    return validator_1.Validator;
  }
});
var validation_1 = require("./main/validation");
Object.defineProperty(exports, "Validation", {
  enumerable: true,
  get: function () {
    return validation_1.Validation;
  }
});
var general_1 = require("./main/general");
Object.defineProperty(exports, "General", {
  enumerable: true,
  get: function () {
    return general_1.General;
  }
});
var schema_1 = require("./main/schema");
Object.defineProperty(exports, "Schema", {
  enumerable: true,
  get: function () {
    return schema_1.Schema;
  }
});
var collegeDegrees_1 = require("./main/collegeDegrees");
Object.defineProperty(exports, "CollegeDegrees", {
  enumerable: true,
  get: function () {
    return collegeDegrees_1.CollegeDegrees;
  }
});