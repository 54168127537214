"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class MakePayment extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT]: this.getSpinnerDefault()
        },
        applyDiscountCode: {
          [risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT]: {
            call_in_progress: false,
            user_disc_code: '',
            disc_already_applied: false,
            disc_applied_msg: '',
            new_pmt_reqd: '',
            new_pmt_reqd_sig: ''
          }
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT) {
      return {
        razorpay_payment_id: '',
        selected_option: ''
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_MAKEPAYMENT) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        makePayment: {
          payment_options: {}
        }
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
}
exports.default = MakePayment;