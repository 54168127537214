"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
/*
* IMPORTANT - does not entirely use Base functions
*/
class TrackAccessUsers extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.setCustomClientState = mobx_1.action((key, value) => {
      const client = this.state.client;
      client[key] = value;
    });
    this.setCustomApiState = mobx_1.action(newState => {
      const api = this.state.api;
      const errorMessage = lodash_1.get(newState, 'errorMessage');
      if (errorMessage !== undefined) {
        api.errorMessage = errorMessage;
      }
      const successMessage = lodash_1.get(newState, 'successMessage');
      if (successMessage !== undefined) {
        api.successMessage = successMessage;
      }
      const trackAccessUsersData = lodash_1.get(newState, 'trackAccessUsers');
      if (trackAccessUsersData !== undefined) {
        const collegesList = lodash_1.get(trackAccessUsersData, 'collegesList');
        if (collegesList !== undefined) {
          api.data.collegesList = collegesList;
        }
        const employersList = lodash_1.get(trackAccessUsersData, 'employersList');
        if (employersList !== undefined) {
          api.data.employersList = employersList;
        }
        const jobsList = lodash_1.get(trackAccessUsersData, 'jobsList');
        if (jobsList !== undefined) {
          api.data.jobsList = jobsList;
        }
        const summaryDetails = lodash_1.get(trackAccessUsersData, 'summaryDetails');
        if (summaryDetails !== undefined) {
          api.data.summaryDetails = summaryDetails;
        }
        const employerDetails = lodash_1.get(trackAccessUsersData, 'employerDetails');
        if (employerDetails !== undefined) {
          api.data.employerDetails = employerDetails;
        }
        const collegeDetails = lodash_1.get(trackAccessUsersData, 'collegeDetails');
        if (collegeDetails !== undefined) {
          api.data.collegeDetails = collegeDetails;
        }
        const studentDetails = lodash_1.get(trackAccessUsersData, 'studentDetails');
        if (studentDetails !== undefined) {
          api.data.studentDetails = studentDetails;
        }
        const jobCollegesList = lodash_1.get(trackAccessUsersData, 'jobCollegesList');
        if (jobCollegesList !== undefined) {
          api.data.jobCollegesList = jobCollegesList;
        }
        const jobDetails = lodash_1.get(trackAccessUsersData, 'jobDetails');
        if (jobDetails !== undefined) {
          api.data.jobDetails = jobDetails;
        }
      }
    });
    this.resetCustomApiState = mobx_1.action(() => {
      const api = this.state.api;
      api.errorMessage = '';
      api.successMessage = '';
    });
    this.resetState();
  }
  setCustomGenericError() {
    const api = this.state.api;
    api.errorMessage = risesharp_common_1.Constants.GENERIC_ERROR;
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, this.getDefaultState())
    });
  }
  getDefaultState() {
    return {
      client: {
        viewMode: '',
        emplDetailsEmployerId: [],
        collDetailsCollegeId: [],
        stuDetailsCollegeId: [],
        jobDetailsJobId: [],
        jobDetailsCollegeId: []
      },
      api: {
        errorMessage: '',
        successMessage: '',
        data: {
          collegesList: [],
          employersList: [],
          jobsList: [],
          jobsCollegesList: [],
          summaryDetails: {
            totalUsers: 0,
            totalPlatformStaff: 0,
            totalEmployersStaff: 0,
            totalCollegesStaff: 0,
            totalStudents: 0,
            totalActiveStudents: 0,
            totalAlumniStudents: 0,
            bothChannels: 0,
            onlyEmails: 0,
            onlyMobile: 0,
            totalBouncedEmails: 0,
            totalInvalidEmails: 0,
            totalEmployers: 0,
            totalEmplZeroJobs: 0,
            totalEmplNonZeroJobs: 0,
            totalColleges: 0,
            totalUnclaimedColleges: 0,
            totalClaimedColleges: 0,
            notProcessedColleges: 0,
            contactInfoColleges: 0,
            introductionColleges: 0,
            verificationColleges: 0,
            notInterestedColleges: 0,
            closedColleges: 0,
            noContactColleges: 0,
            totalCollZeroStudents: 0,
            totalCollNonZeroStudents: 0,
            totalJobs: 0,
            totalJobsZeroCand: 0,
            totalJobsNonZeroCand: 0
          },
          employerDetails: {
            totalUsers: 0,
            loginAttempts: 0,
            lastLoginTime: ''
          },
          collegeDetails: {
            totalUsers: 0,
            loginAttempts: 0,
            lastLoginTime: ''
          },
          studentDetails: {
            totalUsers: 0,
            loginAttempts: 0,
            lastLoginTime: ''
          },
          jobDetails: {
            totalUsers: 0,
            loginAttempts: 0,
            lastLoginTime: ''
          }
        }
      },
      spinner: {
        [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
      }
    };
  }
}
exports.default = TrackAccessUsers;