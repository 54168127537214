"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.properties = void 0;
// see https://medium.com/@tacomanator/environments-with-create-react-app-7b645312c09d
const processEnv = process.env;
const prefix = 'REACT_APP_';
const properties = {
  apiBaseUrl: processEnv[prefix + 'apiBaseUrl'],
  application: {
    name: processEnv[prefix + 'application_name'],
    company: processEnv[prefix + 'application_company'],
    website: processEnv[prefix + 'application_website'],
    support_email: processEnv[prefix + 'application_support_email'],
    team_name: processEnv[prefix + 'application_team_name']
  },
  google_analytics_id: processEnv[prefix + 'google_analytics_id'],
  google_maps_key: processEnv[prefix + 'google_maps_key'],
  credits_negativelimit_email: processEnv[prefix + 'credits_negativelimit_email'],
  razorpay_api_key: processEnv[prefix + 'razorpay_api_key'],
  recaptcha_site_key: processEnv[prefix + 'recaptcha_site_key'],
  tvcollege_code: processEnv[prefix + 'tvcollege_code'],
  aicte_college_code: processEnv[prefix + 'aicte_college_code']
};
exports.properties = properties;