"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class ViewResume extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_VIEWRESUME]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_VIEWRESUME)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
        },
        showLink: {
          [risesharp_common_1.Constants.IDENTIFIER_VIEWRESUME]: false
        },
        showFullRecommendation: {
          [risesharp_common_1.Constants.IDENTIFIER_VIEWRESUME]: new mobx_1.ObservableMap()
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_VIEWRESUME) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        viewResume: risesharp_common_1.Schema.getViewResumeDetails()
      });
    }
    return {};
  }
}
exports.default = ViewResume;