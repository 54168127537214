"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
/*
* IMPORTANT - does not entirely use Base functions
*/
class EmployerSubscription extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.setCustomApiState = mobx_1.action(function (newState) {
      let startTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      let endTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      const api = _this.state.api;
      const errorMessage = lodash_1.get(newState, 'errorMessage');
      if (errorMessage !== undefined) {
        api.errorMessage = errorMessage;
      }
      const successMessage = lodash_1.get(newState, 'successMessage');
      if (successMessage !== undefined) {
        api.successMessage = successMessage;
      }
      const employerSubscriptionData = lodash_1.get(newState, 'employerSubscription');
      if (employerSubscriptionData !== undefined) {
        const pricing = lodash_1.get(employerSubscriptionData, 'pricing');
        if (pricing !== undefined) {
          api.data.pricing = pricing;
        }
        const plan = lodash_1.get(employerSubscriptionData, 'plan');
        if (plan !== undefined) {
          api.data.plan = plan;
          _this.copyToClient(startTime, endTime);
        }
      }
    });
    this.resetCustomApiState = mobx_1.action(() => {
      const api = this.state.api;
      api.errorMessage = '';
      api.successMessage = '';
    });
    this.resetState();
  }
  setCustomGenericError() {
    const api = this.state.api;
    api.errorMessage = risesharp_common_1.Constants.GENERIC_ERROR;
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, this.getDefaultState())
    });
  }
  getDefaultState() {
    return {
      client: {
        form: {
          employer_id: '',
          free_plan: '',
          paid_plan: '',
          pan_india_plan: '',
          states_plan: '',
          states: [],
          start_time: '',
          end_time: '',
          total_amount: '',
          discount_percentage: '',
          net_amount: '',
          plan_calculation: {}
        }
      },
      api: {
        errorMessage: '',
        successMessage: '',
        data: {
          pricing: {},
          plan: {}
        }
      },
      spinner: {
        [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false,
        [risesharp_common_1.Constants.IDENTIFIER_EMPLSUBSCRIPTION]: false
      }
    };
  }
  copyToClient(startTime, endTime) {
    const api = this.state.api;
    const apiData = api.data;
    const plan = apiData.plan;
    if (Object.keys(plan).length > 0) {
      const client = this.state.client;
      const form = client.form;
      form.employer_id = plan.employer_id || '';
      form.free_plan = plan.free_plan || '';
      form.paid_plan = plan.paid_plan || '';
      form.pan_india_plan = plan.pan_india_plan || '';
      form.states_plan = plan.states_plan || '';
      form.start_time = startTime;
      form.end_time = endTime;
      form.total_amount = plan.total_amount || '';
      form.discount_percentage = plan.discount_percentage || '';
      form.net_amount = plan.net_amount || '';
      form.plan_calculation = plan.plan_calculation || {};
      const states = plan.states || [];
      const newStates = [];
      for (const x of states) {
        newStates.push({
          label: x,
          value: x
        });
      }
      form.states = newStates;
    }
  }
}
exports.default = EmployerSubscription;