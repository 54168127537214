import { Helper } from './helper';

import { UiTS } from 'risesharp-common';

class ServiceInput {
  prepareInput(props: any, args: UiTS.RestClientInputArgs) {
    const obj = {} as UiTS.RestClientInput;
    obj.token = Helper.getAuthorizedToken(props);
    obj.apiArgs = args;
    if (!obj.apiArgs.hasOwnProperty('isMobileApp')) {
      obj.apiArgs.isMobileApp = false;
    }
    return obj;
  }
}

const ServiceInputInstance = new ServiceInput();
export { ServiceInputInstance as ServiceInput };
