"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class ApproveLinkedJobs extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault()
        },
        displaySections: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: this.getDisplaySectionsDefault()
        },
        pagination: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: risesharp_common_1.Schema.getPaginationDefault()
        },
        filters: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: {
            employers: []
          }
        },
        collegeSelection: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: new mobx_1.ObservableMap()
        },
        sendMessage: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: {}
        },
        tpoResume: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: {}
        },
        s3Progress: {
          [risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS]: {
            email_attachments: '',
            tpo_resume: ''
          }
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_APPROVELINKEDJOBS) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        noDetails: true
      }, {
        approveLinkedJobsList: risesharp_common_1.Schema.getPaginationList()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
  getDisplaySectionsDefault() {
    return {
      filters: false
    };
  }
}
exports.default = ApproveLinkedJobs;