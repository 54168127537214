"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class CollegeEnrollmentDetails extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.modifyRegularFormState = mobx_1.action((name, value, identifier) => {
      const map = this.state.form[identifier];
      map.set(name, value);
    });
    this.modifyCustomFormState = mobx_1.action((value, index, identifier) => {
      const map = this.state.form[identifier];
      const detailsObj = map.get('details') || {};
      const jsonObj = Object.assign({}, detailsObj, {
        [index.toString()]: value
      });
      map.set('details', jsonObj);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS)
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS]: this.getSpinnerDefault()
        },
        displayForm: {
          [risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS]: true
        },
        coursesList: {
          [risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS]: []
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS) {
      const map = new mobx_1.ObservableMap();
      map.set('id', '');
      map.set('enrollment_year', '');
      map.set('degree_program', '');
      map.set('degree_specialization', '');
      map.set('no_of_years', '');
      map.set('details', {});
      return map;
    }
    return {};
  }
  getValidationDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS) {
      return {
        enrollment_year: {
          isError: false,
          errorMessage: ''
        },
        degree_program: {
          isError: false,
          errorMessage: ''
        },
        degree_specialization: {
          isError: false,
          errorMessage: ''
        }
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        collegeEnrollmentDetails: risesharp_common_1.Schema.getCollegeEnrollmentDetails()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
}
exports.default = CollegeEnrollmentDetails;