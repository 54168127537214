import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { General } from './../parentClasses/general';

class NotFound extends General {
  render() {
    return (
      <Redirect
        to={{
          pathname: "/error-404",
          state: { pageNotFound: true }
        }}
      />
    );
  }
}

export default NotFound;
