"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Signout = void 0;
const base_1 = require("./base");
class Signout extends base_1.Base {
  constructor() {
    super(...arguments);
    this.url = '/signout';
  }
  logout(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        // @ts-ignore
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        yield this.handlePostOpsInput(token, input, this.url);
        // no need to check for api response
        // IMPORTANT: pwa/jsn component needs to handle token removal
      } catch (err) {
        console.error('SignoutService-logout', err);
      }
    });
  }
}
const SignoutInstance = new Signout();
exports.Signout = SignoutInstance;