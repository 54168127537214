"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class StudentAppointments extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS]: this.getSpinnerDefault()
        },
        displayForm: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS]: this.getDisplayFormDefault()
        },
        coursesList: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS]: []
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS) {
      return risesharp_common_1.Schema.getStudentAppointmentsDetails();
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTAPPOINTMENTS) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        studentAppointmentsDetails: risesharp_common_1.Schema.getStudentAppointmentsDetails()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
  getDisplayFormDefault() {
    return true;
  }
}
exports.default = StudentAppointments;