"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class VerifyCertificate extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_VERIFYCERTIFICATE]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_VERIFYCERTIFICATE)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault()
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_VERIFYCERTIFICATE) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), {
        userDetails: {
          url: '',
          photo_url: '',
          certificate_id: '',
          name: '',
          date: ''
        }
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
}
exports.default = VerifyCertificate;