import * as React from 'react';

import { General } from './../parentClasses/general';

class Content extends General {
  render() {
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter || false;
    return (
      <React.Fragment>
        {this.props.children}
        <div id="page-loader-stk-ftr" style={{ minHeight: '0' }}></div>
        {(
          hideHeaderAndFooter &&
          <div id="page-loader-no-ftr" style={{ minHeight: '0' }}></div>
        )}
      </React.Fragment>
    );
  }
}

export default Content;
