"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Validation = void 0;
const PasswordValidator = require('password-validator');
const validator_1 = require("./validator");
const constants_1 = require("./constants");
const general_1 = require("./general");
class Validation {
  getErrors(errorDetails) {
    const errorMessages = {};
    for (const element of errorDetails) {
      if (element !== undefined) {
        Object.keys(element).forEach(key => {
          errorMessages[key] = element[key];
        });
      }
    }
    return errorMessages;
  }
  isEmailOrMobile(input) {
    const regexTest = s => {
      const pattern = /^[0-9]+$/;
      return pattern.test(s);
    };
    let retVal = 0; // error
    if (input) {
      if (regexTest(input)) {
        retVal = 1; // mobile
      } else {
        retVal = 2; // email
      }
    }
    return retVal;
  }
  checkContactUsInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.name, this.switchKeys('name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    errorDetails.push(validator_1.Validator.validatePresence(input.subject, this.switchKeys('subject', altKeys), constants_1.Constants.MISSING_SUBJECT));
    errorDetails.push(validator_1.Validator.validatePresence(input.message, this.switchKeys('message', altKeys), constants_1.Constants.MISSING_MESSAGE));
    errorDetails.push(validator_1.Validator.validatePresence(input.role, this.switchKeys('role', altKeys), constants_1.Constants.MISSING_ROLE));
    errorDetails.push(validator_1.Validator.validatePresence(input.phone, this.switchKeys('phone', altKeys), constants_1.Constants.MISSING_PHONE));
    return errorDetails;
  }
  checkSignupInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    const email = input.email;
    const mobile = input.mobile;
    if (!email && !mobile) {
      errorDetails.push(validator_1.Validator.validateEmail('', this.switchKeys('email', altKeys), constants_1.Constants.ONEOF_EMAILMOBILE_EMAIL));
      errorDetails.push(validator_1.Validator.validateMobile('', this.switchKeys('mobile', altKeys), constants_1.Constants.ONEOF_EMAILMOBILE_MOBILE));
    }
    if (email && !mobile) {
      errorDetails.push(validator_1.Validator.validateEmail(email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    }
    if (!email && mobile) {
      errorDetails.push(validator_1.Validator.validateMobile(mobile, this.switchKeys('mobile', altKeys), constants_1.Constants.MISSING_INVALID_MOBILE));
    }
    if (email && mobile) {
      const emailErrorObj = validator_1.Validator.validateEmail(email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL);
      if (emailErrorObj) {
        // validate mobile only if email fails
        const mobileErrorObj = validator_1.Validator.validateMobile(mobile, this.switchKeys('mobile', altKeys), constants_1.Constants.MISSING_INVALID_MOBILE);
        if (mobileErrorObj) {
          // both email and mobile failed
          errorDetails.push(emailErrorObj);
          errorDetails.push(mobileErrorObj);
        } else {
          // mobile cleared but email failed - allowed as per requirement
        }
      }
    }
    return errorDetails;
  }
  checkForgotPasswordInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    if (input.email_selected) {
      errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    }
    if (input.mobile_selected) {
      errorDetails.push(validator_1.Validator.validateMobile(input.mobile, this.switchKeys('mobile', altKeys), constants_1.Constants.MISSING_INVALID_MOBILE));
    }
    return errorDetails;
  }
  checkChangePasswordInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.code, this.switchKeys('code', altKeys), constants_1.Constants.MISSING_CONFIRMATION_CODE));
    errorDetails.push(validator_1.Validator.validatePresence(input.password, this.switchKeys('password', altKeys), constants_1.Constants.MISSING_PASSWORD));
    if (input.password && !this.checkPasswordPolicy(input.password)) {
      errorDetails.push(validator_1.Validator.validatePresence('', this.switchKeys('password', altKeys), constants_1.Constants.INVALID_PASSWORD_POLICY));
    }
    return errorDetails;
  }
  checkSigninInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    const isEmailOrMobile = this.isEmailOrMobile(input.email_or_mobile);
    if (isEmailOrMobile === 0) {
      errorDetails.push(validator_1.Validator.validateEmail('', this.switchKeys('email_or_mobile', altKeys), constants_1.Constants.ONEOF_EMAILMOBILE_EMAIL));
    } else {
      if (isEmailOrMobile === 1) {
        if (input.mobile) {
          errorDetails.push(validator_1.Validator.validateMobile(input.email_or_mobile, this.switchKeys('email_or_mobile', altKeys), constants_1.Constants.MISSING_INVALID_MOBILE));
        }
      } else {
        errorDetails.push(validator_1.Validator.validateEmail(input.email_or_mobile, this.switchKeys('email_or_mobile', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
      }
    }
    // check password (skip for directlogin access)
    const directloginMode = input.directlogin_mode || false;
    if (!directloginMode) {
      errorDetails.push(validator_1.Validator.validatePresence(input.password, this.switchKeys('password', altKeys), constants_1.Constants.MISSING_PASSWORD));
    }
    return errorDetails;
  }
  checkUpdateAccountProfileInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.first_name, this.switchKeys('first_name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    return errorDetails;
  }
  checkUpdatePasswordInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.old_password, this.switchKeys('old_password', altKeys), constants_1.Constants.MISSING_EXISTING_PASSWORD));
    errorDetails.push(validator_1.Validator.validatePresence(input.new_password, this.switchKeys('new_password', altKeys), constants_1.Constants.MISSING_NEW_PASSWORD));
    if (input.new_password && !this.checkPasswordPolicy(input.new_password)) {
      errorDetails.push(validator_1.Validator.validatePresence('', this.switchKeys('new_password', altKeys), constants_1.Constants.INVALID_PASSWORD_POLICY));
    }
    return errorDetails;
  }
  checkCollegeContactUsInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.name, this.switchKeys('name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    errorDetails.push(validator_1.Validator.validatePresence(input.phone, this.switchKeys('phone', altKeys), constants_1.Constants.MISSING_PHONE));
    errorDetails.push(validator_1.Validator.validatePresence(input.job_title, this.switchKeys('job_title', altKeys), constants_1.Constants.MISSING_JOB_TITLE));
    errorDetails.push(validator_1.Validator.validatePresence(input.college_name, this.switchKeys('college_name', altKeys), constants_1.Constants.MISSING_COLLEGE_NAME));
    errorDetails.push(validator_1.Validator.validatePresence(input.college_address, this.switchKeys('college_address', altKeys), constants_1.Constants.MISSING_COLLEGE_ADDRESS));
    return errorDetails;
  }
  checkUrlSlugInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.slug, this.switchKeys('slug', altKeys), constants_1.Constants.MISSING_URL_SLUG));
    errorDetails.push(validator_1.Validator.validatePresence(input.user_type, this.switchKeys('user_type', altKeys), constants_1.Constants.MISSING_USER_TYPE));
    return errorDetails;
  }
  checkAddCollegeInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.college_name, this.switchKeys('college_name', altKeys), constants_1.Constants.MISSING_COLLEGE_NAME));
    errorDetails.push(validator_1.Validator.validatePresence(input.city, this.switchKeys('city', altKeys), constants_1.Constants.MISSING_CITY));
    errorDetails.push(validator_1.Validator.validatePresence(input.country, this.switchKeys('country', altKeys), constants_1.Constants.MISSING_COUNTRY));
    let ctDataOK = false;
    const collegeTypes = input.college_types;
    if (collegeTypes.length > 0) {
      ctDataOK = true;
    }
    if (!ctDataOK) {
      errorDetails.push(validator_1.Validator.validatePresence(input.college_types, this.switchKeys('college_types', altKeys), constants_1.Constants.MISSING_COLLEGE_TYPE));
    }
    errorDetails.push(validator_1.Validator.validatePresence(input.url_slug, this.switchKeys('url_slug', altKeys), constants_1.Constants.MISSING_URL_SLUG));
    errorDetails.push(validator_1.Validator.validatePresence(input.admin_first_name, this.switchKeys('admin_first_name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.admin_email, this.switchKeys('admin_email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    return errorDetails;
  }
  checkUpdateCollegeInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.college_name, this.switchKeys('college_name', altKeys), constants_1.Constants.MISSING_COLLEGE_NAME));
    errorDetails.push(validator_1.Validator.validatePresence(input.city, this.switchKeys('city', altKeys), constants_1.Constants.MISSING_CITY));
    errorDetails.push(validator_1.Validator.validatePresence(input.country, this.switchKeys('country', altKeys), constants_1.Constants.MISSING_COUNTRY));
    let ctDataOK = false;
    const collegeTypes = input.college_types;
    if (collegeTypes.length > 0) {
      ctDataOK = true;
    }
    if (!ctDataOK) {
      errorDetails.push(validator_1.Validator.validatePresence(input.college_types, this.switchKeys('college_types', altKeys), constants_1.Constants.MISSING_COLLEGE_TYPE));
    }
    errorDetails.push(validator_1.Validator.validatePresence(input.admin_first_name, this.switchKeys('admin_first_name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.admin_email, this.switchKeys('admin_email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    return errorDetails;
  }
  checkAddEmployerInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.business_name, this.switchKeys('business_name', altKeys), constants_1.Constants.MISSING_BUSINESS_NAME));
    errorDetails.push(validator_1.Validator.validatePresence(input.country, this.switchKeys('country', altKeys), constants_1.Constants.MISSING_COUNTRY));
    errorDetails.push(validator_1.Validator.validatePresence(input.url_slug, this.switchKeys('url_slug', altKeys), constants_1.Constants.MISSING_URL_SLUG));
    errorDetails.push(validator_1.Validator.validatePresence(input.admin_first_name, this.switchKeys('admin_first_name', altKeys), constants_1.Constants.MISSING_NAME));
    if (!input.admin_email && !input.admin_mobile) {
      errorDetails.push(validator_1.Validator.validateEmail('', this.switchKeys('admin_email', altKeys), constants_1.Constants.ONEOF_EMAILMOBILE_EMAIL));
      errorDetails.push(validator_1.Validator.validateMobile('', this.switchKeys('admin_mobile', altKeys), constants_1.Constants.ONEOF_EMAILMOBILE_MOBILE));
    } else {
      if (input.admin_email) {
        errorDetails.push(validator_1.Validator.validateEmail(input.admin_email, this.switchKeys('admin_email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
      }
      if (input.admin_mobile) {
        errorDetails.push(validator_1.Validator.validateMobile(input.admin_mobile, this.switchKeys('admin_mobile', altKeys), constants_1.Constants.MISSING_INVALID_MOBILE));
      }
    }
    return errorDetails;
  }
  checkUpdateEmployerInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.business_name, this.switchKeys('business_name', altKeys), constants_1.Constants.MISSING_BUSINESS_NAME));
    errorDetails.push(validator_1.Validator.validatePresence(input.country, this.switchKeys('country', altKeys), constants_1.Constants.MISSING_COUNTRY));
    errorDetails.push(validator_1.Validator.validatePresence(input.admin_first_name, this.switchKeys('admin_first_name', altKeys), constants_1.Constants.MISSING_NAME));
    if (!input.admin_email && !input.admin_mobile) {
      errorDetails.push(validator_1.Validator.validateEmail('', this.switchKeys('admin_email', altKeys), constants_1.Constants.ONEOF_EMAILMOBILE_EMAIL));
      errorDetails.push(validator_1.Validator.validateMobile('', this.switchKeys('admin_mobile', altKeys), constants_1.Constants.ONEOF_EMAILMOBILE_MOBILE));
    } else {
      if (input.admin_email) {
        errorDetails.push(validator_1.Validator.validateEmail(input.admin_email, this.switchKeys('admin_email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
      }
      if (input.admin_mobile) {
        errorDetails.push(validator_1.Validator.validateMobile(input.admin_mobile, this.switchKeys('admin_mobile', altKeys), constants_1.Constants.MISSING_INVALID_MOBILE));
      }
    }
    return errorDetails;
  }
  checkEmployerContactUsInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.name, this.switchKeys('name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    errorDetails.push(validator_1.Validator.validatePresence(input.phone, this.switchKeys('phone', altKeys), constants_1.Constants.MISSING_PHONE));
    errorDetails.push(validator_1.Validator.validatePresence(input.job_title, this.switchKeys('job_title', altKeys), constants_1.Constants.MISSING_JOB_TITLE));
    errorDetails.push(validator_1.Validator.validatePresence(input.business_name, this.switchKeys('business_name', altKeys), constants_1.Constants.MISSING_BUSINESS_NAME));
    errorDetails.push(validator_1.Validator.validatePresence(input.business_address, this.switchKeys('business_address', altKeys), constants_1.Constants.MISSING_BUSINESS_ADDRESS));
    return errorDetails;
  }
  checkFetchAdministratorsInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.query, this.switchKeys('query', altKeys), constants_1.Constants.MISSING_ADMIN_QUERY));
    errorDetails.push(validator_1.Validator.validatePresence(input.type, this.switchKeys('type', altKeys), constants_1.Constants.MISSING_ADMIN_TYPE));
    return errorDetails;
  }
  checkPersonalityAssessmentInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.detailed_plans, this.switchKeys('detailed_plans', altKeys), constants_1.Constants.MISSING_DETAILED_PLANS));
    errorDetails.push(validator_1.Validator.validatePresence(input.clear_goals, this.switchKeys('clear_goals', altKeys), constants_1.Constants.MISSING_CLEAR_GOALS));
    errorDetails.push(validator_1.Validator.validatePresence(input.like_everyone, this.switchKeys('like_everyone', altKeys), constants_1.Constants.MISSING_LIKE_EVERYONE));
    errorDetails.push(validator_1.Validator.validatePresence(input.finish_tasks, this.switchKeys('finish_tasks', altKeys), constants_1.Constants.MISSING_FINISH_TASKS));
    errorDetails.push(validator_1.Validator.validatePresence(input.money_motivation, this.switchKeys('money_motivation', altKeys), constants_1.Constants.MISSING_MONEY_MOTIVATION));
    errorDetails.push(validator_1.Validator.validatePresence(input.team_environment, this.switchKeys('team_environment', altKeys), constants_1.Constants.MISSING_TEAM_ENVIRONMENT));
    return errorDetails;
  }
  checkInterestsAssessmentInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.realistic, this.switchKeys('realistic', altKeys), constants_1.Constants.MISSING_REALISTIC_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.investigative, this.switchKeys('investigative', altKeys), constants_1.Constants.MISSING_INVESTIGATIVE_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.artistic, this.switchKeys('artistic', altKeys), constants_1.Constants.MISSING_ARTISTIC_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.social, this.switchKeys('social', altKeys), constants_1.Constants.MISSING_SOCIAL_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.enterprising, this.switchKeys('enterprising', altKeys), constants_1.Constants.MISSING_ENTERPRISING_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.conventional, this.switchKeys('conventional', altKeys), constants_1.Constants.MISSING_CONVENTIONAL_CHOICE));
    return errorDetails;
  }
  checkValuesAssessmentInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.fun, this.switchKeys('fun', altKeys), constants_1.Constants.MISSING_FUN_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.job_security, this.switchKeys('job_security', altKeys), constants_1.Constants.MISSING_JOBSECURITY_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.recognition, this.switchKeys('recognition', altKeys), constants_1.Constants.MISSING_RECOGNITION_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.compensation, this.switchKeys('compensation', altKeys), constants_1.Constants.MISSING_COMPENSATION_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.loyalty, this.switchKeys('loyalty', altKeys), constants_1.Constants.MISSING_LOYALTY_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.structure, this.switchKeys('structure', altKeys), constants_1.Constants.MISSING_STRUCTURE_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.independence, this.switchKeys('independence', altKeys), constants_1.Constants.MISSING_INDEPENDENCE_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.respect, this.switchKeys('respect', altKeys), constants_1.Constants.MISSING_RESPECT_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.risk_taking, this.switchKeys('risk_taking', altKeys), constants_1.Constants.MISSING_RISK_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.variety, this.switchKeys('variety', altKeys), constants_1.Constants.MISSING_VARIETY_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.learning, this.switchKeys('learning', altKeys), constants_1.Constants.MISSING_LEARNING_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.training_development, this.switchKeys('training_development', altKeys), constants_1.Constants.MISSING_TRAINING_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.colleague_relationships, this.switchKeys('colleague_relationships', altKeys), constants_1.Constants.MISSING_COLLEAGUE_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.making_difference, this.switchKeys('making_difference', altKeys), constants_1.Constants.MISSING_DIFFERENCE_CHOICE));
    errorDetails.push(validator_1.Validator.validatePresence(input.creative_opportunities, this.switchKeys('creative_opportunities', altKeys), constants_1.Constants.MISSING_CREATIVE_CHOICE));
    return errorDetails;
  }
  checkCompetencyAssessmentInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    if (input.section_id !== 'readiness_score') {
      errorDetails.push(validator_1.Validator.validatePresence(input.rank, this.switchKeys('rank', altKeys), constants_1.Constants.MISSING_RANK));
      errorDetails.push(validator_1.Validator.validatePresence(input.plan, this.switchKeys('plan', altKeys), constants_1.Constants.MISSING_PLAN));
    }
    return errorDetails;
  }
  checkEmployerJobInput(input) {
    let mode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let altKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const errorDetails = [];
    const flag1 = mode && (mode === 'add' || mode === 'update' || mode.indexOf('section1') !== -1);
    const flag3 = mode && (mode === 'add' || mode === 'update' || mode.indexOf('section3') !== -1);
    const flag6 = mode && (mode === 'add' || mode === 'update' || mode.indexOf('section6') !== -1);
    if (flag1) {
      errorDetails.push(validator_1.Validator.validatePresence(input.job_title, this.switchKeys('job_title', altKeys), constants_1.Constants.MISSING_JOB_TITLE));
      errorDetails.push(validator_1.Validator.validatePresence(input.job_type, this.switchKeys('job_type', altKeys), constants_1.Constants.MISSING_JOB_TYPE));
      errorDetails.push(validator_1.Validator.validatePresence(input.job_function, this.switchKeys('job_function', altKeys), constants_1.Constants.MISSING_JOB_FUNCTION));
      if (input.job_type === 'Full-time') {
        let ctcMatches = false;
        let ctcBandFrequency = '';
        if (general_1.General.matchCTCFrequency(input.ctc_band_frequency)) {
          ctcBandFrequency = input.ctc_band_frequency;
        }
        if (ctcBandFrequency && input.min_ctc_band && input.max_ctc_band) {
          const [lowerLimitOK, tmpLowerLimit] = general_1.General.convertToInteger(input.min_ctc_band, {
            removeCommas: true
          });
          const [upperLimitOK, tmpUpperLimit] = general_1.General.convertToInteger(input.max_ctc_band, {
            removeCommas: true
          });
          if (lowerLimitOK && upperLimitOK) {
            if (tmpLowerLimit > 0 && tmpUpperLimit > 0 && tmpUpperLimit >= tmpLowerLimit) {
              ctcMatches = true;
            }
          }
        }
        if (!ctcMatches) {
          errorDetails.push(validator_1.Validator.validatePresence('', this.switchKeys('ctc', altKeys), constants_1.Constants.MISSING_INVALID_CTC));
        }
      }
    }
    if (flag3) {
      const degreePrograms = input.degree_program || [];
      if (degreePrograms.length === 0) {
        errorDetails.push(validator_1.Validator.validatePresence(input.degree_program, this.switchKeys('degree_program', altKeys), constants_1.Constants.MISSING_JOB_DEGREEPROGRAM));
      }
      if (degreePrograms.length > 0) {
        const academicYears = input.academic_years || [];
        if (academicYears.length === 0) {
          errorDetails.push(validator_1.Validator.validatePresence(input.academic_years, this.switchKeys('academic_years', altKeys), constants_1.Constants.MISSING_JOB_ACADYR));
        } else {
          let ayErrorRaised = false;
          for (const x of academicYears) {
            const years = x.years || [];
            if (years.length === 0) {
              if (!ayErrorRaised) {
                ayErrorRaised = true;
                errorDetails.push(validator_1.Validator.validatePresence(input.academic_years, this.switchKeys('academic_years', altKeys), constants_1.Constants.INVALID_JOB_ACADYR));
              }
            }
          }
        }
      }
    }
    if (flag6) {
      const applyExternal = input.apply_external || 'N';
      if (applyExternal === 'Y') {
        let website = '';
        const url = input.apply_ext_url || '';
        if (url) {
          const flag1 = url.indexOf('http://') === 0;
          const flag2 = url.indexOf('https://') === 0;
          if (flag1 || flag2) {
            website = url;
          }
        }
        if (!website) {
          errorDetails.push(validator_1.Validator.validatePresence('', this.switchKeys('apply_ext_url', altKeys), constants_1.Constants.MISSING_INVALID_WEBSITE));
        }
      }
    }
    return errorDetails;
  }
  checkApplyJobInput(input, jobLocation) {
    let altKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.first_name, this.switchKeys('first_name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    errorDetails.push(validator_1.Validator.validateMobile(input.mobile, this.switchKeys('mobile', altKeys), constants_1.Constants.MISSING_INVALID_MOBILE));
    if (jobLocation.length > 1) {
      const sjl = input.job_location;
      if (!sjl || sjl.length === 0) {
        errorDetails.push({
          job_location: constants_1.Constants.MISSING_JOB_LOCATION
        });
      }
    }
    return errorDetails;
  }
  checkStudentJobSrchStatusInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.search_status, this.switchKeys('search_status', altKeys), constants_1.Constants.MISSING_SEARCH_STATUS));
    return errorDetails;
  }
  checkStudentImportInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.csv_url, this.switchKeys('csv_url', altKeys), constants_1.Constants.INVALID_CSV_TYPE));
    return errorDetails;
  }
  checkEmployerInvitationInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.full_name, this.switchKeys('full_name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.contact_email, this.switchKeys('contact_email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    errorDetails.push(validator_1.Validator.validatePresence(input.subject, this.switchKeys('subject', altKeys), constants_1.Constants.MISSING_SUBJECT));
    errorDetails.push(validator_1.Validator.validatePresence(input.message, this.switchKeys('message', altKeys), constants_1.Constants.MISSING_MESSAGE));
    return errorDetails;
  }
  checkStaffNotifyStudents(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.subject, this.switchKeys('subject', altKeys), constants_1.Constants.MISSING_SUBJECT));
    errorDetails.push(validator_1.Validator.validatePresence(input.message, this.switchKeys('message', altKeys), constants_1.Constants.MISSING_MESSAGE));
    return errorDetails;
  }
  checkCollegeEnrollmentDetails(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.enrollment_year, this.switchKeys('enrollment_year', altKeys), constants_1.Constants.MISSING_COLLEGE_ENROLLMENTYR));
    errorDetails.push(validator_1.Validator.validatePresence(input.degree_program, this.switchKeys('degree_program', altKeys), constants_1.Constants.MISSING_COLLEGE_DEGREEPROGRAM));
    errorDetails.push(validator_1.Validator.validatePresence(input.degree_specialization, this.switchKeys('degree_specialization', altKeys), constants_1.Constants.MISSING_COLLEGE_DEGREESPECN));
    return errorDetails;
  }
  checkStaffNotifyTpos(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validateOptions(input.role_identifier, [constants_1.Constants.ROLE_COLL_STUDENT_MENTOR, constants_1.Constants.ROLE_COLL_DEPT_COORDINATOR, constants_1.Constants.ROLE_COLL_PRINCIPAL, constants_1.Constants.ROLE_COLL_PLACEMENT_STAFF, constants_1.Constants.ROLE_COLL_SUPER_ADMIN], 'role_identifier', constants_1.Constants.MISSING_USER_ROLE));
    errorDetails.push(validator_1.Validator.validatePresence(input.subject, this.switchKeys('subject', altKeys), constants_1.Constants.MISSING_SUBJECT));
    errorDetails.push(validator_1.Validator.validatePresence(input.message, this.switchKeys('message', altKeys), constants_1.Constants.MISSING_MESSAGE));
    return errorDetails;
  }
  checkEmployerSignupInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.name, this.switchKeys('name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    errorDetails.push(validator_1.Validator.validatePresence(input.phone, this.switchKeys('phone', altKeys), constants_1.Constants.MISSING_PHONE));
    errorDetails.push(validator_1.Validator.validatePresence(input.job_title, this.switchKeys('job_title', altKeys), constants_1.Constants.MISSING_JOB_TITLE));
    errorDetails.push(validator_1.Validator.validatePresence(input.business_name, this.switchKeys('business_name', altKeys), constants_1.Constants.MISSING_BUSINESS_NAME));
    return errorDetails;
  }
  checkEventsWorkshopsInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.event_title, 'event_title', constants_1.Constants.MISSING_EVENT_TITLE));
    errorDetails.push(validator_1.Validator.validatePresence(input.event_start, 'event_start', constants_1.Constants.MISSING_EVENT_START));
    errorDetails.push(validator_1.Validator.validatePresence(input.event_end, 'event_end', constants_1.Constants.MISSING_EVENT_END));
    return errorDetails;
  }
  checkCollegeClaimInput(input) {
    let altKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const errorDetails = [];
    errorDetails.push(validator_1.Validator.validatePresence(input.name, this.switchKeys('name', altKeys), constants_1.Constants.MISSING_NAME));
    errorDetails.push(validator_1.Validator.validateEmail(input.email, this.switchKeys('email', altKeys), constants_1.Constants.MISSING_INVALID_EMAIL));
    errorDetails.push(validator_1.Validator.validatePresence(input.job_title, this.switchKeys('job_title', altKeys), constants_1.Constants.MISSING_JOB_TITLE));
    errorDetails.push(validator_1.Validator.validatePresence(input.phone, this.switchKeys('phone', altKeys), constants_1.Constants.MISSING_PHONE));
    return errorDetails;
  }
  checkPasswordPolicy(password) {
    const schema = new PasswordValidator();
    schema.is().min(6) // Minimum length 6
    .has().not().spaces();
    return schema.validate(password);
  }
  switchKeys(key, altKeys) {
    let result = key;
    if (Object.keys(altKeys).length > 0) {
      if (altKeys.hasOwnProperty(key)) {
        result = altKeys[key];
      }
    }
    return result;
  }
}
const ValidationInstance = new Validation();
exports.Validation = ValidationInstance;