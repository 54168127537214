"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class StaffEmployers extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyValidationState(_this.getValidationDefault(identifier), identifier, subIdentifier);
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS)
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_URLSLUG]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: this.getSpinnerDefault()
        },
        availability: {
          [risesharp_common_1.Constants.IDENTIFIER_URLSLUG]: this.getAvailabilityDefault()
        },
        displaySections: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: this.getDisplaySectionsDefault()
        },
        pagination: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: this.getPaginationDefault()
        },
        s3Progress: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: {
            logo: '',
            video: ''
          }
        },
        employerUserSelection: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: new mobx_1.ObservableMap()
        },
        emailSelection: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS]: new mobx_1.ObservableMap()
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS) {
      return {
        id: '',
        business_name: '',
        business_type: 'Enterprise',
        business_description: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zipcode: '',
        po_box_no: '',
        country: '',
        website: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        contact_position: '',
        industry_type: '',
        total_employees: '',
        ats_integration: '',
        gda_integration: '',
        jobnotn_sms_new: '',
        jobnotn_sms_update: '',
        jobnotn_sms_csteam: '',
        logo_url: '',
        video_mode: '',
        video_url: '',
        external_video_url: '',
        url_slug: '',
        admin_id: '',
        admin_first_name: '',
        admin_last_name: '',
        admin_email: '',
        admin_mobile: '',
        admin_job_title: ''
      };
    }
    return {};
  }
  getValidationDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS) {
      return {
        url_slug: {
          isError: false,
          errorMessage: ''
        },
        business_name: {
          isError: false,
          errorMessage: ''
        },
        country: {
          isError: false,
          errorMessage: ''
        },
        admin_first_name: {
          isError: false,
          errorMessage: ''
        },
        admin_email: {
          isError: false,
          errorMessage: ''
        },
        admin_mobile: {
          isError: false,
          errorMessage: ''
        }
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFFEMPLOYERS) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        noDetails: true
      }, {
        staffEmployersList: risesharp_common_1.Schema.getPaginationList()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
  getDisplaySectionsDefault() {
    return {
      filters: false
    };
  }
  getPaginationDefault() {
    return risesharp_common_1.Schema.getPaginationDefault();
  }
  getAvailabilityDefault() {
    return -1;
  }
}
exports.default = StaffEmployers;