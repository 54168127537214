"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollegeManageStaff = void 0;
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
const apiMessageHelper_1 = require("./../utils/apiMessageHelper");
class CollegeManageStaff extends base_1.Base {
  constructor() {
    super(...arguments);
    this.url = '/college-manage-staff';
  }
  processGet(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      let reply;
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        let responseJson;
        const actionType = input.action_type || '';
        if (actionType === 'main_list' || actionType === 'account_profile_list') {
          reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ANDERROR), {
            collegeManageStaff: {
              main_list: risesharp_common_1.Schema.getPaginationList()
            }
          });
          responseJson = yield this.handleGetOpsInput(token, input, this.url, 'cms-get');
          if (!responseJson.success) {
            reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
          } else {
            reply.collegeManageStaff.main_list = responseJson.data.collegeManageStaff.main_list;
          }
        } else {
          reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR);
          throw new Error('invalid action type');
        }
      } catch (err) {
        console.error('CollegeManageStaffService - processGet', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  processPost(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('CollegeManageStaffService - processPost', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  processPut(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePutOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('CollegeManageStaffService - processPut', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  processDelete(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handleDeleteOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('CollegeManageStaffService - processDelete', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
}
const CollegeManageStaffInstance = new CollegeManageStaff();
exports.CollegeManageStaff = CollegeManageStaffInstance;