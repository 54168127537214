"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmployerDetails = void 0;
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
const apiMessageHelper_1 = require("./../utils/apiMessageHelper");
class EmployerDetails extends base_1.Base {
  constructor() {
    super(...arguments);
    this.url = '/employer-details';
  }
  getDetails(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), {
        employerDetails: risesharp_common_1.Schema.getFullEmployerDetails()
      });
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handleGetOpsInput(token, input, this.url, 'id');
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.employerDetails = responseJson.data.employerDetails;
        }
      } catch (err) {
        console.error('EmployerDetailsStateApiService - getDetails', err);
        this.setErrorOutput(err, reply);
      }
      return reply;
    });
  }
  getDetailsFromSlug(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_ONLYERROR), {
        employerDetails: risesharp_common_1.Schema.getFullEmployerDetails()
      });
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handleGetOpsInput(token, input, this.url, 'slug');
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.employerDetails = responseJson.data.employerDetails;
        }
      } catch (err) {
        console.error('EmployerDetailsService - getDetailsFromSlug', err);
        this.setErrorOutput(err, reply);
      }
      return reply;
    });
  }
}
const EmployerDetailsInstance = new EmployerDetails();
exports.EmployerDetails = EmployerDetailsInstance;