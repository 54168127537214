"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetExternalFile = void 0;
const axios_1 = require("axios");
class GetExternalFile {
  getPdfFile(url) {
    return __awaiter(this, void 0, void 0, function* () {
      let blob = null;
      try {
        const response = yield axios_1.default.request({
          responseType: 'arraybuffer',
          url,
          method: 'get',
          headers: {
            'Content-Type': 'application/pdf'
          },
          transformRequest: (data, headers) => {
            delete headers.common.Authorization;
            return data;
          }
        });
        blob = new Blob([response.data], {
          type: 'application/pdf'
        });
      } catch (err) {
        console.error('GetExternalFile - getPdfFile', err);
      }
      return blob;
    });
  }
}
const GetExternalFileInstance = new GetExternalFile();
exports.GetExternalFile = GetExternalFileInstance;