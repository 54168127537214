"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RestClient = void 0;
const axios_1 = require("axios");
const properties_1 = require("./../config/properties");
class RestClient {
  getInstance(token) {
    const instance = axios_1.default.create({
      baseURL: properties_1.properties.apiBaseUrl
    });
    if (token) {
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      instance.defaults.headers.common['Authorization'] = '';
    }
    // timeout after 180 secs
    instance.defaults.timeout = 180 * 1000;
    return instance;
  }
}
const RestClientInstance = new RestClient();
exports.RestClient = RestClientInstance;