"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class CompetencyAssessment extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyValidationState(_this.getValidationDefault(identifier), identifier, subIdentifier);
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        assessmentId: '',
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT]: {
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_COMMUNICATION]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_TEAMWORK]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_LEADERSHIP]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_CREATIVITY]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_PROFESSIONALISM]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIVERSITY]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_ADAPTABILITY]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIGITAL]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_SCORE]: {
              id: '',
              section_id: risesharp_common_1.General.IDENTIFIER_COMPETENCYASSMT_SCORE,
              rank: '',
              plan: ''
            }
          }
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT]: {
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_COMMUNICATION]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_TEAMWORK]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_LEADERSHIP]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_CREATIVITY]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_PROFESSIONALISM]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIVERSITY]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_ADAPTABILITY]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIGITAL]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT)
          }
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT]: {
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_COMMUNICATION]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_TEAMWORK]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_LEADERSHIP]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_CREATIVITY]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_PROFESSIONALISM]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIVERSITY]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_ADAPTABILITY]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIGITAL]: this.getSpinnerDefault(),
            [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_SCORE]: this.getSpinnerDefault()
          }
        },
        displaySections: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT]: this.getDisplaySectionsDefault()
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT) {
      const obj = {
        id: '',
        section_id: '',
        rank: '',
        plan: ''
      };
      return obj;
    }
    return {};
  }
  getValidationDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT) {
      return {
        rank: {
          isError: false,
          errorMessage: ''
        },
        plan: {
          isError: false,
          errorMessage: ''
        }
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_COMPETENCYASSESSMENT) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        competencyAssessmentDetails: risesharp_common_1.Schema.getCompetencyAssessmentDetails()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
  getDisplaySectionsDefault() {
    return {
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_COMMUNICATION]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_TEAMWORK]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_LEADERSHIP]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_CREATIVITY]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_PROFESSIONALISM]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIVERSITY]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_ADAPTABILITY]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_DIGITAL]: false,
      [risesharp_common_1.Constants.SUBIDENTIFIER_COMPETENCYASSMT_SCORE]: true
    };
  }
}
exports.default = CompetencyAssessment;