"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class StudentJobsList extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    // IMPORTANT - form, validation, spinner, displayForm set dynamically based on api list in Base
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
        },
        displaySections: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST]: this.getDisplaySectionsDefault()
        },
        pagination: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST]: this.getPaginationDefault()
        },
        filters: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST]: {
            employers: []
          }
        },
        s3Progress: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST]: new mobx_1.ObservableMap()
        },
        s3Url: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST]: new mobx_1.ObservableMap()
        },
        s3UploadDisplay: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST]: new mobx_1.ObservableMap()
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTJOBSLIST) {
      const studentJobsList = risesharp_common_1.Schema.getPaginationList();
      studentJobsList.blockAccess = false;
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        noDetails: true
      }, {
        studentJobsList
      });
    }
    return {};
  }
  getDisplaySectionsDefault() {
    return {
      filters: false
    };
  }
  getPaginationDefault() {
    return risesharp_common_1.Schema.getPaginationDefault();
  }
}
exports.default = StudentJobsList;