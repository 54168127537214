"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
/*
* IMPORTANT - does not entirely use Base functions as this store uses a complex 'section' format
*/
class EmployerJobs extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.setCustomApiState = mobx_1.action((type, newState) => {
      const api = this.state.api;
      let lastUpdateSync = false;
      const errorMessage = lodash_1.get(newState, 'errorMessage');
      if (errorMessage !== undefined) {
        api.errorMessage = errorMessage;
      }
      const successMessage = lodash_1.get(newState, 'successMessage');
      if (successMessage !== undefined) {
        api.successMessage = successMessage;
      }
      const coursesListData = lodash_1.get(newState, 'collegeCourses');
      if (coursesListData !== undefined) {
        api.data.coursesList = coursesListData;
      }
      const employerDetailsData = lodash_1.get(newState, 'employerDetails');
      if (employerDetailsData !== undefined) {
        api.data.employerDetails = employerDetailsData;
      }
      const addEmployerJobDetailsData = lodash_1.get(newState, 'addEmployerJobDetails');
      if (addEmployerJobDetailsData !== undefined) {
        api.data.addEmployerJobDetails = addEmployerJobDetailsData;
        const client = this.state.client;
        if (type.startsWith('section')) {
          const sections = client.sections;
          const section = sections[type] || {};
          const parentSectionIdentifier = lodash_1.get(addEmployerJobDetailsData, 'tmpId');
          if (parentSectionIdentifier !== undefined) {
            client.parentSectionIdentifier = parentSectionIdentifier;
          }
          const sectionId = lodash_1.get(addEmployerJobDetailsData, 'id');
          if (sectionId !== undefined) {
            section.sectionIdentifier = sectionId;
          }
          const message = lodash_1.get(addEmployerJobDetailsData, 'message');
          if (message !== undefined) {
            api.successMessage = message;
          }
          // need to sync add>lastUpdated section
          const lastUpdatedState = client.lastUpdated;
          const lastUpdatedSections = lastUpdatedState.sections || {};
          if (lastUpdatedSections.hasOwnProperty(type)) {
            const sectionData = section.sectionData || {};
            const clone = this.getClone(sectionData);
            lastUpdatedSections[type] = clone;
            // console.log('add section lastupdatedSync', type, JSON.stringify(lastUpdatedSections[type]));
            lastUpdateSync = true;
          }
        } else {
          const jobId = lodash_1.get(addEmployerJobDetailsData, 'id');
          if (jobId !== undefined) {
            client.jobId = jobId;
            client.jobStatus = 'G'; // Approval
          }
          const message = lodash_1.get(addEmployerJobDetailsData, 'message');
          if (message !== undefined) {
            api.successMessage = message;
          }
        }
      }
      const employerJobDetailsData = lodash_1.get(newState, 'employerJobDetails');
      if (employerJobDetailsData !== undefined) {
        api.data.employerJobDetails = employerJobDetailsData;
        if (type === 'get_details') {
          const client = this.state.client;
          client.jobId = employerJobDetailsData.jobId;
          client.jobStatus = employerJobDetailsData.jobStatus;
          client.employerId = employerJobDetailsData.employerId;
          client.parentSectionIdentifier = employerJobDetailsData.parentSectionIdentifier, client.draftVersionDate = employerJobDetailsData.draftVersionDate;
          client.jobUpdatedDate = employerJobDetailsData.jobUpdatedDate;
          client.sections = employerJobDetailsData.sections;
          // sync lastUpdated
          const lastUpdatedState = client.lastUpdated;
          Object.keys(client.sections).forEach(key => {
            const clone = this.getClone(client.sections[key].sectionData);
            lastUpdatedState.sections[key] = clone;
          });
          // console.log('get sections lastupdatedSync', JSON.stringify(lastUpdatedState));
          lastUpdateSync = true;
        }
      }
      // need to sync update>lastUpdated section
      if (!lastUpdateSync && type.startsWith('section')) {
        const client = this.state.client;
        const sections = client.sections;
        const section = sections[type] || {};
        const lastUpdatedState = client.lastUpdated;
        const lastUpdatedSections = lastUpdatedState.sections || {};
        if (lastUpdatedSections.hasOwnProperty(type)) {
          const sectionData = section.sectionData || {};
          const clone = this.getClone(sectionData);
          lastUpdatedSections[type] = clone;
          client.draftVersionDate = new Date().toISOString();
          client.jobUpdatedDate = '';
          // console.log('update section lastupdatedSync', type, JSON.stringify(lastUpdatedSections[type]));
        }
      }
    });
    this.setCustomValidationState = mobx_1.action((newState, type) => {
      const defaultState = this.getDefaultState();
      const validation = defaultState.client.validation;
      const sections = validation.sections;
      const section = sections[type] || {};
      const keys = Object.keys(section);
      if (keys.length > 0) {
        for (const key of keys) {
          if (newState.hasOwnProperty(key)) {
            section[key] = {
              isError: true,
              errorMessage: newState[key]
            };
          }
        }
        this.state.client.validation.sections[type] = section;
      }
    });
    this.setCustomDisplaySectionState = mobx_1.action((type, value) => {
      const sections = this.state.client.displaySections.sections;
      const keys = Object.keys(sections);
      if (keys.length > 0) {
        for (const key of keys) {
          sections[key] = false;
        }
        if (sections.hasOwnProperty(type)) {
          sections[type] = value;
        }
      }
    });
    this.setCustomSpinnerState = mobx_1.action((type, value) => {
      const sections = this.state.client.displaySpinner.sections;
      const keys = Object.keys(sections);
      if (keys.length > 0) {
        for (const key of keys) {
          sections[key] = false;
        }
        if (sections.hasOwnProperty(type)) {
          sections[type] = value;
        }
      }
    });
    this.resetCustomApiState = mobx_1.action(() => {
      const api = this.state.api;
      api.errorMessage = '';
      api.successMessage = '';
    });
    this.resetCustomValidationState = mobx_1.action(type => {
      const defaultState = this.getDefaultState();
      const validation = defaultState.client.validation;
      const sections = validation.sections;
      if (sections.hasOwnProperty(type)) {
        this.state.client.validation.sections[type] = sections[type];
      }
    });
    this.handleSectionUpdates = mobx_1.action((type, eventName, eventValue) => {
      const client = this.state.client;
      const sections = client.sections;
      const section = sections[type] || {};
      const sectionData = section.sectionData || {};
      if (sectionData.hasOwnProperty(eventName)) {
        sectionData[eventName] = eventValue;
      }
    });
    this.setEditSection = mobx_1.action((type, value, backToPreview) => {
      const client = this.state.client;
      const sections = client.editSections.sections;
      const keys = Object.keys(sections);
      if (keys.length > 0) {
        for (const key of keys) {
          sections[key] = false;
        }
        if (sections.hasOwnProperty(type)) {
          sections[type] = value;
          this.setCustomDisplaySectionState(type, value);
          if (!value && backToPreview) {
            this.setCustomDisplaySectionState('preview', true);
          }
        }
      }
    });
    this.resetState();
  }
  setCustomGenericError() {
    const api = this.state.api;
    api.errorMessage = risesharp_common_1.Constants.GENERIC_ERROR;
  }
  backToPreviewLink(type) {
    const client = this.state.client;
    const sections = client.editSections.sections;
    if (sections.hasOwnProperty(type)) {
      const flag = sections[type];
      if (flag) {
        return true;
      }
    }
    return false;
  }
  isModificationDone(type) {
    let modnDone = false;
    const defaultState = this.getDefaultState();
    const defaultClient = defaultState.client || {};
    const defaultSections = defaultClient.sections || {};
    const api = this.state.api;
    const apiData = api.data || {};
    const employerJobDetails = apiData.employerJobDetails || {};
    const ejdSections = employerJobDetails.sections || {};
    const client = this.state.client;
    const jobId = client.jobId || '';
    const sections = client.sections || {};
    const lastUpdatedState = client.lastUpdated;
    const lastUpdatedSections = lastUpdatedState.sections || {};
    if (type !== 'all' && type !== 'cancel') {
      const defaultSection = defaultSections[type] || {};
      const defaultSectionData = defaultSection.sectionData || {};
      const ejdSection = ejdSections[type] || {};
      const ejdSectionData = ejdSection.sectionData || {};
      const section = sections[type] || {};
      const sectionData = section.sectionData || {};
      const lastUpdatedSectionData = lastUpdatedSections[type] || {};
      if (!jobId) {
        const sectionIdentifier = section.sectionIdentifier || '';
        if (!sectionIdentifier) {
          modnDone = true;
          // console.log(`${type} no jobid no section id`, modnDone);
        } else {
          if (Object.keys(lastUpdatedSectionData).length > 0) {
            const isEqualFlag = lodash_1.isEqual(mobx_1.toJS(lastUpdatedSectionData), mobx_1.toJS(sectionData));
            if (!isEqualFlag) {
              modnDone = true;
              // console.log(`${type} no jobid with local updates`, modnDone);
            }
          } else {
            const isEqualFlag = lodash_1.isEqual(mobx_1.toJS(defaultSectionData), mobx_1.toJS(sectionData));
            if (!isEqualFlag) {
              modnDone = true;
              // console.log(`${type} no jobid with no local updates`, modnDone);
            }
          }
        }
      } else {
        if (Object.keys(lastUpdatedSectionData).length > 0) {
          const isEqualFlag = lodash_1.isEqual(mobx_1.toJS(lastUpdatedSectionData), mobx_1.toJS(sectionData));
          if (!isEqualFlag) {
            modnDone = true;
            // console.log(`${type} jobid with local updates`, modnDone);
          }
        } else {
          const isEqualFlag = lodash_1.isEqual(mobx_1.toJS(ejdSectionData), mobx_1.toJS(sectionData));
          if (!isEqualFlag) {
            modnDone = true;
            // console.log(`${type} jobid with no local updates`, modnDone);
          }
        }
      }
    } else {
      let tmpModnDone = false;
      const draftVersionDate = client.draftVersionDate || '';
      if (draftVersionDate) {
        tmpModnDone = true;
        // console.log('draft version date avl', tmpModnDone);
      } else {
        // TO DO - is this section needed after introducing draftVersionDate comparison?
        // Need a clone since isEqual fails with mobx observables?
        const allSectionsData = {};
        Object.keys(sections).forEach(key => {
          const section = sections[key];
          const sectionData = section.sectionData || {};
          allSectionsData[key] = this.getClone(sectionData);
        });
        const defaultSectionsData = {};
        Object.keys(defaultSections).forEach(key => {
          const section = defaultSections[key];
          const sectionData = section.sectionData || {};
          defaultSectionsData[key] = this.getClone(sectionData);
        });
        const ejdSectionsData = {};
        Object.keys(ejdSections).forEach(key => {
          const section = ejdSections[key];
          const sectionData = section.sectionData || {};
          ejdSectionsData[key] = this.getClone(sectionData);
        });
        if (!jobId) {
          const isEqualFlag = lodash_1.isEqual(mobx_1.toJS(defaultSectionsData), mobx_1.toJS(allSectionsData));
          if (!isEqualFlag) {
            tmpModnDone = true;
            // console.log('all/cancel default no jobid', tmpModnDone);
          }
        } else {
          const isEqualFlag = lodash_1.isEqual(mobx_1.toJS(ejdSectionsData), mobx_1.toJS(allSectionsData));
          if (!isEqualFlag) {
            tmpModnDone = true;
            // console.log('all/cancel default jobid', tmpModnDone);
          }
        }
      }
      if (type === 'cancel' && tmpModnDone) {
        modnDone = true;
        // console.log('cancel', tmpModnDone);
      }
      if (type === 'all' && tmpModnDone) {
        const sectionIdentifier1 = lodash_1.get(client, 'sections.section1.sectionIdentifier', '');
        const sectionIdentifier2 = lodash_1.get(client, 'sections.section2.sectionIdentifier', '');
        const sectionIdentifier3 = lodash_1.get(client, 'sections.section3.sectionIdentifier', '');
        // const sectionIdentifier4 = get(client, 'sections.section4.sectionIdentifier', '');
        const sectionIdentifier5 = lodash_1.get(client, 'sections.section5.sectionIdentifier', '');
        const sectionIdentifier6 = lodash_1.get(client, 'sections.section6.sectionIdentifier', '');
        const parentSectionIdentifier = lodash_1.get(client, 'parentSectionIdentifier', '');
        if (parentSectionIdentifier !== '' && sectionIdentifier1 !== '' && sectionIdentifier2 !== '' && sectionIdentifier3 !== '' && sectionIdentifier5 !== '' && sectionIdentifier6 !== '') {
          modnDone = true;
          // console.log('all', modnDone);
        }
      }
    }
    return modnDone;
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, this.getClone(this.getDefaultState()))
    });
  }
  getDefaultState() {
    return {
      client: {
        jobId: '',
        jobStatus: '',
        employerId: '',
        parentSectionIdentifier: '',
        draftVersionDate: '',
        jobUpdatedDate: '',
        sections: {
          section1: {
            sectionIdentifier: '',
            sectionLabel: 'Section - 1',
            sectionData: {
              directjob_employer_name: '',
              job_title: '',
              job_function: '',
              job_type: '',
              no_of_openings: 1,
              job_location: [],
              ctc: '',
              min_ctc_band: '',
              max_ctc_band: '',
              ctc_band_frequency: '',
              ctc_band_currency: '',
              apply_latest_by: '',
              interview_date: '' // not in use
            }
          },
          section2: {
            sectionIdentifier: '',
            sectionLabel: 'Section - 2',
            sectionData: {
              responsibilities: '',
              qualification_skills: '',
              additional_info: ''
            }
          },
          section3: {
            sectionIdentifier: '',
            sectionLabel: 'Section - 3',
            sectionData: {
              degree_program: [],
              degree_specialization: [],
              academic_years: []
            }
          },
          // screening (future)
          section4: {
            sectionIdentifier: '',
            sectionLabel: 'Section - 4',
            sectionData: {}
          },
          section5: {
            sectionIdentifier: '',
            sectionLabel: 'Section - 5',
            sectionData: {
              recruiting_contact: '',
              contact_email: '',
              contact_phone: '',
              address_line_1: '',
              address_line_2: '',
              city: '',
              state: '',
              zipcode: '',
              po_box_no: '',
              country: '',
              website: ''
            }
          },
          section6: {
            sectionIdentifier: '',
            sectionLabel: 'Section - 6',
            sectionData: {
              apply_external: '',
              apply_ext_url: '',
              apply_ext_notes: '',
              allow_system_assignment: 'N',
              update_notification: ''
            }
          }
        },
        displaySections: {
          sections: {
            section1: true,
            section2: false,
            section3: false,
            section4: false,
            section5: false,
            section6: false,
            preview: false
          }
        },
        displaySpinner: {
          sections: {
            section1: false,
            section2: false,
            section3: false,
            section4: false,
            section5: false,
            section6: false,
            preview: false
          }
        },
        validation: {
          sections: {
            section1: {
              job_type: {
                isError: false,
                errorMessage: ''
              },
              job_title: {
                isError: false,
                errorMessage: ''
              },
              job_function: {
                isError: false,
                errorMessage: ''
              },
              ctc: {
                isError: false,
                errorMessage: ''
              }
            },
            section2: {},
            section3: {
              degree_program: {
                isError: false,
                errorMessage: ''
              },
              academic_years: {
                isError: false,
                errorMessage: ''
              }
            },
            section4: {},
            section5: {},
            section6: {
              apply_ext_url: {
                isError: false,
                errorMessage: ''
              }
            }
          }
        },
        editSections: {
          sections: {
            section1: false,
            section2: false,
            section3: false,
            section4: false,
            section5: false,
            section6: false
          }
        },
        lastUpdated: {
          sections: {
            section1: {},
            section2: {},
            section3: {},
            section4: {},
            section5: {},
            section6: {}
          }
        }
      },
      api: {
        errorMessage: '',
        successMessage: '',
        data: {
          coursesList: [],
          employerDetails: {},
          addEmployerJobDetails: {},
          employerJobDetails: {}
        }
      },
      spinner: {
        [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
      }
    };
  }
  getClone(input) {
    const output = JSON.parse(JSON.stringify(input));
    return output;
  }
}
exports.default = EmployerJobs;