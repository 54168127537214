"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollegeDirectJobs = void 0;
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
const apiMessageHelper_1 = require("./../utils/apiMessageHelper");
class CollegeDirectJobs extends base_1.Base {
  constructor() {
    super(...arguments);
    this.url = '/college-direct-jobs';
  }
  getDetails(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ANDERROR), {
        collegeDirectJobDetails: risesharp_common_1.Schema.getCollegeDirectJobDetails()
      });
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handleGetOpsInput(token, input, this.url, 'jau-get');
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.collegeDirectJobDetails = responseJson.data.collegeDirectJobDetails;
        }
      } catch (err) {
        console.error('CollegeDirectJobsService - getDetails', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  addJob(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ANDERROR), {
        addCollegeDirectJobDetails: risesharp_common_1.Schema.getAddCollegeDirectJobDetails()
      });
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePostOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          // convert successMessage to strip LBR
          const cleanData = this.cleanupMessage(responseJson.data, 'addCollegeDirectJobDetails', isMobileApp);
          reply.addCollegeDirectJobDetails = cleanData.addCollegeDirectJobDetails;
        }
      } catch (err) {
        console.error('CollegeDirectJobsService - addJob', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  updateJob(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handlePutOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('CollegeDirectJobsService - updateJob', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
  deleteJob(serviceInput) {
    return __awaiter(this, void 0, void 0, function* () {
      const reply = risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS);
      try {
        const [token, isMobileApp, input] = this.getServiceInputDetails(serviceInput);
        const responseJson = yield this.handleDeleteOpsInput(token, input, this.url);
        if (!responseJson.success) {
          reply.errorMessage = apiMessageHelper_1.ApiMessageHelper.getError(responseJson.error, isMobileApp);
        } else {
          reply.successMessage = apiMessageHelper_1.ApiMessageHelper.getMessage(responseJson.data.message, isMobileApp);
        }
      } catch (err) {
        console.error('CollegeDirectJobsService - deleteJob', err);
        this.setErrorOutput(err, reply, true);
      }
      return reply;
    });
  }
}
const CollegeDirectJobsInstance = new CollegeDirectJobs();
exports.CollegeDirectJobs = CollegeDirectJobsInstance;