import * as React from 'react';
import { CommonTS, Constants, Validation, Schema, General as GeneralHelper } from 'risesharp-common';
import { MultiSelect } from 'risesharp-ui';

import { General } from './general';
import { Helper } from './../utils/helper';

export abstract class Base extends General {
  private store: any;

  constructor(props: React.Props<any>) {
    super(props);
  }

  /*  #####store##### */
  initStore(store: any) {
    this.store = store;
  }

  getStore() {
    return this.store;
  }

  getState() {
    return this.getStore().state;
  }
  /*  #####store##### */

  /*  #####events##### */
  handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, identifier: string, subIdentifier: string = '') => {
    const eventName = event.target.name;
    const eventValue = event.target.value;
    this.getStore().setFormState(eventName, eventValue, identifier, subIdentifier);
  }

  handleFormKeyPressEvent = (event: React.KeyboardEvent<HTMLInputElement>, identifier: string, subIdentifier: string, callback: (identifier: string, subIdentifier: string) => void) => {
    if (event.charCode === 13) {
      event.preventDefault();
      callback(identifier, subIdentifier);
    }
  }

  handleFormSubmitEvent = (event: React.MouseEvent<any>, identifier: string, subIdentifier: string, callback: (identifier: string, subIdentifier: string) => void) => {
    event.preventDefault();
    callback(identifier, subIdentifier);
  }

  handleCkEditorEvent = (name: string, value: any, identifier: string, subIdentifier: string = '') => {
    this.getStore().setFormState(name, value, identifier, subIdentifier);
  }

  getCardHeaderEvent(displaySection: string, identifier: string, subIdentifier: string = '') {
    let value = false;
    if (subIdentifier) {
      value = !this.getState().displaySections[identifier][subIdentifier][displaySection];
    } else {
      value = !this.getState().displaySections[identifier][displaySection];
    }
    this.getStore().setDisplaySectionState(displaySection, value, identifier, subIdentifier);
    if (value) {
      this.focusElement('card_' + displaySection);
    }
  }
  /*  #####events##### */

  /*  #####apiMessages##### */
  setGenericError(identifier: string) {
    this.getState().api[identifier].errorMessage = Constants.GENERIC_ERROR;
  }

  clearApiMessages(isError: boolean, isSuccess: boolean, identifier: string, subIdentifier: string = '') {
    let apiObj;
    if (subIdentifier) {
      apiObj = this.getState().api[identifier][subIdentifier];
    } else {
      apiObj = this.getState().api[identifier];
    }

    if (apiObj) {
      if (isError) {
        if (apiObj.hasOwnProperty('errorMessage')) {
          apiObj.errorMessage = '';
        }
      }
      if (isSuccess) {
        if (apiObj.hasOwnProperty('successMessage')) {
          apiObj.successMessage = '';
        }
      }
    }
  }

  checkErrorMessage(identifier: string, subIdentifier: string = '') {
    let errorMessage = '';
    if (subIdentifier) {
      errorMessage = this.getState().api[identifier][subIdentifier].errorMessage;
    } else {
      errorMessage = this.getState().api[identifier].errorMessage;
    }
    if (errorMessage) {
      if (identifier === Constants.IDENTIFIER_SIGNIN) {
        if (errorMessage === Constants.SIGNIN_PASSWORD_ERROR) {
          return (
            <p className="text-danger">
              Incorrect password entered. Enter the correct password and try again. If you forgot your password,
              <span className="mx-2">
                <a 
                  role="button" 
                  className="buttonAsLink" 
                  onClick={() => this.props.history.push('/forgot-password')}
                >
                  click here
                </a>
              </span>
              to reset
            </p>
          );
        }
        if (errorMessage === Constants.SIGNIN_VERIFIED_ERROR) {
          return (
            <p className="text-danger">
              Your account has not been verified as yet.
              <span className="mx-2">
                <a 
                  role="button" 
                  className="buttonAsLink" 
                  onClick={() => this.props.history.push('/forgot-password')}
                >
                  Click here
                </a>
              </span>
              to activate your account and reset your password
            </p>
          );
        }
      }
      return (
        <p id="apiErrorMessage" className="text-danger" dangerouslySetInnerHTML={{ __html: errorMessage }}/>
      );
    }
    return null;
  }

  checkSuccessMessage(identifier: string, subIdentifier: string = '') {
    switch (identifier) {
      case Constants.IDENTIFIER_CHANGEPASSWORD:
        return this.handleChangePasswordSuccessMessage(identifier);
      case Constants.IDENTIFIER_FORGOTPASSWORD:
        return this.handleForgotPasswordSuccessMessage(identifier);
      case Constants.IDENTIFIER_UPDATEPASSWORD:
        return this.handleUpdatePasswordSuccessMessage(identifier);
      case Constants.IDENTIFIER_STAFFCOLLEGES:
        return this.handleStaffCollegeSuccessMessage(identifier);
      case Constants.IDENTIFIER_STAFFEMPLOYERS:
        return this.handleStaffEmployerSuccessMessage(identifier);
      case Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS:
        return this.handleEnrollmentSuccessMessage(identifier);
      case Constants.IDENTIFIER_EVENTS_WORKSHOPS:
        return this.handleEVWSSuccessMessage(identifier);
      default:
        return this.handleDefaultSuccessMessage(identifier, subIdentifier);
    }
  }

  private handleDefaultSuccessMessage(identifier: string, subIdentifier: string = '') {
    let successMessage = '';
    if (subIdentifier) {
      successMessage = this.getState().api[identifier][subIdentifier].successMessage;
    } else {
      successMessage = this.getState().api[identifier].successMessage;
    }

    if (successMessage) {
      return (
        <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: successMessage }}/>
      );
    }
    return null;
  }

  private handleChangePasswordSuccessMessage(identifier: string) {
    if (this.getState().api[identifier].successMessage) {
      const successMessage = this.getState().api[identifier].successMessage;
      if (successMessage === Constants.CHANGE_PASSWORD_SUCCESS) {
        // tslint:disable:align
        setTimeout(() => {
          this.props.history.push('/signin');
        }, 2000);
        return (
          <React.Fragment>
            <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: successMessage }}/>
            <p className="text-info">Redirecting to Login page...</p>
          </React.Fragment>
        );
      }
    }
    return null;
  }

  private handleForgotPasswordSuccessMessage(identifier: string) {
    if (this.getState().api[identifier].forgotPasswordDetails.message) {
      return (
        <React.Fragment>
          <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: this.getState().api[identifier].forgotPasswordDetails.message }}/>
          <p className="text-warning">
            Please click on the link below to change your password.
          </p>
          <p>
            <a 
              role="button" 
              className="buttonAsLink" 
              onClick={() => this.props.history.push({
                pathname: '/change-password',
                state: {
                  resend_otp_token: this.getState().api[identifier].forgotPasswordDetails.resend_otp_token
                }
              })}
            >
              Change Password
            </a>
          </p>
        </React.Fragment>
      );
    }
    return null;
  }

  private handleUpdatePasswordSuccessMessage(identifier: string) {
    if (this.getState().api[identifier].successMessage) {
      // tslint:disable:align
      setTimeout(() => {
        // since password is modified, signout user
        Helper.clearAllAndSignout(this.props, false, { updatedPassword: true });
      }, 2000);
      return (
        <React.Fragment>
          <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: this.getState().api[identifier].successMessage }}/>
          <p className="text-info">Redirecting to Login page...</p>
        </React.Fragment>
      );
    }
    return null;
  }

  private handleStaffCollegeSuccessMessage(identifier: string) {
    const successMessage = this.getState().api[identifier].successMessage;
    if (successMessage) {
      if (
        successMessage !== Constants.CONTACTUS_SUCCESS &&
        (successMessage !== Constants.STAFFCOLL_BULKEMAIL_SUCCESS && 
        successMessage.indexOf('download the file') === -1) &&
        successMessage !== Constants.DELETE_MEDIAFILE_SUCCESS
      ) {
        let redirectFlag = false;
        const redirectQP = Helper.getQueryParamValue(window.location.href, 'rf');
        if (redirectQP) {
          redirectFlag = true;
        }
        if (redirectFlag) {
          return (
            <h3 
              id="apiSuccessMessage" 
              className="text-success" 
            >
              <span>College details updated successfully. </span>
              <a 
                role="button" 
                className="buttonAsLink boldLink" 
                onClick={() => {
                  this.clearApiMessages(true, true, identifier); 
                  this.props.history.goBack();
                }}
              >
                Back to List
              </a>
            </h3>
          );
        }
        if (!redirectFlag) {
          let url = '/staff/colleges-list';
          if (Helper.getAuthorizedUserType(this.props) === Constants.COLLEGES_USER_TYPE) {
            url = '/colleges/dashboard';
          }
          // tslint:disable:align
          setTimeout(() => {
            this.props.history.push(url);
          }, 2000);
          return (
            <React.Fragment>
              <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: this.getState().api[identifier].successMessage }}/>
              <p className="text-info">Redirecting...</p>
            </React.Fragment>
          );
        }
      }
      return this.handleDefaultSuccessMessage(identifier);
    }
    return null;
  }

  private handleStaffEmployerSuccessMessage(identifier: string) {
    const successMessage = this.getState().api[identifier].successMessage;
    if (successMessage) {
      if (successMessage !== Constants.DELETE_MEDIAFILE_SUCCESS && successMessage !== Constants.CONTACTUS_SUCCESS) {
        let url = '/staff/employers-list';
        if (Helper.getAuthorizedUserType(this.props) === Constants.EMPLOYERS_USER_TYPE) {
          url = '/employers/dashboard';
        }
        // tslint:disable:align
        setTimeout(() => {
          this.props.history.push(url);
        }, 2000);
        return (
          <React.Fragment>
            <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: this.getState().api[identifier].successMessage }}/>
            <p className="text-info">Redirecting...</p>
          </React.Fragment>
        );
      }
      return (
        <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: this.getState().api[identifier].successMessage }}/>
      );
    }
    return null;
  }

  private handleEnrollmentSuccessMessage(identifier: string) {
    if (this.getState().api[identifier].successMessage) {
      const successMessage = this.getState().api[identifier].successMessage;
      if (successMessage === Constants.COLLEGEENMTDETAILS_ADD_SUCCESS || successMessage === Constants.COLLEGEENMTDETAILS_UPDATE_SUCCESS) {
        // tslint:disable:align
        setTimeout(() => {
          this.props.history.push('/colleges/enrollment-list');
        }, 2000);
        return (
          <React.Fragment>
            <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: successMessage }}/>
            <p className="text-info">Redirecting to List page...</p>
          </React.Fragment>
        );
      }
    }
    return null;
  }

  private handleEVWSSuccessMessage(identifier: string) {
    const successMessage = this.getState().api[identifier].successMessage; 
    if (successMessage) {
      if (successMessage !== Constants.DELETE_EVTWKSHP_SUCCESS) {
        // tslint:disable:align
        setTimeout(() => {
          this.props.history.push('/events-workshops-list');
        }, 2000);
        return (
          <React.Fragment>
            <p id="apiSuccessMessage" className="text-success" dangerouslySetInnerHTML={{ __html: successMessage }}/>
            <p className="text-info">Redirecting to Events List page...</p>
          </React.Fragment>
        );
      }
      return this.handleDefaultSuccessMessage(identifier);
    }
    return null;
  }
  /*  #####apiMessages##### */

  /*  #####validation##### */
  validateForm(identifier: string, subIdentifier: string = '') {
    let formOK = false;

    let form = {} as any;
    if (subIdentifier) {
      form = this.getState().form[identifier][subIdentifier];
    } else {
      form = this.getState().form[identifier];
    }

    let errorDetails: Array<object | undefined> = [];
    switch (identifier) {
      case Constants.IDENTIFIER_CONTACTUS:
      case Constants.IDENTIFIER_CONTACT_CAREERSERVICES:
        errorDetails = Validation.checkContactUsInput(form);
        break;
      case Constants.IDENTIFIER_ACCOUNTPROFILE:
        errorDetails = Validation.checkUpdateAccountProfileInput(form);
        break;
      case Constants.IDENTIFIER_FORGOTPASSWORD:
        errorDetails = Validation.checkForgotPasswordInput(form);
        break;
      case Constants.IDENTIFIER_CHANGEPASSWORD:
        errorDetails = Validation.checkChangePasswordInput(form);
        break;
      case Constants.IDENTIFIER_SIGNIN:
        errorDetails = Validation.checkSigninInput(form);
        break;
      case Constants.IDENTIFIER_UPDATEPASSWORD:
        errorDetails = Validation.checkUpdatePasswordInput(form);
        break;
      case Constants.IDENTIFIER_STAFFEMPLOYERS:
        if (form.id) {
          errorDetails = Validation.checkUpdateEmployerInput(form);
        } else {
          errorDetails = Validation.checkAddEmployerInput(form);
        }
        break;
      case Constants.IDENTIFIER_STAFFCOLLEGES:
        if (form.id) {
          errorDetails = Validation.checkUpdateCollegeInput(form);
        } else {
          errorDetails = Validation.checkAddCollegeInput(form);
        }
        break;
      case Constants.IDENTIFIER_SIGNUP:
        errorDetails = Validation.checkSignupInput(form);
        break;
      case Constants.IDENTIFIER_COLLEGECONTACTUS:
        errorDetails = Validation.checkCollegeContactUsInput(form);
        break;
      case Constants.IDENTIFIER_EMPLOYERCONTACTUS:
        errorDetails = Validation.checkEmployerContactUsInput(form);
        break;
      case Constants.IDENTIFIER_PERSONALITYASSESSMENT:
        errorDetails = Validation.checkPersonalityAssessmentInput(form);
        break;
      case Constants.IDENTIFIER_INTERESTSASSESSMENT:
        errorDetails = Validation.checkInterestsAssessmentInput(form);
        break;
      case Constants.IDENTIFIER_VALUESASSESSMENT:
        errorDetails = Validation.checkValuesAssessmentInput(form);
        break;
      case Constants.IDENTIFIER_COMPETENCYASSESSMENT:
        errorDetails = Validation.checkCompetencyAssessmentInput(form);
        break;
      case Constants.IDENTIFIER_EMPLOYERJOBS:
        errorDetails = Validation.checkEmployerJobInput(form);
        break;
      /* 
      case Constants.IDENTIFIER_STUDENTJOBSLIST:
        errorDetails = Validation.checkApplyJobInput(form, []);
        break;
      case Constants.IDENTIFIER_JOBDETAILS:
        errorDetails = Validation.checkApplyJobInput(form, []);
        break; 
      */
      case Constants.IDENTIFIER_STUDENTJOBSEARCHSTATUS:
        errorDetails = Validation.checkStudentJobSrchStatusInput(form);
        break;
      case Constants.IDENTIFIER_COLLEGEIMPORTSTUDENTS:
        errorDetails = Validation.checkStudentImportInput(form);
        break;
      case Constants.IDENTIFIER_COLLEGEDIRECTJOBS:
        errorDetails = Validation.checkEmployerJobInput(form);
        break;
      case Constants.IDENTIFIER_STAFF_NOTIFYSTUDENTS:
        errorDetails = Validation.checkStaffNotifyStudents(form);
        break;
      case Constants.IDENTIFIER_COLLEGEINVITEEMPLOYERS:
        errorDetails = Validation.checkEmployerInvitationInput(form);
        break;
      case Constants.IDENTIFIER_STAFF_NOTIFYTPOS:
        errorDetails = Validation.checkStaffNotifyTpos(form);
        break;
      case Constants.IDENTIFIER_EMPLOYERSIGNUP:
        errorDetails = Validation.checkEmployerSignupInput(form);
        break;
      case Constants.IDENTIFIER_EVENTS_WORKSHOPS:
        errorDetails = Validation.checkEventsWorkshopsInput(form);
        break;
      case Constants.IDENTIFIER_APPLYJOB:
        const jobLoc = this.getState().jobLocation[identifier] || [];
        errorDetails = Validation.checkApplyJobInput(form, jobLoc);
        break;
      case Constants.IDENTIFIER_CLAIMCOLLEGE:
        errorDetails = Validation.checkCollegeClaimInput(form);
        break;
      default:
        errorDetails = [];
    }
    // console.log('validation error', errorDetails, JSON.stringify(form));
    const errorMessages: {} = Validation.getErrors(errorDetails);
    if (Object.keys(errorMessages).length === 0) {
      this.resetValidationState(identifier, subIdentifier);
      this.resetApiState(identifier, subIdentifier);
      formOK = true;
    } else {
      this.resetApiState(identifier, subIdentifier);
      this.showError(errorMessages, identifier, subIdentifier);
    }

    return formOK;
  }

  resetValidationState(identifier: string, subIdentifier: string = '') {
    this.getStore().resetValidationState(identifier, subIdentifier);
  }

  resetApiState(identifier: string, subIdentifier: string = '') {
    this.getStore().resetApiState(identifier, subIdentifier);
  }

  showEOM(key: string, id: string, existingClassNames: string, identifier: string, subIdentifier: string = '') {
    let validation = {};
    if (subIdentifier) {
      validation = this.getState().validation[identifier][subIdentifier];
    } else {
      validation = this.getState().validation[identifier];
    }
    return Helper.handleOutlineAndMessage(validation, key, id, existingClassNames);
  }

  showError(errorMessages: {}, identifier: string, subIdentifier: string = '') {
    let validation = {};
    if (subIdentifier) {
      validation = this.getState().validation[identifier][subIdentifier];
    } else {
      validation = this.getState().validation[identifier];
    }
    const modState = Helper.handleValidationState(validation, errorMessages);
    this.getStore().setValidationState(modState, identifier, subIdentifier);
    const topElement = Object.keys(modState)[0];
    if (document.getElementById(topElement)) {
      this.focusElement(topElement);
    } else {
      this.displayTopLevelMessages();
    }
  }
  /*  #####validation##### */

  /*  #####s3Upload##### */
  onS3Error = (message: string, identifier: string, subIdentifier: string = '') => {
    this.getState().api[identifier].errorMessage = message;
    this.displayTopLevelMessages();
  }

  onS3Finish = (signResult: {}, key: string, identifier: string, subIdentifier: string = '') => {
    // @ts-ignore
    const [publicUrl, signature] = (signResult as any).signedUrl.split('?') as any;
    if (subIdentifier) {
      this.getState().form[identifier][subIdentifier][key] = decodeURIComponent(publicUrl);
    } else {
      this.getState().form[identifier][key] = decodeURIComponent(publicUrl);
    }
  }

  onS3Progress = (percent: number, message: string, identifier: string, subIdentifier: string = '') => {
    const s3Progress = 'Upload status: ' + percent + '% ' + message;
    if (subIdentifier) {
      this.getState().s3Progress[identifier][subIdentifier] = s3Progress; 
    } else {
      this.getState().s3Progress[identifier] = s3Progress; 
    }
  }

  checkS3DelUrl = (displayUrl: string = '') => {
    // @ts-ignore
    const [baseUrl, signature] = displayUrl.split('?') as any;
    return decodeURIComponent(baseUrl);
  }
  /*  #####s3Upload##### */

  /*  #####filters##### */
  // based on https://stackoverflow.com/a/47513369
  mergeStoreToHistoryState(identifier: string) {
    const filters = this.getState().pagination[identifier];
    const keys = Object.keys(filters);
    if (keys.length > 0) {
      const history = this.props.history;
      const state = { ...history.location.state };
      const finalFilters = MultiSelect.getValue(filters);
      if (finalFilters.hasOwnProperty('prevPaginationAndFilter')) {
        delete finalFilters.prevPaginationAndFilter;
      }

      let mergeRequired = true;
      if (state && state.pagination) {
        const hstState = JSON.stringify(state.pagination);
        const fltState = JSON.stringify(finalFilters);
        const checkState = (hstState === fltState);
        if (checkState) {
          // console.log('same state no merge needed');
          mergeRequired = false;
        }
      }

      if (mergeRequired) {
        state.pagination = finalFilters;
        history.replace({ ...history.location, state });
        // console.log('history replace', history);
      }
    }
  }

  mergeHistoryStateToStore(identifier: string) {
    const history = this.props.history;
    const state = history.location.state;
    const filters = this.getState().pagination[identifier];
    if (filters !== undefined && state !== undefined && state.hasOwnProperty('pagination')) {
      const hstrPagination = state.pagination;
      const keys = Object.keys(hstrPagination);
      if (keys.length > 0) {
        for (const key of keys) {
          filters[key] = hstrPagination[key];
        }
      }
      // console.log('store pagination after merge', JSON.stringify(filters));
    }
  }

  checkPrevPaginationAndFilter(identifier: string) {
    const pagination = this.getState().pagination[identifier];
    const clone = Object.assign({}, pagination);
    delete clone.prevPaginationAndFilter;
    const chkStr1: string = JSON.stringify(clone);
    const chkStr2: string = pagination.prevPaginationAndFilter;
    if (chkStr1 === chkStr2) {
      return false;
    }
    return true;
  }

  resetFilterAndPagination(identifier: string) {
    const pagination = this.getState().pagination[identifier];
    const resetStr = pagination.prevPaginationAndFilter || '';
    if (resetStr) {
      const resetValue = JSON.parse(resetStr);
      const clone = Object.assign({}, resetValue, { prevPaginationAndFilter: resetStr });
      this.getState().pagination[identifier] = clone;
    }
  }

  adjustFilterAndPagination(pagination: CommonTS.PaginationAndFilter, multiSelect: string[] = []) {
    // track prev values
    const clone = Object.assign({}, pagination);
    delete clone.prevPaginationAndFilter;
    pagination.prevPaginationAndFilter = JSON.stringify(clone);
    
    // remove label for api calls
    const newPagination = Object.assign({}, pagination);
    if (multiSelect.indexOf('dp') !== -1) {
      const newObj = [];
      const tmpObj = pagination.dp!;
      for (const x of tmpObj) {
        newObj.push((x as any).value); // remove label
      }
      newPagination.dp = newObj;
    }
    if (multiSelect.indexOf('ds') !== -1) {
      const newObj = [];
      const tmpObj = pagination.ds!;
      for (const x of tmpObj) {
        if (typeof x === 'string') {
          newObj.push(x);
        } else {
          newObj.push((x as any).value); // remove label
        }
      }
      newPagination.ds = newObj;
    } 
    if (multiSelect.indexOf('ss') !== -1) {
      const newObj = [];
      const tmpObj = pagination.ss!;
      for (const x of tmpObj) {
        newObj.push((x as any).value); // remove label
      }
      newPagination.ss = newObj;
    }
    if (multiSelect.indexOf('gy_single') !== -1) {
      let newValue = '';
      if (typeof pagination.gy! === 'string' && pagination.gy!) {
        newPagination.gy = pagination.gy!;
      } else {
        if (pagination.gy!.length === 1) {
          newValue = (pagination.gy![0] as any).value;
        }
      }
      newPagination.gy = newValue;
    }    
    if (multiSelect.indexOf('jrd') !== -1) {
      const newObj = [];
      const tmpObj = pagination.jrd!;
      for (const x of tmpObj) {
        newObj.push((x as any).value); // remove label
      }
      newPagination.jrd = newObj;
    }
    if (multiSelect.indexOf('esk') !== -1) {
      const newObj = [];
      const tmpObj = pagination.esk!;
      for (const x of tmpObj) {
        newObj.push((x as any).value); // remove label
      }
      newPagination.esk = newObj;
    }
    if (multiSelect.indexOf('st') !== -1) {
      const newObj = [];
      const tmpObj = pagination.st!;
      for (const x of tmpObj) {
        newObj.push((x as any).value); // remove label
      }
      newPagination.st = newObj;
    }
    if (multiSelect.indexOf('sp_ab_di') !== -1) {
      const newObj = [];
      const tmpObj = pagination.sp_ab_di!;
      for (const x of tmpObj) {
        newObj.push((x as any).value); // remove label
      }
      newPagination.sp_ab_di = newObj;
    }

    return newPagination;
  }

  showFilterStatus(identifier: string, subIdentifier: string, callback: (identifier: string, subIdentifier: string) => void) {
    let filters = {} as any;
    const multiSelect: string[] = [];

    const resetFilters = (key: string) => {
      const resetDefaultObj = Schema.getPaginationDefault(multiSelect);
      if (filters.hasOwnProperty(key) && resetDefaultObj.hasOwnProperty(key)) {
        filters[key] = resetDefaultObj[key];
        if (key === 't' && identifier === Constants.IDENTIFIER_VIEWCANDIDATES) {
          filters[key] = 'All';
        }

        this.mergeStoreToHistoryState(identifier);
        
        callback(identifier, subIdentifier);
      }
    };

    if (subIdentifier) {
      filters = this.getState().pagination[identifier][subIdentifier];
    } else {
      filters = this.getState().pagination[identifier];
    }

    const filterValue = {} as any;
    let filterValue1 = '';
    let filterValue2 = '';
    if (Object.keys(filters).length > 0) {
      // query
      if (filters.q) {
        filterValue.query = 'Query';
      }

      // status
      if (filters.t) {
        filterValue1 = 'Status';
        if (filters.t === 'A') {
          filterValue2 = ': Active';
        } else if (filters.t === 'AP') {
          filterValue2 = ': Active - Full Profile';
        } else if (filters.t === 'AN') {
          filterValue2 = ': Active - Incomplete Profile';
        }  else if (filters.t === 'AU') {
          filterValue2 = ': Unconfirmed';
        } else if (filters.t === 'I') {
          filterValue2 = ': Inactive';
        } else if (filters.t === 'W') {
          filterValue2 = ': Waiting Approval';
        } else if (filters.t === 'R') {
          filterValue2 = ': Rejected';
        } else if (filters.t === 'P') {
          filterValue2 = ': Pending';
        } else if (filters.t === 'E') {
          filterValue2 = ': Expired';
        } else if (filters.t === 'NA') {
          filterValue2 = ': Deactivated';
        } else if (filters.t === 'AL') {
          filterValue2 = ': Alumni';
        } else if (filters.t === 'ALP') {
          filterValue2 = ': Alumni - Full Profile';
        } else if (filters.t === 'ALN') {
          filterValue2 = ': Alumni - Incomplete Profile';
        } else if (filters.t === 'N') {
          filterValue2 = ': Not Fit';
        } else if (filters.t === 'V') {
          filterValue2 = ': Interview';
        } else if (filters.t === 'O') {
          filterValue2 = ': Offer';
        } else if (filters.t === 'H') {
          filterValue2 = ': Hired';
        } else if (filters.t === 'L') {
          filterValue2 = ': Applied';
        } else if (filters.t === 'S') {
          filterValue2 = ': Not Selected';
        } else if (filters.t === 'G') {
          filterValue2 = ': Awaiting Approval';
        } else if (filters.t === 'U') {
          filterValue2 = ': Paused';
        } else if (filters.t === 'Y') {
          filterValue2 = ': Shortlisted';
        }

        if (identifier === Constants.IDENTIFIER_VIEWCANDIDATES && filters.t === 'All') {
          filterValue1 = '';
        }

        filterValue.status =  filterValue1 + filterValue2;
      } else {
        if (Helper.checkActiveUserType(this.props, Constants.EMPLOYERS_USER_TYPE) && identifier === Constants.IDENTIFIER_VIEWCANDIDATES) {
          filterValue.status = 'Status: New';
        }
      }

      // order
      if (filters.r) {
        if (filters.r === 'alphabetical') {
          filterValue.order = 'Order By: Alphabetical';
        } else {
          filterValue.order = 'Order By: Last Updated';
        }
      }

      // sort
      if (filters.s) {
        if (filters.s === 'asc') {
          filterValue.sort = 'Sort By: Ascending';
        } else {
          filterValue.sort = 'Sort By: Descending';
        }
      }

      // job type
      if (filters.jt) {
        filterValue.job_type = 'Job Type: ' + filters.jt;
      }

      // job fn
      if (filters.jf) {
        filterValue.job_function = 'Job Function: ' + filters.jf;
      }

      // degree program - multiSelect allowed
      const dpMultiSelect = MultiSelect.getValue(filters.dp);
      if (Array.isArray(dpMultiSelect)) {
        multiSelect.push('dp');
        if (dpMultiSelect.length > 0) {
          const tmpArr = [];
          for (const x of dpMultiSelect) {
            tmpArr.push(x.label);
          }
          filterValue.degree_program = 'Degree Program: ' + tmpArr.join(', ');
        }
      } else if (typeof filters.dp === 'string' && filters.dp) {
        filterValue.degree_program = 'Degree Program: ' + filters.dp;
      }

      // degree specialization - multiSelect allowed
      const dsMultSelect = MultiSelect.getValue(filters.ds);
      if (Array.isArray(dsMultSelect)) {
        multiSelect.push('ds');
        if (dsMultSelect.length > 0) {
          const tmpArr = [];
          for (const x of dsMultSelect) {
            if (typeof x === 'string') {
              tmpArr.push(x);
            } else {
              tmpArr.push(x.label);
            }
          }
          filterValue.degree_specialization = 'Degree Specialization: ' + tmpArr.join(', ');
        }
      } else if (typeof filters.ds === 'string' && filters.ds) {
        filterValue.degree_specialization = 'Degree Specialization: ' + filters.ds;
      }

      // graduation year
      if (filters.gy) {
        // multiselect component - single mode
        if (typeof filters.gy === 'string' && filters.gy) {
          filterValue.graduation_year = 'Graduation: ' + filters.gy;
        } else {
          if (filters.gy.length === 1) {
            multiSelect.push('ds_single');
            filterValue.graduation_year = 'Graduation: ' + filters.gy[0].value;
          }
        }
      }

      // industry type
      if (filters.ity) {
        filterValue.industry_type = 'Industry Type: ' + filters.ity;
      }

      // search status - multiSelect allowed
      const ssMultSelect = MultiSelect.getValue(filters.ss);
      if (Array.isArray(ssMultSelect)) {
        multiSelect.push('ss');
        if (ssMultSelect.length > 0) {
          const tmpArr = [];
          for (const x of ssMultSelect) {
            tmpArr.push(x.label);
          }
          filterValue.search_status = 'Job Search Status: ' + tmpArr.join(', ');
        }
      } else if (typeof filters.ss === 'string' && filters.ss) {
        filterValue.search_status = 'Job Search Status: ' + filters.ss;
      }

      // tpo recm
      if (filters.tpr) {
        filterValue.tpo_recommendation = 'TPO Recommendation: ' + filters.tpr;
      }

      // employer
      if (filters.emp) {
        const employers = this.getState().filters[identifier].employers;
        for (const x of employers) {
          if ((x as any).id === filters.emp) {
            filterValue.emp = 'Employer: ' + (x as any).business_name;
          }
        }
      }

      // job readiness - multiSelect allowed
      const jrdMultSelect = MultiSelect.getValue(filters.jrd);
      if (Array.isArray(jrdMultSelect)) {
        multiSelect.push('jrd');
        if (jrdMultSelect.length > 0) {
          const tmpArr = [];
          for (const x of jrdMultSelect) {
            tmpArr.push(x.label);
          }
          filterValue.job_readiness = 'Job Readiness Score: ' + tmpArr.join(', ');
        }
      } else if (typeof filters.jrd === 'string' && filters.jrd) {
        filterValue.job_readiness = 'Job Readiness Score: ' + filters.jrd;
      }

      // last login
      if (filters.llt) {
        if (filters.llt === '-1') {
          filterValue.llt = 'Last Login: No signin';
        }
        if (filters.llt === '30') {
          filterValue.llt = 'Last Login: More than 30 days';
        }
        if (filters.llt === '60') {
          filterValue.llt = 'Last Login: More than 60 days';
        }
        if (filters.llt === '90') {
          filterValue.llt = 'Last Login: More than 90 days';
        }
        if (filters.llt === '180') {
          filterValue.llt = 'Last Login: More than 180 days';
        }
        if (filters.llt === '365') {
          filterValue.llt = 'Last Login: More than 1 year';
        }
      }

      // college
      if (filters.cle) {
        const colleges = this.getState().filters[identifier].colleges;
        for (const x of colleges) {
          if ((x as any).id === filters.cle) {
            filterValue.cle = 'College: ' + (x as any).college_name;
          }
        }
      }

      // mentor
      if (filters.mnt) {
        const mentors = this.getState().filters[identifier].student_mentors;
        for (const x of mentors) {
          if ((x as any).id === filters.mnt) {
            filterValue.mnt = 'Mentor: ' + (x as any).name;
          }
        }
      }

      // event type
      if (filters.ev_ty) {
        filterValue.event_type = 'Event Type: ' + filters.ev_ty;
      }

      // event target audience
      if (filters.ev_ta) {
        filterValue.target_audience = 'Target Audience: ' + filters.ev_ta;
      }

      // employability skills - multiSelect allowed
      const eskMultSelect = MultiSelect.getValue(filters.esk);
      if (Array.isArray(eskMultSelect)) {
        multiSelect.push('esk');
        if (eskMultSelect.length > 0) {
          const tmpArr = [];
          for (const x of eskMultSelect) {
            tmpArr.push(x.label);
          }
          filterValue.employability_skills = 'Employability Skills Score: ' + tmpArr.join(', ');
        }
      } else if (typeof filters.esk === 'string' && filters.esk) {
        filterValue.employability_skills = 'Employability Skills Score: ' + filters.esk;
      }

      // state - multiSelect allowed
      const stMultSelect = MultiSelect.getValue(filters.st);
      if (Array.isArray(stMultSelect)) {
        multiSelect.push('st');
        if (stMultSelect.length > 0) {
          const tmpArr = [];
          for (const x of stMultSelect) {
            tmpArr.push(x.label);
          }
          filterValue.state = 'State: ' + tmpArr.join(', ');
        }
      } else if (typeof filters.st === 'string' && filters.st) {
        filterValue.state = 'State: ' + filters.st;
      }

      // payment_plan
      if (filters.pp) {
        filterValue.payment_plan = 'Plan: ' + filters.pp;
      }

      // claim_college_status
      if (filters.ccs) {
        const claimStatus = GeneralHelper.getClaimCollegeStatus();
        for (const x of claimStatus) {
          if (x[0] === filters.ccs) {
            filterValue.claim_college_status = 'Lead Status: ' + x[1];
          }
        }
      }

      // recruiters
      if (filters.emp_rec) {
        const recruiters = this.getState().filters[identifier].recruiters;
        for (const x of recruiters) {
          if ((x as any).id === filters.emp_rec) {
            filterValue.emp_rec = 'Recruiting User: ' + (x as any).name;
          }
        }
      }

      // gpa_marks
      if (filters.cum_gpa) {
        filterValue.cumulative_gpa = 'Cumulative GPA: ' + filters.cum_gpa;
      }

      // gpa_percentage
      if (filters.cum_pr) {
        filterValue.cumulative_percentage = 'Cumulative %: ' + filters.cum_pr;
      }

      // staff_job_count
      const stJctMultSelect = MultiSelect.getValue(filters.st_jct);
      if (Array.isArray(stJctMultSelect)) {
        if (stJctMultSelect.length === 2) {
          let compStr = '';
          if (stJctMultSelect[0] === 'EQ') {
            compStr = '=';
          }
          if (stJctMultSelect[0] === 'LEQ') {
            compStr = '<=';
          }
          if (stJctMultSelect[0] === 'GEQ') {
            compStr = '>=';
          }
          if (compStr && !Number.isNaN(Number.parseInt(stJctMultSelect[1], 10))) {
            filterValue.staff_job_count = `# of Jobs (${compStr}): ` + stJctMultSelect[1];
          }
        }
      }

      // staff_student_count
      const stSctMultSelect = MultiSelect.getValue(filters.st_sct);
      if (Array.isArray(stSctMultSelect)) {
        if (stSctMultSelect.length === 2) {
          let compStr = '';
          if (stSctMultSelect[0] === 'EQ') {
            compStr = '=';
          }
          if (stSctMultSelect[0] === 'LEQ') {
            compStr = '<=';
          }
          if (stSctMultSelect[0] === 'GEQ') {
            compStr = '>=';
          }
          if (compStr && !Number.isNaN(Number.parseInt(stSctMultSelect[1], 10))) {
            filterValue.staff_student_count = `# of Students (${compStr}): ` + stSctMultSelect[1];
          }
        }
      }

      // staff_candidates_count
      const stCctMultSelect = MultiSelect.getValue(filters.st_cct);
      if (Array.isArray(stCctMultSelect)) {
        if (stCctMultSelect.length === 2) {
          let compStr = '';
          if (stCctMultSelect[0] === 'EQ') {
            compStr = '=';
          }
          if (stCctMultSelect[0] === 'LEQ') {
            compStr = '<=';
          }
          if (stCctMultSelect[0] === 'GEQ') {
            compStr = '>=';
          }
          if (compStr && !Number.isNaN(Number.parseInt(stCctMultSelect[1], 10))) {
            filterValue.staff_candidate_count = `# of Candidates (${compStr}): ` + stCctMultSelect[1];
          }
        }
      }

      // staff_job_date
      const stFtJMultSelect = MultiSelect.getValue(filters.st_ftj);
      if (Array.isArray(stJctMultSelect)) {
        if (stFtJMultSelect.length === 2) {
          let compStr1 = '';
          let compStr2 = '';
          if (stFtJMultSelect[0]) {
            compStr1 = this.displayDateIST(stFtJMultSelect[0], 'MMMM DD, YYYY');
          }
          if (stFtJMultSelect[1]) {
            compStr2 = this.displayDateIST(stFtJMultSelect[1], 'MMMM DD, YYYY');
          }
          if (compStr1 || compStr2) {
            let compStr3 = '';
            if (compStr1 && !compStr2) {
              compStr3 = 'Created Date From: ' + compStr1;
            }
            if (!compStr1 && compStr2) {
              compStr3 = 'Created Date To: ' + compStr2;
            }
            if (compStr1 && compStr2) {
              compStr3 = 'Created Date From: ' + compStr1 + ', To: ' + compStr2;
            }
            filterValue.staff_job_date = compStr3;
          }
        }
      }

      // staff_employer_date
      const stFteMultSelect = MultiSelect.getValue(filters.st_fte);
      if (Array.isArray(stFteMultSelect)) {
        if (stFteMultSelect.length === 2) {
          let compStr1 = '';
          let compStr2 = '';
          if (stFteMultSelect[0]) {
            compStr1 = this.displayDateIST(stFteMultSelect[0], 'MMMM DD, YYYY');
          }
          if (stFteMultSelect[1]) {
            compStr2 = this.displayDateIST(stFteMultSelect[1], 'MMMM DD, YYYY');
          }
          if (compStr1 || compStr2) {
            let compStr3 = '';
            if (compStr1 && !compStr2) {
              compStr3 = 'Created Date From: ' + compStr1;
            }
            if (!compStr1 && compStr2) {
              compStr3 = 'Created Date To: ' + compStr2;
            }
            if (compStr1 && compStr2) {
              compStr3 = 'Created Date From: ' + compStr1 + ', To: ' + compStr2;
            }
            filterValue.staff_employer_date = compStr3;
          }
        }
      }

      // staff_college_date
      const stFtcMultSelect = MultiSelect.getValue(filters.st_ftc);
      if (Array.isArray(stFtcMultSelect)) {
        if (stFtcMultSelect.length === 2) {
          let compStr1 = '';
          let compStr2 = '';
          if (stFtcMultSelect[0]) {
            compStr1 = this.displayDateIST(stFtcMultSelect[0], 'MMMM DD, YYYY');
          }
          if (stFtcMultSelect[1]) {
            compStr2 = this.displayDateIST(stFtcMultSelect[1], 'MMMM DD, YYYY');
          }
          if (compStr1 || compStr2) {
            let compStr3 = '';
            if (compStr1 && !compStr2) {
              compStr3 = 'Created Date From: ' + compStr1;
            }
            if (!compStr1 && compStr2) {
              compStr3 = 'Created Date To: ' + compStr2;
            }
            if (compStr1 && compStr2) {
              compStr3 = 'Created Date From: ' + compStr1 + ', To: ' + compStr2;
            }
            filterValue.staff_college_date = compStr3;
          }
        }
      }

      // gender
      if (filters.gnd) {
        filterValue.gender = 'Gender: ' + filters.gnd;
      }

      // spl_abl_disabilities - multiSelect allowed
      const spadMultSelect = MultiSelect.getValue(filters.sp_ab_di);
      if (Array.isArray(spadMultSelect)) {
        multiSelect.push('sp_ab_di');
        if (spadMultSelect.length > 0) {
          const tmpArr = [];
          for (const x of spadMultSelect) {
            tmpArr.push(x.label);
          }
          filterValue.spl_abl_disabilities = 'Special Abilities/Disabilities: ' + tmpArr.join(', ');
        }
      } else if (typeof filters.sp_ab_di === 'string' && filters.sp_ab_di) {
        filterValue.spl_abl_disabilities = 'Special Abilities/Disabilities: ' + filters.sp_ab_di;
      }

      // college type
      if (filters.cty) {
        filterValue.cty = 'Institution Type: ' + filters.cty;
      }

      // college tier
      if (filters.cti) {
        filterValue.cti = 'Institution Tier: ' + filters.cti;
      }

      // nlp_score
      if (filters.nlps) {
        const list = GeneralHelper.getNlpScoreRange();
        for (const x of list) {
          if (x[0] === filters.nlps) {
            filterValue.nlps = x[1];
          }
        }
      }
    }

    return (
      <React.Fragment>
        {(
          Object.keys(filters).length > 0 &&
          <div id="filter-status-display" className="my-3">
            {(
              filters.q !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.query}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('q')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.status) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.status}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('t')}/>
              </span>
            )}
            {(
              filters.s !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.sort}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('s')}/>
              </span>
            )}
            {(
              filters.r !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.order}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('r')}/>
              </span>
            )}
            {(
              filters.jt !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.job_type}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('jt')}/>
              </span>
            )}
            {(
              filters.jf !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.job_function}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('jf')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.degree_program) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.degree_program}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('dp')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.degree_specialization) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.degree_specialization}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('ds')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.graduation_year) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.graduation_year}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('gy')}/>
              </span>
            )}
            {(
              filters.ity !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.industry_type}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('ity')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.search_status) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.search_status}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('ss')}/>
              </span>
            )}
            {(
              filters.tpr !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.tpo_recommendation}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('tpr')}/>
              </span>
            )}
            {(
              filters.emp !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.emp}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('emp')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.job_readiness) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.job_readiness}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('jrd')}/>
              </span>
            )}
            {(
              filters.llt !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.llt}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('llt')}/>
              </span>
            )}
            {(
              filters.cle !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.cle}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('cle')}/>
              </span>
            )}
            {(
              filters.mnt !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.mnt}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('mnt')}/>
              </span>
            )}
            {(
              filters.ev_ty !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.event_type}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('ev_ty')}/>
              </span>
            )}
            {(
              filters.ev_ta !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.target_audience}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('ev_ta')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.employability_skills) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.employability_skills}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('esk')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.state) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.state}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('st')}/>
              </span>
            )}
            {(
              filters.pp !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.payment_plan}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('pp')}/>
              </span>
            )}
            {(
              filters.ccs !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.claim_college_status}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('ccs')}/>
              </span>
            )}
            {(
              filters.emp_rec !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.emp_rec}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('emp_rec')}/>
              </span>
            )}
            {(
              filters.cum_gpa !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.cumulative_gpa}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('cum_gpa')}/>
              </span>
            )}
            {(
              filters.cum_pr !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.cumulative_percentage}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('cum_pr')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.staff_job_count) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.staff_job_count}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('st_jct')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.staff_student_count) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.staff_student_count}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('st_sct')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.staff_candidate_count) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.staff_candidate_count}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('st_cct')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.staff_job_date) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.staff_job_date}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('st_ftj')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.staff_employer_date) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.staff_employer_date}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('st_fte')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.staff_college_date) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.staff_college_date}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('st_ftc')}/>
              </span>
            )}
            {(
              filters.gnd !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.gender}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('gnd')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.spl_abl_disabilities) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.spl_abl_disabilities}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('sp_ab_di')}/>
              </span>
            )}
            {(
              filters.cty !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.cty}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('cty')}/>
              </span>
            )}
            {(
              filters.cti !== '' &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.cti}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('cti')}/>
              </span>
            )}
            {(
              GeneralHelper.checkValue(filterValue.nlps) &&
              <span className="badge badge-pill badge-info mr-2">
                {filterValue.nlps}
                &nbsp;&nbsp;
                <i className="fa fa-close" onClick={() => resetFilters('nlps')}/>
              </span>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
  /*  #####filters##### */

  /*  #####pagination##### */
  getPaginationPageCount(key: string, identifier: string) {
    const totalRecords = this.getState().api[identifier][key].total_records;
    const recordsPerPage = this.getState().api[identifier][key].records_per_page;
    return Helper.getPageCount(totalRecords, recordsPerPage);
  }

  loadPaginationChanges(elemName: string | null, identifier: string, callback: (elemName: string | null, componentIdentifier: string, identifier: string, paginationObj: CommonTS.PaginationAndFilter) => void) {
    const componentMountIdentifier = Constants.IDENTIFIER_COMPONENTMOUNT;
    const paginationObj = this.getState().pagination[identifier];
    if (this.getState().spinner[componentMountIdentifier]) {
      this.getStore().setSpinnerState(false, componentMountIdentifier); 
    }
    callback(elemName, componentMountIdentifier, identifier, paginationObj);
  }
  /*  #####pagination count##### */

  /*  #####page loading indicator##### */
  showPageLoadingIndicator() {
    if (!this.getState().spinner[Constants.IDENTIFIER_COMPONENTMOUNT]) {
      return true;
    }
    return false;
  }
  
  displayPageLoadingIndicator() {
    const showLoader = this.showPageLoadingIndicator();
    const pageIndicator = document.getElementById('pageLoader');
    const pageIndicatorSpinner = document.getElementById('pageLoaderSpinner');
    if (showLoader && pageIndicator && pageIndicatorSpinner) {
      if (!pageIndicator.classList.contains('pageLoadingContainerVis')) {
        pageIndicator.classList.add('pageLoadingContainerVis');
      }
      if (!pageIndicatorSpinner.classList.contains('pageLoadingVis')) {
        pageIndicatorSpinner.classList.add('pageLoadingVis');
        const stickyFooter: HTMLElement = document.getElementById('page-loader-stk-ftr')!;
        const noFooter: HTMLElement = document.getElementById('page-loader-no-ftr')!;
        if (stickyFooter && !noFooter) {
          const calcMargin = 0.5 * (500 + 80); // add empty div and header
          if (pageIndicatorSpinner.style.marginTop !== calcMargin + "px") {
            pageIndicatorSpinner.style.marginTop = calcMargin + "px";
            // console.log('reset marginTop for normal browser to', `${calcMargin}px`);
          }
        }
      }
    }
    if (!showLoader && pageIndicator && pageIndicatorSpinner) {
      if (pageIndicator.classList.contains('pageLoadingContainerVis')) {
        pageIndicator.classList.remove('pageLoadingContainerVis');
      }
      if (pageIndicatorSpinner.classList.contains('pageLoadingVis')) {
        pageIndicatorSpinner.classList.remove('pageLoadingVis');
      }
    }
  }

  togglePageLoadingVariables(newState: boolean, isStrict: boolean = false) {
    if (isStrict) {
      // only for create job form currently
      const oldState = this.getState().spinner[Constants.IDENTIFIER_COMPONENTMOUNT];
      if (oldState !== newState) {
        this.store.setSpinnerState(newState, Constants.IDENTIFIER_COMPONENTMOUNT);
        this.moveStickyFooterAdjuster(newState);
      }
    } else {
      this.store.setSpinnerState(newState, Constants.IDENTIFIER_COMPONENTMOUNT);
      this.moveStickyFooterAdjuster(newState);
    }
  }
  /*  #####page loading indicator##### */
}
