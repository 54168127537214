"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class StudentDashboard extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTDASHBOARD]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_STUDENTDASHBOARD)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
        },
        displaySections: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTDASHBOARD]: this.getDisplaySectionsDefault()
        },
        updateEmail: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTDASHBOARD]: ''
        },
        generatedPassword: {
          [risesharp_common_1.Constants.IDENTIFIER_STUDENTDASHBOARD]: ''
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STUDENTDASHBOARD) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(risesharp_common_1.Schema.IDENTIFIER_401ERRORSUCCESS), {
        studentDashboard: risesharp_common_1.Schema.getStudentDashboard()
      });
    }
    return {};
  }
  getDisplaySectionsDefault() {
    // show all by default
    return {
      profile: true,
      resources: true,
      assessments: true,
      jobs: true
    };
  }
}
exports.default = StudentDashboard;