"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class EmployerCollegeEvents extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(_this.getApiDefault(identifier), identifier, subIdentifier);
    });
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
        },
        displaySections: {
          [risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS]: this.getDisplaySectionsDefault()
        },
        pagination: {
          [risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS]: this.getPaginationDefault()
        },
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS]: {
            state: ''
          }
        },
        typeaheadSelection: {
          [risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS]: []
        },
        typeaheadLoader: {
          [risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS]: false
        },
        messageAdmins: {
          [risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS]: new mobx_1.ObservableMap()
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_EMPLCOLLEGEEVENTS) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        noDetails: true
      }, {
        college_list: [],
        main_list: risesharp_common_1.Schema.getPaginationList()
      });
    }
    return {};
  }
  getDisplaySectionsDefault() {
    return {
      filters: false
    };
  }
  getPaginationDefault() {
    return risesharp_common_1.Schema.getPaginationDefault();
  }
}
exports.default = EmployerCollegeEvents;