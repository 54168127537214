"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class TrackInvoicesReport extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
        },
        purchaser_gst_no: {
          [risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT]: new mobx_1.ObservableMap()
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT) {
      return {
        report_year: '',
        report_month: ''
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_TRACKINVOICESREPORT) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        trackInvoicesReport: risesharp_common_1.Schema.getTrackInvoicesReport()
      });
    }
    return {};
  }
}
exports.default = TrackInvoicesReport;