"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class ConfirmClaimCollege extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_CONFIRMCLAIMCOLLEGE]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_CONFIRMCLAIMCOLLEGE)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault()
        }
      })
    });
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_CONFIRMCLAIMCOLLEGE) {
      return risesharp_common_1.Schema.getDefaultApiMessages();
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
}
exports.default = ConfirmClaimCollege;