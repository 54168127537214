"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class StaffNotifyTpos extends base_1.Base {
  constructor() {
    var _this;
    super({});
    _this = this;
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.resetValidationState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyValidationState(_this.getValidationDefault(identifier), identifier, subIdentifier);
    });
    this.resetApiState = mobx_1.action(function (identifier) {
      let subIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      _this.modifyApiState(risesharp_common_1.Schema.getDefaultApiMessages(), identifier, subIdentifier);
    });
    this.resetState();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        form: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS]: this.getFormDefault(risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS)
        },
        validation: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS]: this.getValidationDefault(risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS)
        },
        api: {
          [risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS]: this.getApiDefault(risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS)
        },
        spinner: {
          [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: this.getSpinnerDefault(),
          [risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS]: this.getSpinnerDefault()
        }
      })
    });
  }
  getFormDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS) {
      return {
        role_identifier: '',
        college_id: '',
        subject: '',
        message: '',
        payment_plan: '',
        country: '',
        state: '',
        college_type: ''
      };
    }
    return {};
  }
  getValidationDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS) {
      return {
        role_identifier: {
          isError: false,
          errorMessage: ''
        },
        subject: {
          isError: false,
          errorMessage: ''
        },
        message: {
          isError: false,
          errorMessage: ''
        }
      };
    }
    return {};
  }
  getApiDefault(identifier) {
    if (identifier === risesharp_common_1.Constants.IDENTIFIER_STAFF_NOTIFYTPOS) {
      return Object.assign({}, risesharp_common_1.Schema.getDefaultApiMessages(), {
        staffNotifyTpos: risesharp_common_1.Schema.getStaffNotifyTpos()
      });
    }
    return {};
  }
  getSpinnerDefault() {
    return false;
  }
}
exports.default = StaffNotifyTpos;