import { Constants, General } from 'risesharp-common';
import { properties } from 'risesharp-ui';

class HelmetData {
  getTitle(opts: any) {
    const identifier = opts.identifier || '';
    const collegeName = opts.college_name || '';
    const employerName = opts.employer_name || '';
    const jobTitle = opts.job_title || '';
    const studentName = opts.student_name || '';
    const mode = opts.mode || '';
    
    // IMPORTANT - sync with public/index.html
    let title = `${properties.application.name}: College to Career Pathway`;
    let dynTitle = '';
    
    // job_title based
    if (jobTitle && !employerName) {
      dynTitle = 'Job Candidates for ' + jobTitle;
    }
    if (jobTitle && employerName) {
      dynTitle = jobTitle + ' - ' + employerName;
    }

    // student_name based
    if (studentName) {
      dynTitle = 'Resume of ' + studentName;
    }

    // college or employer based
    if (!dynTitle) {
      const baseData = this.getBaseData(collegeName, employerName);
      if (mode === 'student_purchases') {
        baseData[Constants.IDENTIFIER_MAKEPAYMENT] = {
          title: 'Purchase Course',
          description: ''
        };
      }

      if (baseData.hasOwnProperty(identifier) && baseData[identifier].hasOwnProperty('title')) {
        dynTitle = baseData[identifier]['title'];
      }
    }

    if (dynTitle) {
      title = dynTitle;
    }
    
    return title;
  }

  getDescription(opts: any) {
    const identifier = opts.identifier || '';
    const collegeName = opts.college_name || '';
    const employerName = opts.employer_name || '';

    // IMPORTANT - sync with public/index.html
    let description = 'Enabling successful transition from college to career by connecting Job-Ready college students and graduates to employers';
    let dynDescription = '';

    const baseData = this.getBaseData(collegeName, employerName);
    if (baseData.hasOwnProperty(identifier) && baseData[identifier].hasOwnProperty('description')) {
      dynDescription = baseData[identifier]['description'];
    }
    
    if (dynDescription) {
      description = dynDescription;
    }

    return description;
  }

  private getBaseData(collegeName: string, employerName: string) {
    const applicationName =  properties.application.name;

    const baseData = {
      [Constants.IDENTIFIER_ABOUTUS]: {
        title: 'Helping college students transition to career successfully',
        description: ''
      },
      [Constants.IDENTIFIER_ACCOUNTPROFILE]: {
        title: 'Account Profile',
        description: ''
      },
      [Constants.IDENTIFIER_APPROVELINKEDJOBS]: {
        title: 'Job postings',
        description: ''
      },
      [Constants.IDENTIFIER_CHANGEPASSWORD]: {
        title: 'Change Password',
        description: ''
      },
      [Constants.IDENTIFIER_CLAIMCOLLEGE]: {
        title: 'Claim College',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEAPPROVAL]: {
        title: 'Employers',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGE_CAREERTL]: {
        title: 'Manage Career Pathways',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGECONTACTUS]: {
        title: 'Contact Us',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEDIRECTJOBS]: {
        title: 'Create a Job',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEENROLLMENTLIST]: {
        title: 'College Student Enrollment Numbers',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEENROLLMENTDETAILS]: {
        title: 'College Student Enrollment Numbers',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEIMPORTSTUDENTS]: {
        title: 'Onboard college students',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEINVITEEMPLOYERS]: {
        title: 'Invite employers and recruiters',
        description: ''
      },
      [Constants.IDENTIFIER_COLLSTUSUMMARY]: {
        title: 'Student Summary Report',
        description: ''
      },
      [Constants.IDENTIFIER_STU_CAREERCLIST]: {
        title: 'Career Checklist',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENT_CAREERTL]: {
        title: 'Career Pathway',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENTASSESSMENTSLIST]: {
        title: 'Assess Your Interests and Career Goals',
        description: ''
      },
      [Constants.IDENTIFIER_STU_HOWTO]: {
        title: 'Guides and Tutorials by Career Experts',
        description: ''
      },
      [Constants.IDENTIFIER_STU_HOWTO]: {
        title: 'Guides and Tutorials by Career Experts',
        description: ''
      },
      [Constants.IDENTIFIER_EVENTS_WORKSHOPS]: {
        title: 'Career Events, Seminars, Workshops',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENTAPPOINTMENTS]: {
        title: 'Schedule an Appointment',
        description: ''
      },
      [Constants.IDENTIFIER_STU_INTERNSHIPS]: {
        title: 'Find Jobs & Internships',
        description: ''
      },
      [Constants.IDENTIFIER_STU_EVTWORKSHOPS]: {
        title: 'Career Events & Workshops',
        description: ''
      },
      [Constants.IDENTIFIER_MENTORING_NOTES]: {
        title: 'Mentoring Notes',
        description: ''
      },
      [Constants.IDENTIFIER_COMPETENCYASSESSMENT]: {
        title: 'Employability Skills Readiness',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEJOBREADINESS]: {
        title: 'Job Search Readiness',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENTJOBSLIST]: {
        title: 'Job Board, Fresher Jobs',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENTJOBSEARCHSTATUS]: {
        title: 'Report Job Search Status',
        description: ''
      },
      [Constants.IDENTIFIER_STAFFCOLLEGES]: {
        title: 'Update college profile',
        description: ''
      },
      [Constants.IDENTIFIER_MANAGE_COLLEGE]: {
        title: 'Manage College',
        description: ''
      },
      [Constants.IDENTIFIER_TRACKACCESSUSERS]: {
        title: 'College User Activity Report',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGE_WIDGETS]: {
        title: `Add ${properties.application.name} to Your Website`,
        description: ''
      },
      [Constants.IDENTIFIER_STAFFEMPLOYERS]: {
        title: 'Update employer profile',
        description: ''
      },
      [Constants.IDENTIFIER_EMPLOYERCOLLEGESLIST]: {
        title: 'Targeted Colleges',
        description: ''
      },
      [Constants.IDENTIFIER_JOBPLMTREPORT]: {
        title: 'College Placement Report',
        description: ''
      },
      [Constants.IDENTIFIER_JOBPLMTSUMMARY]: {
        title: 'College Job Placement Summary',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGESTUDENTS]: {
        title: 'Student List',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEIMPORTSTUDENTS]: {
        title: 'Onboard college students',
        description: ''
      },
      [Constants.IDENTIFIER_CONTACTUS]: {
        title: 'Contact Us',
        description: ''
      },
      [Constants.IDENTIFIER_CONTACT_CAREERSERVICES]: {
        title: 'Contact Career Services',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGE_GETSTARTED]: {
        title: 'Colleges',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENT_GETSTARTED]: {
        title: 'Students',
        description: ''
      },
      [Constants.IDENTIFIER_EMPLOYER_GETSTARTED]: {
        title: 'Employers',
        description: ''
      },
      [Constants.IDENTIFIER_SIGNIN]: {
        title: 'Signin',
        description: ''
      },
      [Constants.IDENTIFIER_FORGOTPASSWORD]: {
        title: 'Forgot Password',
        description: ''
      },
      [Constants.IDENTIFIER_SIGNUP]: {
        title: 'Signup',
        description: ''
      },
      [Constants.IDENTIFIER_PRIVPOLICY]: {
        title: 'Privacy Policy',
        description: ''
      },
      [Constants.IDENTIFIER_TOS]: {
        title: 'Terms of Service',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_JOBSEARCH]: {
        title: 'Five Steps to Conduct a Successful Job Search',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_HIREDQUICKLY]: {
        title: '10 Tips to Help You Get Hired Quickly',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_MAINPARTS]: {
        title: 'The Main Parts of a Resume',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_DOSDONTS]: {
        title: "Five Do’s and Don’ts for Writing Your Resume",
        description: ''
      },
      [Constants.IDENTIFIER_STURES_COVERLETTER]: {
        title: 'How to Write a Cover Letter for Your Job Application Even with No Work Experience',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_DRESSCODE]: {
        title: 'How to Dress for a Job Interview',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_TENTIPS]: {
        title: 'Ten Tips to Prepare for a Job Interview',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_NETWJOB]: {
        title: 'How to Network to Find a Job After College',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_FOLLOWUP]: {
        title: 'How to Write a Follow Up Email after a Job Interview',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_PRERECORD]: {
        title: 'How to Prepare for a Recorded Video Job Interview',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_PHONEINTVW]: {
        title: 'Five Tips for a Successful Phone Interview',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_COMMONQNS]: {
        title: 'Common Interview Questions for Entry-Level Jobs',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_CRRFAIR]: {
        title: 'How to Get the Most out of a Career Fair',
        description: ''
      },
      [Constants.IDENTIFIER_STURES_CRRPLANNING]: {
        title: 'Student Resources',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENTRESUMEBUILDER]: {
        title: 'Resume Builder',
        description: ''
      },
      [Constants.IDENTIFIER_STUDENTDASHBOARD]: {
        title: 'Student Dashboard',
        description: ''
      },
      [Constants.IDENTIFIER_APPLYJOB]: {
        title: 'Apply for Job',
        description: ''
      },
      [Constants.IDENTIFIER_VIEWCANDIDATES]: {
        title: 'Job Candidates',
        description: ''
      },
      [Constants.IDENTIFIER_EMPLCANDSUMMARY]: {
        title: 'Job Candidates Summary',
        description: ''
      },
      [Constants.IDENTIFIER_EMPL_MANAGE_STAFF]: {
        title: 'Recruiting Staff',
        description: ''
      },
      [Constants.IDENTIFIER_EMPLOYERJOBSLIST]: {
        title: 'Jobs List',
        description: ''
      },
      [Constants.IDENTIFIER_EMPLOYERDETAILS]: {
        title: General.checkValue(employerName) ? (employerName + ` | ${applicationName}`) : '',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEDETAILS]: {
        title: General.checkValue(collegeName) ? (collegeName + ` | ${applicationName}`) : '',
        description: ''
      },
      [Constants.IDENTIFIER_EMPLOYERDASHBOARD]: {
        title: 'Employer Dashboard',
        description: ''
      },
      [Constants.IDENTIFIER_EMPLUSAGEREPORT]: {
        title: 'Recruiter Usage Report',
        description: ''
      },
      [Constants.IDENTIFIER_MAKEPAYMENT]: {
        title: 'Purchase Job Postings',
        description: ''
      },
      [Constants.IDENTIFIER_ACCOUNTPROFILE]: {
        title: 'Account Profile',
        description: ''
      },
      [Constants.IDENTIFIER_UPDATEPASSWORD]: {
        title: 'Update Password',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEDASHBOARD]: {
        title: 'College Dashboard',
        description: ''
      },
      [Constants.IDENTIFIER_COLL_MANAGE_STAFF]: {
        title: 'Manage College Staff',
        description: ''
      },
      [Constants.IDENTIFIER_CREDITSUSAGEREPORT]: {
        title: 'Credits Usage Report',
        description: ''
      },
      [Constants.IDENTIFIER_COLLEGEMOU]: {
        title: 'Memorandum of Understanding',
        description: ''
      },
      [Constants.IDENTIFIER_RES_STUDENTASSMT]: {
        title: 'Assessments',
        description: ''
      },
      [Constants.IDENTIFIER_NOTNPREFERENCES]: {
        title: 'Notification Preferences',
        description: ''
      },
      [Constants.IDENTIFIER_APPLICANTCREDITS]: {
        title: 'Applicant Credits',
        description: ''
      },
      [Constants.IDENTIFIER_VERIFYCERTIFICATE]: {
        title: 'Certificate Verification',
        description: ''
      },
      [Constants.IDENTIFIER_CLOSEACCOUNT]: {
        title: 'Close Account',
        description: ''
      }
    };

    return baseData;
  }
}

const HelmetDataInstance = new HelmetData();
export { HelmetDataInstance as HelmetData };
